import React from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

const ElementPopup = ({ chooseElement, img }) => {
  const { type } = useStoreState((state) => state.popup)
  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        chooseElement({ step: 'mainPopup', element: 'Image', type: 'imgGift' })
      }}
      className={`WheelContent__right-gift flex justify-end cursor-pointer ${
        type == 'imgGift' && `border-active-element`
      }`}
    >
      <img style={{ width: 72, height: 76, objectFit: 'cover' }} src={img} alt="" />
    </div>
  )
}

export default ElementPopup
