import React, { useRef, useEffect, useState } from 'react'

import { Skeleton } from 'antd'
import Legends from '../../Legends'

import { startCase } from 'lodash'
import { COLOR_PALLETTE } from 'Utils/constants'
import ChartItem from './Chart'

const BarLineChart = ({ data, opts }) => {
  const [loading, setLoading] = useState(false)

  if (!data) return null

  return (
    <div
      className={`BarLineChartComponent w-x2-full h-full ${opts.classNames}`}
      style={{ ...opts.wrapperStyles }}
    >
      <Skeleton active loading={loading} paragraph={{ rows: 5 }} />
      <div style={{ height: Object.keys(data.bars).length > 3 ? '78%' : '90%' }} className="p-2">
        {/* <canvas style={{ width: '100%', height: '100%' }} ref={ref} className="chart"></canvas> */}
        <ChartItem rawData={data} />
      </div>

      <Legends
        className="mt2"
        legends={Object.keys(data.bars)
          .map((key, index) => ({
            label: startCase(key.replace(/_/g, ' ')) || '',
            color: COLOR_PALLETTE[index],
            shape: 'rectangle',
          }))
          .concat(
            // if bars legend > 3, break line
            Object.keys(data.bars).length > 3
              ? {
                  label: '',
                  color: '',
                  shape: '',
                }
              : []
          )
          .concat(
            Object.keys(data.lines).map((key, index) => {
              let LINE_COLORS = COLOR_PALLETTE.map((item) => item)
              LINE_COLORS.splice(0, Object.keys(data.bars).length)
              return {
                label: startCase(key.replace(/_/g, ' ')) || '',
                color: LINE_COLORS[index],
                shape: 'line',
              }
            })
          )}
      ></Legends>
    </div>
  )
}

export default BarLineChart
