import React from 'react'
import { Modal, Card, Button, Tabs, Input, List, Typography, Layout, Menu, Steps } from 'antd'
import { STEPS } from '../constants'
import { useStoreActions, useStoreState } from 'easy-peasy'

const { Step } = Steps

const SpinGameSteps = ({ values }) => {
  const { current_step, stepMax } = useStoreState((state) => state.randomvoucher)
  const { setState, showEditChangeStep } = useStoreActions((action: any) => action.randomvoucher)

  return (
    <div className="StepsPopupComponent px-7 bg-white w-full">
      <Steps
        size="default"
        className="w-2/3"
        current={current_step}
        onChange={(step: number) => showEditChangeStep(step)}
      >
        {STEPS.map((step, index) => (
          <Step
            style={{ fontWeight: 600 }}
            disabled={index > (!values ? stepMax : 4) ? true : false}
            key={index}
            title={step.title}
          />
        ))}
      </Steps>
    </div>
  )
}

export default SpinGameSteps
