import React, { useCallback, useEffect, useMemo, useState } from 'react'
import './index.scss'
import { Divider, Input, message, Popover, Select, Space, Tag, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import HeaderTagPopover from './HeaderTagPopover'
import BodyTagPopover from './BodyTagPopover'
import { createTag, updateTag, searchTag, deleteCampaignTag } from 'Services/campaign-tags'
import { randomColor, transformNameToId, findIdFromValue, findValueFromId } from './Contant'
import { debounce } from 'lodash'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { States } from 'Models/campaign'
import { useParams } from 'react-router-dom'

type props = {
  value: { value: string; color: string; id: string }[]
}
const { Option } = Select
const Tags: React.FC<props> = (props) => {
  const { value } = props
  const valueInputSelect = value.reduce((acc: string[], item) => {
    acc.push(item.value)
    return acc
  }, [])
  const { id } = useParams()
  const { campaign_tag_ids, campaign_tag_objs } = useStoreState((state) => state.campaignModule)
  const { setState } = useStoreActions((actions: any) => actions.campaignModule)
  const [items, setItems] = useState(value ? value : [])
  const [name, setName] = useState('')
  const [filterItem, setFilterItem] = useState<string[]>([])
  const [filteredOptions, setFilteredOptions] = useState(value ? value : [])
  const [payloadValue, setPayloadValue] = useState<string[]>(filterItem)

  useEffect(() => {
    id && setFilterItem(valueInputSelect)
    id && setFilteredOptions([])
  }, [])

  const onNameChange = (event) => {
    setName(event.target.value)
  }
  const getValue = (input, values) => {
    const tempArr: any = []
    values.map((item) => {
      if (item.value == input) {
        return tempArr.push(item.value)
      } else return
    })
    return tempArr
  }

  const handleSearch = async (v: string) => {
    const resp = await searchTag(v)
    const tempValue = resp?.data?.map((item: any) => {
      return { color: item.color, value: item.name, id: item.id }
    })
    setFilteredOptions(tempValue)
    setItems((prev) => [...prev, ...tempValue])
  }

  const onChangeLabelItem = async (value, name) => {
    const resp = await updateTag(findIdFromValue(value, filteredOptions), { name: name })
    const newItems = filteredOptions.map((item) =>
      item.value === value ? { ...item, value: resp.data.name } : item
    )
    setFilteredOptions(newItems)
    setItems((prev) => [...prev, ...newItems])
  }

  const onDeleteItems = async (v) => {
    const newItems = filteredOptions.filter((item) => item.value !== v)
    if (id) {
      setFilteredOptions(newItems)
      await deleteCampaignTag(id, findIdFromValue(v, filteredOptions))
    } else {
      setFilteredOptions(newItems)
    }
  }

  const onChangeColorItem = async (v, newValue) => {
    const resp = await updateTag(findIdFromValue(v, filteredOptions), { color: newValue })
    const newItems = filteredOptions.map((item) =>
      item.value === v ? { ...item, color: resp.data.color } : item
    )
    setFilteredOptions(newItems)
    setItems((prev) => [...prev, ...newItems])
  }

  const addItem = async (e) => {
    try {
      e.preventDefault()
      const color = randomColor[Math.floor(Math.random() * (11 - 0 + 1)) + 0]
      const resp = await createTag({ name, color: color })
      setItems((prev) => [...prev, { color: color, value: name, id: resp.data.id }])
      setFilteredOptions([...filteredOptions, { color: color, value: name, id: resp.data.id }])
      setName('')
    } catch (e) {
      message.error('Campaign tag already exists')
    }
  }

  function tagRender(prop) {
    const { label, value, closable, onClose } = prop
    const onPreventMouseDown = (event) => {
      event.preventDefault()
      event.stopPropagation()
    }
    const arrayUniqueByKey = [...new Map(items.map((item: any) => [item['id'], item])).values()]
    const color = arrayUniqueByKey.find((item) => item.value == value)?.color
    return (
      <Tag
        className="Tag_campaign flex items-center"
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {value}
      </Tag>
    )
  }

  const handleChange = (selectedItems) => {
    setFilterItem(selectedItems)
  }
  const debounceDropDown = useCallback(
    debounce((nextValue) => handleSearch(nextValue), 1000),
    []
  )

  useEffect(() => {
    setState({
      key: 'campaign_tag_ids',
      value: transformNameToId(payloadValue, [
        ...new Map(items.map((item: any) => [item['id'], item])).values(),
      ]),
    })
  }, [payloadValue])

  return (
    <div className="OverviewTags">
      <div className="text-secondary_text font-semibold">Tags</div>
      <div>
        <Select
          onClick={(e) => e.stopPropagation()}
          mode="multiple"
          showArrow
          tagRender={tagRender}
          style={{ width: '368px' }}
          onSearch={debounceDropDown}
          //defaultValue={}
          placeholder={<div>| + Add tags</div>}
          value={filterItem}
          onSelect={(value, e) => {
            setFilterItem([
              ...filterItem,
              ...getValue(value, [
                ...new Map(items.map((item: any) => [item['id'], item])).values(),
              ]),
            ])
            setFilteredOptions(filteredOptions.filter((item) => !(value == item?.value)))
            setPayloadValue([...payloadValue, value])
          }}
          onDeselect={(value, e) => {
            setPayloadValue(payloadValue.filter((item) => item !== value))
            onDeleteItems(value)
          }}
          onChange={handleChange}
          dropdownRender={(menu) => (
            <div className="dropdown_campaign">
              {menu}

              <Space align="center" style={{ padding: '0 8px 4px' }}>
                <Input placeholder="Please enter item" value={name} onChange={onNameChange} />
                <Typography.Link
                  className="ml-3"
                  onClick={addItem}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <PlusOutlined /> Add item
                </Typography.Link>
              </Space>
            </div>
          )}
        >
          {filteredOptions.map((item, index) => (
            <Option label={item.color} value={item.value} key={item.id}>
              <div className="flex justify-between items-center">
                <Tag color={item.color}>{item.value}</Tag>
                <div className="campaign_tag--option">
                  <Popover
                    trigger="click"
                    placement="right"
                    content={
                      <BodyTagPopover
                        item={item}
                        index={index}
                        onChangeColorItem={onChangeColorItem}
                      />
                    }
                    title={
                      <HeaderTagPopover
                        item={item}
                        onChangeLabelItem={onChangeLabelItem}
                        onDeleteItems={onDeleteItems}
                        index={index}
                      />
                    }
                  >
                    <span
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                    >
                      ...
                    </span>
                  </Popover>
                </div>
              </div>
            </Option>
          ))}
        </Select>
      </div>
    </div>
  )
}

export default Tags
