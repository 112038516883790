import React, { useState, useEffect } from 'react'
import Table from 'Components/Table2.0'

export const COLUMNS = [
  {
    title: 'Mã NV',
    dataIndex: 'employee_code',
    width: 100,
  },
  {
    title: 'Tên NV',
    dataIndex: 'employee_name',
  },
  {
    title: 'Session',
    dataIndex: 'sessions',
  },
  {
    title: 'Số đơn',
    dataIndex: 'orders',
  },
  {
    title: 'Doanh thu đơn hàng',
    dataIndex: 'rev',
    sorter: true,
  },
  {
    title: 'Doanh thu thành công',
    dataIndex: 'successful_revenue',
    sorter: true,
  },
  {
    title: 'Doanh thu đơn trả',
    dataIndex: 'return_revenue',
    sorter: true,
  },

  {
    title: 'Tỉ lệ Đơn trả / Đơn thành công',
    dataIndex: 'return_successful_rate',
    sorter: true,
  },
  {
    title: 'Doanh thu đơn đổi',
    dataIndex: 'rma_revenue',
    sorter: true,
  },
  {
    title: 'Tỉ lệ Đơn đổi / Đơn thành công',
    dataIndex: 'rma_successful_rate',
    sorter: true,
  },
]

const DashboardDetail = ({ fromDate, toDate }) => {
  const [filters, setFilters] = useState<any>({})
  const [searchTerm, setSearchTerm] = useState('')
  useEffect(() => {
    ;(async () => {
      try {
        let fDate = fromDate.format('YYYY-MM-DD')
        let tDate = toDate.format('YYYY-MM-DD')
        setFilters({
          ...filters,
          from_date: fDate,
          to_date: tDate,
          company: process.env.REACT_APP_COMPANY_NAME,
        })
      } catch (error: any) {}
    })()
  }, [fromDate, toDate])
  return (
    <Table
      className="mt-4"
      dropdown={{
        fields: [
          {
            label: 'Brand',
            value: 'brand',
            options: [
              {
                label: process.env.REACT_APP_COMPANY_NAME || '',
                value: process.env.REACT_APP_COMPANY_NAME || '',
              },
            ],
            type: 'DROPDOWN',
            onClick: ({ key, value }) => setFilters({ ...filters, [key]: value }),
          },
        ],
        value: filters,
      }}
      search={{ keyword: searchTerm, onSearch: setSearchTerm }}
      fetchFrom={{ url: '/social-selling/dashboard/detail', key: 'results' }}
      columns={COLUMNS}
    />
  )
}

export default DashboardDetail
