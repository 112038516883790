import { transform } from 'lodash'
import ItemCampaigns from 'Pages/Audiences/ItemCampaigns'
import React, { useEffect, useState } from 'react'
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Text,
  LabelList,
} from 'recharts'
// import { BarChart, Bar, XAxis, YAxis } from 'recharts'
import './index.scss'
import { ReactComponent as DownIcon } from 'Assets/images/icons/ReduceArrow.svg'

const fortmatAboveChartData = (data) => {
  const testData = [
    { name: 'delivery', label: 'Total Delivery' },
    { name: 'view', label: 'View' },
    { name: 'add_to_cart', label: 'Add To Cart' },
    { name: 'buy', label: 'Buy Online' },
  ]
  const tempArr = []
  for (let i = 0; i < testData?.length; i++) {
    for (let j = 0; j < data?.length; j++) {
      if (testData[i]?.name == data[j]?.name) {
        tempArr.push({ label: testData[i]?.label, total: data[j]?.total })
      }
    }
  }
  return tempArr
}

const formatLabel = (label) => {
  const testData = [
    { name: 'delivery', label: 'Total Delivery' },
    { name: 'view', label: 'View' },
    { name: 'add_to_cart', label: 'Add To Cart' },
    { name: 'buy', label: 'Buy Online' },
  ]
  return testData?.find((item) => item?.name == label)
}

const formatBelowChartData = (data) => {
  const tempArr = []
  for (let i = 0; i < data?.length; i++) {
    const temptObj = {
      value: data[i]?.total - data[i + 1]?.total,
      percentage: (((data[i]?.total - data[i + 1]?.total) / data[i]?.total) * 100).toFixed(2),
    }
    tempArr.push(temptObj)
  }
  return tempArr
}

const sortData = (data) => {
  const testData = ['delivery', 'view', 'add_to_cart', 'buy']
  const tempArr = []
  for (let i = 0; i < testData?.length; i++) {
    for (let j = 0; j < data?.length; j++) {
      if (testData[i] == data[j]?.name) {
        tempArr.push({
          name: data[j]?.name,
          new: data[j]?.new,
          return: data[j]?.return,
          total: data[j]?.total,
        })
      }
    }
  }
  return tempArr
}

const calculatePVPercentge = (value) => {
  const tempArr = []
  for (let i = 0; i < value?.length; i++) {
    const tempObj = {
      ...value[i],
      pvPercentage: value[i]?.new / value[i + 1]?.new ? value[i]?.new / value[i + 1]?.new : '',
      totalPercentage:
        value[i]?.total / value[i + 1]?.total ? value[i]?.total / value[i + 1]?.total : '',
      barHeightRate: value[i]?.new / value[i]?.return || '',
      uvPercentage:
        value[i + 1]?.return / value[i]?.return ? value[i + 1]?.return / value[i]?.return : '',
      newPercentage:
        value[i + 1]?.new / value[i]?.new
          ? ((value[i + 1]?.new / value[i]?.new) * 100)?.toFixed(2) + '%'
          : '',
      returnPercentage:
        value[i + 1]?.return / value[i]?.return
          ? ((value[i + 1]?.return / value[i]?.return) * 100)?.toFixed(2) + '%'
          : '',
    }
    tempArr.push(tempObj)
  }
  return tempArr
}

const getTotalQuadPath = (x, y, width, height, payload) => {
  if (typeof payload?.totalPercentage === 'number') {
    return `M${x},${y} L${x}, ${y + height} L${x + width},${y + height} L${x + width},${
      y + (height / payload?.totalPercentage) * (payload?.totalPercentage - 1)
    } Z`
  }
}

const TotalQuadChart = (props) => {
  const { fill, x, y, width, height, payload, pvPercentage, index } = props
  if (pvPercentage == '') {
    return null
  } else {
    return (
      <>
        <path
          d={getTotalQuadPath(x, y, width, height, payload)}
          stroke="none"
          fill={fill}
          id={`${height?.toFixed(0)?.toString()}`}
        ></path>
        <text
          x={x + width / 2}
          y={y + height / 1.5}
          fill="#000"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {payload?.returnPercentage}
        </text>
      </>
    )
  }
}

const getTestQuadPath = (x, y, width, height, payload) => {
  if (typeof payload?.pvPercentage === 'number') {
    return `M${x},${y} L${x}, ${y + height} L${x + width},${y + height} L${x + width},${
      y + (height / payload?.pvPercentage) * (payload?.pvPercentage - 1)
    } `
  }
}

const TestQuadChart = (props) => {
  const { fill, x, y, width, height, payload, barHeightRate, pvPercentage } = props
  if (pvPercentage == '') {
    return null
  } else {
    return (
      <>
        {/* <path
          d={getTestQuadPath(x, y, width, height, payload)}
          stroke="none"
          fill={fill}
          style={{
            transform: `translateY(${height + height / payload?.barHeightRate}px)`,
          }}
        ></path> */}

        <path
          d={getTestQuadPath(x, y, width, height, payload)}
          stroke="none"
          fill={fill}
          style={{
            transform: `translateY(${height + height / payload?.barHeightRate}px)`,
          }}
          id={(height + 2)?.toFixed(0)?.toString()}
        ></path>

        <text
          style={{
            transform: `translateY(${height + height / payload?.barHeightRate}px)`,
          }}
          x={x + width / 2}
          y={y + height}
          fill="#000"
          textAnchor="middle"
          dominantBaseline="text-bottom"
        >
          {parseInt(payload?.newPercentage?.split('%')[0]) < 30 ? '' : payload?.newPercentage}
        </text>
      </>
    )
  }
}

const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props

  return (
    <g>
      {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#5CDBD3" /> */}
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {parseInt(value) < 500 ? '' : value}
      </text>
    </g>
  )
}
const renderBelowCustomizedLabel = (props) => {
  const { x, y, width, height, value, barHeightRate, payload } = props
  return (
    <g>
      {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#5CDBD3" /> */}
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {height < 10 ? '' : value}
      </text>
    </g>
  )
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload?.length) {
    return (
      <div
        className="custom-tooltip"
        style={{ padding: 8, background: '#fff', border: '1px solid rgba(0,0,0,0.6)' }}
      >
        <div>{formatLabel(label)?.label}</div>
        <div style={{ color: '#1890FF' }}>{`New: ${payload[0]?.payload.new}`}</div>
        <div style={{ color: '#5CDBD3' }}>{`Return: ${payload[0]?.payload.return}`}</div>
        <div style={{ color: '#91D5FF' }}>{`New: ${payload[0]?.payload.newPercentage}`}</div>
        <div style={{ color: '#B5F5EC' }}>{`Return: ${payload[0]?.payload.returnPercentage}`}</div>
      </div>
    )
  }

  return null
}

const FunnelChart = (data) => {
  const belowData = formatBelowChartData(sortData(data?.data))

  return (
    <div className="grid-container flex flex-col ">
      {/* <div className="p-4 col-span-3 shadow-div_rb"> */}
      <div className="flex justify-between items-center">
        {/* <div className="font-semibold">Audience Funnel</div> */}
        {/* <div className="flex text-brand_primary">
            <div className="mr-4">Refresh all</div>
            <div>Download all</div>
          </div> */}
        {/* </div> */}
      </div>
      <div className="flex " style={{ marginLeft: 65, width: 'fit-content' }}>
        {fortmatAboveChartData(data?.data)?.map((item) => {
          return (
            <div
              className="flex flex-col justify-center"
              style={{
                width: 301,
                height: 64,
                border: 'solid 1px rgba(0,0,0,0.3)',
                paddingLeft: 8,
              }}
            >
              <div>{item?.label}</div>
              <div style={{ fontSize: 20, fontWeight: 600 }}>{item?.total}</div>
            </div>
          )
        })}
      </div>
      <div class="FunnelBar">
        <ComposedChart
          barGap={0}
          width={1275}
          height={230}
          data={calculatePVPercentge(sortData(data?.data))}
        >
          <XAxis dataKey="name" hide />
          <YAxis tickSize={20} />
          <Tooltip content={CustomTooltip} />
          {/* <Legend /> */}
          <CartesianGrid stroke="#000000" strokeDasharray="1" />
          <Bar stackId="a" dataKey="new" barSize={200} fill="#1890FF">
            <LabelList dataKey="new" content={renderBelowCustomizedLabel} position="inside" />
          </Bar>

          <Bar stackId="a" dataKey="return" barSize={200} fill="#5CDBD3">
            <LabelList dataKey="return" content={renderCustomizedLabel} position="inside" />
          </Bar>
          <Bar
            className="totalPercentageBar"
            stackId="b"
            dataKey="total"
            barSize={101}
            fill="#B5F5EC"
            shape={<TotalQuadChart />}
          >
            {/* <LabelList dataKey="returnPercentage" position="insideStart" /> */}
          </Bar>
          <Bar stackId="b" dataKey="new" barSize={101} fill="#91D5FF" shape={<TestQuadChart />}>
            {/* <LabelList dataKey="newPercentage" position="outside" offset={100} /> */}
          </Bar>
        </ComposedChart>
      </div>
      <div style={{ transform: 'translateY(-10px)', width: 'fit-content' }}>
        <div className="flex " style={{ marginLeft: 65 }}>
          <div
            className="flex flex-col justify-center"
            style={{
              width: 301,
              height: 52,
              border: 'solid 1px rgba(0,0,0,0.3)',
              borderBottom: 0,
              borderRight: 0,
              paddingLeft: 8,
            }}
          >
            <DownIcon />
          </div>
          <div
            className="flex flex-col justify-center"
            style={{
              width: 301,
              height: 52,
              border: 'solid 1px rgba(0,0,0,0.3)',
              borderBottom: 0,
              borderRight: 0,
              paddingLeft: 8,
            }}
          >
            <DownIcon />
          </div>
          <div
            className="flex flex-col justify-center"
            style={{
              width: 301,
              height: 52,
              border: 'solid 1px rgba(0,0,0,0.3)',
              borderBottom: 0,
              borderRight: 0,
              paddingLeft: 8,
            }}
          >
            <DownIcon />
          </div>
          <div
            className="flex flex-col justify-center"
            style={{
              width: 301,
              height: 52,
              border: 'solid 1px rgba(0,0,0,0.3)',
              borderBottom: 0,

              paddingLeft: 8,
            }}
          >
            {/* <DownIcon /> */}
          </div>
        </div>
        <div className="flex " style={{ marginLeft: 65 }}>
          <div
            className="flex flex-col justify-center"
            style={{
              width: 301,
              height: 84,
              border: 'solid 1px rgba(0,0,0,0.3)',
              borderRight: 0,
              paddingLeft: 8,
            }}
          >
            <div>No View</div>
            <div style={{ fontSize: 20, fontWeight: 600 }}>{belowData[0]?.value}</div>
            <div style={{ fontSize: 12, fontWeight: 600, color: '#F5222D' }}>
              {belowData[0]?.percentage}%
            </div>
          </div>
          <div
            className="flex flex-col justify-center"
            style={{
              width: 301,
              height: 84,
              border: 'solid 1px rgba(0,0,0,0.3)',
              borderRight: 0,
              paddingLeft: 8,
            }}
          >
            <div>No Add To Cart</div>
            <div style={{ fontSize: 20, fontWeight: 600 }}>{belowData[1]?.value}</div>
            <div style={{ fontSize: 12, fontWeight: 600, color: '#F5222D' }}>
              {belowData[1]?.percentage}%
            </div>
          </div>
          <div
            className="flex flex-col justify-center"
            style={{
              width: 301,
              height: 84,
              border: 'solid 1px rgba(0,0,0,0.3)',
              borderRight: 0,
              paddingLeft: 8,
            }}
          >
            <div>No Buy</div>
            <div style={{ fontSize: 20, fontWeight: 600 }}>{belowData[2]?.value}</div>
            <div style={{ fontSize: 12, fontWeight: 600, color: '#F5222D' }}>
              {belowData[2]?.percentage}%
            </div>
          </div>
          <div
            className="flex flex-col justify-center"
            style={{ width: 301, height: 84, border: 'solid 1px rgba(0,0,0,0.3)', paddingLeft: 8 }}
          ></div>
        </div>
        <div className="flex " style={{ marginTop: 32, marginLeft: 24 }}>
          <div className="flex items-center">
            <div style={{ width: 16, height: 8, backgroundColor: '#1890FF' }}></div>
            <div className="px-4">New</div>
          </div>
          <div className="flex items-center">
            <div style={{ width: 16, height: 8, backgroundColor: '#5CDBD3' }}></div>
            <div className="px-4">Return</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FunnelChart
