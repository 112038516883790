import React from 'react'
import { CloseOutlined } from '@ant-design/icons'
import ElementPopup from './ElementPopup'
import TitlePopup from './TitlePopup'
import InputPopup from './InputPopup'
import ButtonPopup from './ButtonPopup'
import gift from '../../img/gift.png'
import { useStoreActions, useStoreState } from 'easy-peasy'

const RightEdit = ({ chooseElement, initialValues }) => {
  const { device } = useStoreState((state) => state.popup)

  const { mainPopup } = initialValues
  const { text, text_1, text_2, input, button, text_3 } = mainPopup

  return (
    <>
      <CloseOutlined className="flex justify-end px-4 pt-4" />
      <div className="WheelContent__right__content p-2">
        <div className={`${device == 'mobile' && 'hidden'}`}>
          <ElementPopup img={mainPopup?.imgGift?.url} chooseElement={chooseElement} />
        </div>
        <div className="WheelContent__title">
          <TitlePopup
            textContent={text}
            text1={text_1}
            text2={text_2}
            chooseElement={chooseElement}
          />
        </div>
        <div className="WheelContent__infor mt-4">
          <div className="WheelContent__Email">
            <InputPopup
              textContent={input}
              textEmailValidate={text_3}
              chooseElement={chooseElement}
            />
            <div className="mt-4">
              <ButtonPopup textContent={button} chooseElement={chooseElement} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RightEdit
