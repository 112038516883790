import React, { useEffect, useState } from 'react'
import axios from 'Utils/axios'
import { Modal, Card, Button, Tabs, Input, List, Typography, Layout, Menu, Select } from 'antd'
import { useStoreActions } from 'easy-peasy'

const { Title } = Typography
const { Option } = Select

interface coupon {
  title: string
  code: string
}
const EditWheelModal = ({ isModalVisible, handleCancel, handleOk, wheels, numberOptions }) => {
  const { setNumberOptionWheel, setElement } = useStoreActions(
    (actions: any) => actions.randomvoucher
  )

  const [coupons, setCoupons] = useState([])
  useEffect(() => {
    const getCoupons = async () => {
      const resp = await axios.get(`/utils/minigame/coupons`)
      console.log('rep', resp.data)
      setCoupons(resp.data)
    }
    getCoupons()
  }, [])

  function handleChange(value, index) {
    console.log(`selected ${value}`)
    setElement({
      step: 'mainPopup',
      element: 'gift',
      key: 'gifts',
      item: 'code',
      value: value,
      index: index,
    })
    // setNumberOptionWheel(value)
  }
  function onBlur() {
    console.log('blur')
  }

  function onFocus() {
    console.log('focus')
  }

  function onSearch(val) {
    console.log('search:', val)
    setNumberOptionWheel(val)
  }

  const handleOnChange = (e, index) => {
    setElement({
      step: 'mainPopup',
      element: 'gift',
      key: 'gifts',
      item: 'title',
      value: e.target.value,
      index: index,
    })
  }
  return (
    <>
      <Modal
        style={{ top: 100 }}
        className="WheelModal"
        width={620}
        visible={isModalVisible}
        closable={false}
        footer={null}
        // onOk={handleOk}
        // onCancel={handleCancel}
      >
        <div className="border flex justify-between align-center px-8 py-4">
          <Title style={{ fontSize: 18, fontWeight: 600 }}>Edit Gifts</Title>
          <div className="flex">
            <div className="mr-2">
              <Button onClick={() => handleCancel()} className="px-5">
                Cancel
              </Button>
            </div>
            <div>
              <Button onClick={() => handleOk()} className="px-1" type="primary">
                Save
              </Button>
            </div>
          </div>
        </div>
        <div className="p-4 mt-2">
          <div className="flex align-center">
            <div className="mr-4">
              <Title level={5}>Number Options</Title>
            </div>
            <div>
              <Select value={numberOptions} style={{ width: 120 }} onChange={onSearch}>
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
              </Select>
            </div>
          </div>
          <div className="mt-8">
            <div className="flex">
              <div className="flex-1" style={{ color: 'rgba(0,0,0,0.6)', fontWeight: 600 }}>
                Title
              </div>
              <div className="flex-1 ml-4" style={{ color: 'rgba(0,0,0,0.6)', fontWeight: 600 }}>
                Coupon code
              </div>
            </div>
            <div>
              {wheels?.map((item, index) => (
                <div key={index} className="mt-4">
                  <div className="flex">
                    <div className="flex-1 mr-4">
                      <Input
                        style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                        value={item.title}
                        onChange={(e) => handleOnChange(e, index)}
                      />
                    </div>
                    <div className="flex-1" style={{ color: 'rgba(0,0,0,0.45)' }}>
                      <Select
                        className="w-full"
                        showSearch
                        placeholder="Select"
                        // optionFilterProp="children"
                        onChange={(value) => handleChange(value, index)}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        style={{ width: 200, color: 'rgba(0, 0, 0, 0.85)' }}
                        // onSearch={onSearch}
                      >
                        {coupons.map((item: coupon, index) => (
                          <Option key={index} value={item.code}>
                            {item.title}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default EditWheelModal
