import React from 'react'
import { Form, message } from 'antd'
import { useStoreState, useStoreActions } from 'easy-peasy'

import HaraSocialSF from './StoreFarmingForm'
import { Schema } from 'CampaignTypes'
import FormItem from './FormItem'
import ChannelSchemas from 'Constants/ChannelSchemas'

export default function ContentForm() {
  const { selected_placement, content } = useStoreState((state) => state.campaignModule)
  const { setState } = useStoreActions((action: any) => action.campaignModule)
  const [form] = Form.useForm()

  const onFinish = () => {
    message.success('Content is validated')
    setState({ key: 'isContentValidated', value: true })
  }

  if (!selected_placement)
    return (
      <div className="w-full text-center h-full flex justify-center items-center">
        Please select a placement
      </div>
    )

  const renderBody = () => {
    if (selected_placement.code === 'hrs_store_farming') return <HaraSocialSF></HaraSocialSF>

    return (
      <Form
        layout="vertical"
        name="ContentForm"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 24 }}
        requiredMark
        onFinish={onFinish}
        initialValues={content}
        scrollToFirstError
        form={form}
      >
        {ChannelSchemas[selected_placement?.code]?.map((schema: Schema) => (
          <Form.Item
            key={schema.name}
            label={schema.label}
            tooltip={schema.tooltip}
            className={schema.className || ''}
            name={schema.name}
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues[schema.name] !== currentValues[schema.name]) return true
              return false
            }}
            rules={[
              ...(schema.isRequired
                ? [
                    {
                      required: schema.isRequired,
                      message: `${schema.label} is required`,
                    },
                  ]
                : []),
              ...(schema.validatePattern
                ? [
                    {
                      pattern: schema.validatePattern,
                      message: `${schema.label} doesn't match the pattern`,
                    },
                  ]
                : []),
            ]}
            initialValue={schema.defaultValue}
          >
            <FormItem
              name={schema?.name}
              type={schema?.type}
              value={form.getFieldValue(content && content[schema?.name])}
              options={schema.options}
              placeholder={schema.placeholder || ''}
              onChange={(name, value) => {
                form.setFieldsValue({ [name]: value })
                setState({ key: 'isContentValidated', value: false })
                setState({ key: 'content', value: { ...content, [name]: value } })
              }}
            />
          </Form.Item>
        ))}
      </Form>
    )
  }
  return (
    <div>
      <div className="font-semibold text-lg pb-6">{selected_placement?.name}</div>
      {renderBody()}
    </div>
  )
}
