import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { message, Typography, Modal } from 'antd'
import { useStoreActions } from 'easy-peasy'
import { BarChartOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { ReactComponent as Duplicate } from 'Assets/images/icons/duplicate.svg'

import { deleteCampaign } from 'Services/campaigns'
import { formatErrors } from 'Utils'
import { STATUS, IS_ACTIVE, COLUMNS, CHANNELS, DATE, PLACEMENTS } from './constants'
import Table from 'Components/Table2.0'

import { setCampaignDetail } from 'Containers/Campaign/MainFlow/helpers'

import { getCampaignDetail } from 'Services/campaigns'

const { Title } = Typography

const CampaignList = () => {
  const history = useHistory()
  const { setBreadCrumb } = useStoreActions((actions: any) => actions.breadcrumb)
  const { setState } = useStoreActions((actions: any) => actions.campaignModule)

  const [filters, setFilters] = useState<any>({
    campaign_status: window.location.href.split('tab=')[1] || '',
  })
  const [searchTerm, setSearchTerm] = useState('')

  const handleDelete = async (record, setRows, rows) => {
    Modal.confirm({
      title: (
        <div className="action-prompt-popup">
          <Title level={4}>Are you sure to delete {record.name}?</Title>
        </div>
      ),
      onOk: async () => {
        try {
          await deleteCampaign(record.id)
          message.success('Deleted campaign')
          setRows(rows.filter((row) => row.id !== record.id))
        } catch (err: any) {
          message.error(formatErrors(err))
          throw new Error(err)
        }
      },
      okText: 'Delete',
      okType: 'danger',
      centered: true,
    })
  }

  const handleDuplicate = async (record) => {
    setFilters({
      ...filters,
      isLoading: true,
    })
    try {
      const resp = await getCampaignDetail(record.id)
      var dataDuplicate = { ...resp.data, name: '[Copy] ' + resp.data.name }

      await setCampaignDetail({
        data: dataDuplicate,
        handler: setState,
      })

      history.push('/campaigns/new/')
    } catch (err: any) {}
    setFilters({
      ...filters,
      isLoading: false,
    })
  }

  const handleCreate = async () => {
    history.push('./campaigns/new')
  }

  const handleEdit = async (record) => {
    history.push(`./campaigns/${record.id}`)
  }

  const handleStatistics = async (record) => {
    window.open(`/analytics/campaign360/${record.id}`, '_self')
  }

  const handleTableChange = (tablePagination, _, sorter) => {
    setFilters({
      ...filters,
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.order
        ? sorter.order === 'ascend'
          ? sorter.field
          : `-${sorter.field}`
        : null,
    })
  }

  useEffect(() => {
    setBreadCrumb([{ title: 'Campaigns', path: '/campaigns', isLastItem: true }])
  }, [setBreadCrumb])

  return (
    <Table
      className="px-8 py-6"
      dropdown={{
        fields: [
          {
            label: 'Status',
            value: 'campaign_status',
            options: STATUS,
            type: 'TAB',
            onClick: ({ key, value }) => setFilters({ ...filters, campaign_status: value }),
          },
          {
            label: 'Channel',
            value: 'channel',
            options: CHANNELS,
            type: 'DROPDOWN',
            onClick: ({ key, value }) => setFilters({ ...filters, [key]: value }),
          },
          {
            label: 'Placements',
            value: 'placement',
            options: PLACEMENTS,
            type: 'DROPDOWN',
            onClick: ({ key, value }) => setFilters({ ...filters, placement: value }),
          },
        ],
        value: filters,
      }}
      fetchFrom={{ url: '/campaigns/', key: 'results' }}
      search={{ keyword: searchTerm, onSearch: setSearchTerm }}
      title="Campaigns"
      columns={COLUMNS}
      handleTableChange={handleTableChange}
      rowActions={{
        onDoubleClick: handleEdit,
        actions: [
          {
            icon: <BarChartOutlined />,
            attribute: { type: 'highlight', label: 'Performance' },
            onClick: handleStatistics,
          },
          { icon: <EditOutlined />, attribute: { label: 'Edit' }, onClick: handleEdit },
          {
            icon: <Duplicate />,
            attribute: { label: 'Duplicate' },
            onClick: handleDuplicate,
          },
          {
            icon: <DeleteOutlined />,
            attribute: { label: 'Delete' },
            onClick: handleDelete,
          },
        ],
      }}
      primaryButton={{ title: 'Create campaign', onClick: handleCreate }}
    />
  )
}

export default CampaignList
