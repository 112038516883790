export const STEPS = [
  { title: 'Main Popup', description: '' },
  {
    title: 'Minimize',
    description: '',
  },
  { title: 'Result', description: '' },
  { title: 'Email', description: '' },
  // { title: 'Review', description: 'Final review before starting your campaign' },
]

export const FONTSIZE = [
  12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
  36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46,
]

export const FONT_FAMILY = [
  'Inter',
  'Merriweather',
  'Montserrat',
  'Nunito',
  'Playfair Display',
  'Roboto',
]
