export const STEPS = [
  { title: 'Campaign Overview', description: 'Choose your placement' },
  {
    title: 'Audience',
    description:
      'Personalize experience of a specific group of users by choosing from pre-built segments',
  },
  { title: 'Content', description: 'Create your push notification' },
  { title: 'Schedule', description: 'Planning your notification & release' },
  // { title: 'Review', description: 'Final review before starting your campaign' },
]
export const STEPS_WITH_ID = [
  { title: 'Campaign Overview', description: 'Choose your placement' },
  { title: 'Content', description: 'Create your push notification' },
  { title: 'Schedule', description: 'Planning your notification & release' },
  // { title: 'Review', description: 'Final review before starting your campaign' },
]
