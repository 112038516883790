import React from 'react'
import { Modal, Card, Button, Tabs, Input, List, Typography, Layout, Menu, Steps } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'

const InputPopup = ({ chooseElement, textContent, textEmailValidate }) => {
  const { type } = useStoreState((state) => state.popup)
  const { content, style, background } = textContent
  const { fontFamily, color, fontSize, fontStyle, textAlign } = style
  const fontStyleClass = (fontStyle) => {
    if (fontStyle == 'bold') {
      return 'font-semibold'
    }
    if (fontStyle == 'underline') {
      return 'underline'
    }
    if (fontStyle == 'italic') {
      return 'italic'
    }
  }

  return (
    <>
      <div
        className={`cursor-pointer ${type == 'input' && `border-active-element`}`}
        onClick={(e) => {
          e.stopPropagation()
          chooseElement({ step: 'mainPopup', element: 'Input', type: 'input' })
        }}
      >
        <Input
          className={`${fontStyleClass(fontStyle)}`}
          style={{
            ...style,
            fontStyle: fontStyle == 'italic' ? 'italic' : '',
            height: 40,
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)',
            ...background,
          }}
          placeholder={content}
        />
      </div>
    </>
  )
}

export default InputPopup
