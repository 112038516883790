import React from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

const TitlePopup = ({ chooseElement, textContent, text1, text2 }) => {
  const { type } = useStoreState((state) => state.popup)
  const { content, style } = textContent
  const { fontFamily, color, fontSize, fontStyle, textAlign } = style

  const fontStyleClass = (fontStyle) => {
    if (fontStyle == 'bold') {
      return 'font-semibold'
    }
    if (fontStyle == 'underline') {
      return 'underline'
    }
    if (fontStyle == 'italic') {
      return 'italic'
    }
  }
  return (
    <>
      <div
        className={`cursor-pointer ${fontStyleClass(fontStyle)} ${
          type == 'text' && `border-active-element`
        }`}
        onClick={(e) => {
          e.stopPropagation()
          chooseElement({ step: 'mainPopup', element: 'Text', type: 'text' })
        }}
        style={{
          ...style,
          fontStyle: fontStyle == 'italic' ? 'italic' : '',
          lineHeight: '44px',
        }}
      >
        {content == '' ? 'Title' : content}
      </div>
      <div
        className={`cursor-pointer mt-4 ${fontStyleClass(text1.style.fontStyle)} ${
          type == 'text_1' && `border-active-element`
        } `}
        onClick={(e) => {
          e.stopPropagation()
          chooseElement({ step: 'mainPopup', element: 'Text', type: 'text_1' })
        }}
        style={{
          ...text1.style,
          fontStyle: text1.style.fontStyle == 'italic' ? 'italic' : '',
        }}
      >
        {text1.content == '' ? 'Subtitle' : text1.content}
      </div>
      <div
        className={`cursor-pointer mt-2 ${fontStyleClass(text2.style.fontStyle)} ${
          type == 'text_2' && `border-active-element`
        }`}
        onClick={(e) => {
          e.stopPropagation()
          chooseElement({ step: 'mainPopup', element: 'Text', type: 'text_2' })
        }}
        style={{
          ...text2.style,
          fontStyle: text2.style.fontStyle == 'italic' ? 'italic' : '',
        }}
      >
        {text2.content == '' ? 'Text' : text2.content}
      </div>
    </>
  )
}

export default TitlePopup
