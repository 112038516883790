import React from 'react'
import gift from '../../img/congratlogo.png'
import { useStoreState, useStoreActions } from 'easy-peasy'

const CongratulationResult = ({ initialValues, chooseElement }) => {
  const { type } = useStoreState((state) => state.randomvoucher)
  const { result } = initialValues
  const { imgGift, text, text_1, button, code } = result

  const fontStyleClass = (fontStyle) => {
    if (fontStyle == 'bold') {
      return 'font-semibold'
    }
    if (fontStyle == 'underline') {
      return 'underline'
    }
    if (fontStyle == 'italic') {
      return 'italic'
    }
  }

  const renderElement = () => {
    return (
      <div
        className={`flex justify-center cursor-pointer mt-4 m-auto mb-6 ${
          type == 'imgGift' && `border-active-element`
        }`}
        onClick={(e) => {
          e.stopPropagation()
          chooseElement({ step: 'result', element: 'Image', type: 'imgGift' })
        }}
      >
        <img style={{ objectFit: 'cover', width: 116, height: 80 }} src={imgGift?.url} alt="" />
      </div>
    )
  }

  const renderTitle = () => {
    const { content, style } = text
    const { fontStyle } = style
    return (
      <div
        className={`cursor-pointer ${fontStyleClass(fontStyle)} ${
          type == 'text' && `border-active-element`
        }`}
        onClick={(e) => {
          e.stopPropagation()
          chooseElement({ step: 'result', element: 'Text', type: 'text' })
        }}
        style={{ ...style, fontStyle: fontStyle == 'italic' ? 'italic' : '', width: 265 }}
      >
        <span> {content == '' ? 'Title' : content}</span>
      </div>
    )
  }

  const renderCode = () => {
    const { content, style } = code
    const { fontStyle } = style
    return (
      <div
        className={`cursor-pointer ${fontStyleClass(fontStyle)} ${
          type == 'code' && `border-active-element`
        }`}
        onClick={(e) => {
          e.stopPropagation()
          chooseElement({ step: 'result', element: 'Text', type: 'code' })
        }}
        style={{ ...style, fontStyle: fontStyle == 'italic' ? 'italic' : '', width: 262 }}
      >
        <span> {content == '' ? 'Title' : content}</span>
      </div>
    )
  }

  const renderTitle1 = () => {
    const { content, style } = text_1
    const { fontStyle } = style

    return (
      <div
        className={`cursor-pointer ${fontStyleClass(fontStyle)} ${
          type == 'text_1' && `border-active-element`
        }`}
        onClick={(e) => {
          e.stopPropagation()
          chooseElement({ step: 'result', element: 'Text', type: 'text_1' })
        }}
        style={{ ...style, fontStyle: fontStyle == 'italic' ? 'italic' : '', width: 262 }}
      >
        <span> {content == '' ? 'Text' : content}</span>
      </div>
    )
  }

  const renderButton = () => {
    const { content, style, buttonStyle } = button
    const { hover } = buttonStyle

    const { fontStyle, backgroundColor } = style

    const onHandleOnMouseOver = (e) => {
      e.target.style.backgroundColor = hover
    }
    const onHandleOnMouseOut = (e) => {
      e.target.style.backgroundColor = backgroundColor
    }
    return (
      <div
        onClick={(e) => {
          e.stopPropagation()
          chooseElement({ step: 'result', element: 'Button', type: 'button' })
        }}
      >
        <button
          className={`cursor-pointer px-2 truncate ${fontStyleClass(fontStyle)} ${
            type == 'button' && `border-active-element`
          }`}
          style={{ ...style, fontStyle: fontStyle == 'italic' ? 'italic' : '' }}
          onMouseOver={onHandleOnMouseOver}
          onMouseOut={onHandleOnMouseOut}
        >
          {content}
        </button>
      </div>
    )
  }

  return (
    <div className="text-center CongratulationResultPopup">
      <div>{renderElement()}</div>
      <div className="mb-2 flex justify-center">{renderTitle()}</div>
      <div className="mb-2 flex justify-center">{renderCode()}</div>
      <div className="flex justify-center">{renderTitle1()}</div>
      <div className="my-4 flex justify-center">{renderButton()}</div>
    </div>
  )
}

export default CongratulationResult
