import React from 'react'
import { Typography, Tooltip } from 'antd'
import moment from 'moment'

import { CampaignStatus } from 'CampaignTypes'
import { systemColor } from 'DesignSystem/Colors'
import { capitalizeFirstLetter } from 'Utils'

const { Text } = Typography
const STATUS_COLORS = {
  init: 'greekBlue',
  waiting: 'gold',
  running: 'green',
  completed: 'blue',
  deleted: 'red',
}

const format = (values) => {
  return values?.map((value) => {
    return (
      <div
        className="mr-2 mb-2 font-semibold rounded-sm text-sm py-1 px-2"
        style={{
          width: 'fit-content',
          display: 'inline-block',
          textAlign: 'left',
          color: `${
            value?.color === systemColor.geekblue_6
              ? systemColor.geekblue_6
              : systemColor[value?.color + '_6']
          }`,
          backgroundColor: `${
            value?.color === systemColor.geekblue_1
              ? systemColor.greekBlue_1
              : systemColor[value?.color + '_1']
          }`,
        }}
      >
        {value?.name}
      </div>
    )
  })
}

export const COLUMNS = [
  {
    title: 'Campaign',
    dataIndex: 'name',
    sorter: true,
    align: 'left',
    render: (campaign) => (
      <Tooltip title={campaign}>
        <Text className="campaign-title" strong>
          {campaign}
        </Text>
      </Tooltip>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 100,
    sorter: true,
    render: (status) =>
      status ? (
        <span
          className="font-semibold rounded-sm text-sm py-1 px-2"
          style={{
            width: 'fit-content',
            display: 'inline-block',
            textAlign: 'left',
            color: `${
              STATUS_COLORS[status] === systemColor.geekblue_6
                ? systemColor.geekblue_6
                : systemColor[STATUS_COLORS[status] + '_6']
            }`,
            backgroundColor: `${
              STATUS_COLORS[status] === systemColor.geekblue_1
                ? systemColor.greekBlue_1
                : systemColor[STATUS_COLORS[status] + '_1']
            }`,
          }}
        >
          {capitalizeFirstLetter(status)}
        </span>
      ) : (
        'N/A'
      ),
  },
  {
    title: 'Tags',
    dataIndex: 'campaign_tag_objs',
    width: 218,
    render: (tags) => tags && <div>{format(tags)}</div>,
  },
  {
    title: 'Views',
    dataIndex: ['analytic_key_metrics', 'views'],
    width: 100,
    render: (value) => {
      if (value) return value?.toLocaleString()
      else return 'N/A'
    },
  },
  {
    title: 'CPC',
    dataIndex: ['analytic_key_metrics', 'cpc'],
    width: 100,
    render: (value) => {
      if (typeof value === 'number') return value.toFixed(2).toLocaleString() + '%'
      else return 'N/A'
    },
  },
  {
    title: 'Clicks',
    dataIndex: ['analytic_key_metrics', 'clicks'],
    width: 100,
    render: (value) => {
      if (value) return value?.toLocaleString()
      else return 'N/A'
    },
  },
  {
    title: 'CTR',
    dataIndex: ['analytic_key_metrics', 'ctr'],
    width: 100,
    render: (value) => {
      if (typeof value === 'number') return value.toFixed(2).toLocaleString() + '%'
      else return 'N/A'
    },
  },
  // {
  //   title: 'Performance',
  //   dataIndex: 'performance',
  //   width: 100,
  // },
  {
    title: 'Updated on',
    dataIndex: 'updated_at',
    sorter: true,
    render: (text) => moment(text).format('DD-MM-YYYY'),
    width: 150,
  },
]

export const STATUS: { label: string; value: CampaignStatus }[] = [
  { label: 'All', value: '' },
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
  { label: 'Draft', value: 'draft' },
]
export const IS_ACTIVE = [
  { label: 'Published', value: 'published' },
  { label: 'Draft', value: 'draft' },
]
export const CHANNELS = [
  { label: 'Facebook Advertising', value: 'facebook' },
  { label: 'Application Push', value: 'kaipass' },
  { label: 'Email', value: 'email' },
  { label: 'SMS', value: 'sms' },
  { label: 'HaraSocial', value: 'harasocial' },
  { label: 'Own App', value: 'own_app' },
  { label: 'Website', value: 'website' },
]
export const PLACEMENTS = [
  { label: 'Link Clicks', value: 'link_clicks' },
  { label: 'Post', value: 'post_engagement' },
  { label: 'Kaipass', value: 'kaipass' },
  { label: 'Email', value: 'email' },
  { label: 'Store Farming', value: 'hrs_store_farming' },
  { label: 'Marketing News', value: 'tch_app' },
  { label: 'Manual Targeted Push', value: 'web_push' },
  // { label: 'Harasocial', value: 'harasocial' },
  { label: 'Sms', value: 'sms' },
  // { label: 'Zalo', value: 'zalo' },
]
export const DATE = [
  { label: 'Published', value: 'published' },
  { label: 'Draft', value: 'draft' },
]
