import React, { useEffect } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { Button } from 'antd'

import { isNextStepDisabled } from './helpers'
import NewCampaign from './New'
import EditCampaign from './Edit'

import './index.scss'
import './Components/index.scss'
import { useLocation } from 'react-router-dom'

const CampaignMainFlowLayout = ({ children }) => {
  const search = useLocation().search
  const id = new URLSearchParams(search).get('id')
  const { resetState, setState } = useStoreActions((actions: any) => actions.campaignModule)
  const { current_step, isContentValidated } = useStoreState((state: any) => state.campaignModule)
  const campaignStates = useStoreState((state: any) => state.campaignModule)

  useEffect(() => resetState, [resetState])

  return (
    <div className="w-full CampaignMainFlowLayout">
      <div className="campaign-layout-children">{children}</div>
      <div className="campaign-bottom-nav">
        <Button
          onClick={() => {
            setState({ key: 'current_step', value: current_step - 1 })
          }}
          disabled={current_step === 0}
        >
          Back
        </Button>
        {current_step === (id ? 1 : 2) && !isContentValidated ? (
          <Button form="ContentForm" key="submit" htmlType="submit" type="primary">
            Validate
          </Button>
        ) : (
          <Button
            disabled={isNextStepDisabled(current_step, campaignStates, id)}
            onClick={() => {
              setState({ key: 'current_step', value: current_step + 1 })
            }}
            type="primary"
          >
            Next
          </Button>
        )}
      </div>
    </div>
  )
}

export const New = () => {
  return (
    <CampaignMainFlowLayout>
      <NewCampaign />
    </CampaignMainFlowLayout>
  )
}
export const Edit = () => {
  return (
    <CampaignMainFlowLayout>
      <EditCampaign />
    </CampaignMainFlowLayout>
  )
}
