import React, { useState, useEffect, useRef } from 'react'
import { Upload, Card, Button, Tabs, message, List, Typography, Layout, Menu, Steps } from 'antd'

import { UploadRequestOption } from 'rc-upload/lib/interface'
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface.d'

import { compact } from 'lodash'

import { formatErrors } from 'Utils'
import { uploadProductImage } from 'Services/products'

export type ImageProps = {
  onChange: (images: string[] | string) => void
  value: string[]
  imageCount?: number
  defaultFileList?: string[] | any
}

const ImageField: React.FC<ImageProps> = ({
  onChange: setImages,
  value: images,
  imageCount = 1,
  defaultFileList,
}) => {
  const firstRun = useRef(true)
  const [fileList, setFileList] = useState<UploadFile[]>(
    images.length
      ? images?.map((image, index) => ({
          uid: index.toString(),
          name: 'image.png',
          status: 'done',
          url: image,
        }))
      : defaultFileList?.map((image, index) => ({
          uid: index.toString(),
          name: 'image.png',
          status: 'done',
          url: image,
        }))
  )

  const handleUploadImage = async (info: UploadRequestOption<string>) => {
    console.log('img ', info)
    try {
      const resp = await uploadProductImage({
        image: info.file,
        config: {
          onUploadProgress: (event) => {
            info.onProgress &&
              info.onProgress({ ...event, percent: (event.loaded / event.total) * 100 })
          },
        },
      })
      const xhr = new XMLHttpRequest()
      info.onSuccess && info.onSuccess(resp.data[0].url, xhr)
    } catch (err: any) {
      info.onError &&
        info.onError({
          name: 'image',
          message: formatErrors(err?.response?.data) || 'Cannot upload image, try another one',
        })
    }
  }

  const onChange = (info: UploadChangeParam) => {
    console.log('onchange', info)
    info.fileList.forEach((file: UploadFile) => {
      if (file.response) file.url = file.response
      if (file.status === 'error') file.thumbUrl = ''
    })
    setFileList(info.fileList)
  }

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false
      return
    }
    imageCount === 1
      ? setImages(fileList[0]?.url || '')
      : setImages(compact(fileList.map((file) => file.url)))
  }, [fileList, imageCount])

  const renderMeat = () => {
    return (
      <Upload
        // listType="picture-card"
        fileList={fileList}
        defaultFileList={fileList}
        customRequest={handleUploadImage}
        onChange={onChange}
      >
        <div>
          <Button className="mt-2 w-full">
            <div style={{ color: '#000000', fontWeight: 600 }}>Replace image</div>{' '}
          </Button>
        </div>
      </Upload>
    )
  }

  return <div>{renderMeat()}</div>
}

export default ImageField
