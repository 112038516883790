import { persist, createTypedHooks } from 'easy-peasy'

import auth from './auth'
import sideMenu from './side-menu'
import setting from './campaign-setting'
import products from './campaign-products'
import placements from './campaign-placements'
import progress from './campaign-progress'
import creativeContents from './campaign-creative-contents'
import schedule from './campaign-schedule'
import audience from './campaign-audience'
import segmentation from './segmentation'
import fbAudience from './campaign-fb-audience'
import campaignDetails from './campaign-details'

import customerJourney from './customer-journey'
import CJAnalytics from './customer-journey-analytics'
import campaignAnalytics from './campaign-analytics'
import audienceAnalytics from './audience-analytics'
import customerAnalytics from './customer-analytics'
import actionTrigger from './action-trigger'
import breadcrumb from './breadcrumb'
import harasocial from './Harasocial'
import dimensions from './dimensions'
import popup from './popup'
import randomvoucher from './randomvoucher'
import codedefault from './codedefault'

import customerSegments from './customer-segments'
import campaignModule from './campaign'

const campaign = { placements, products, setting, progress, creativeContents, schedule, audience }

export default {
  auth: persist(auth, {
    whitelist: ['email', 'token'],
  }),
  segmentation: persist(segmentation),
  sideMenu: persist(sideMenu, { storage: 'localStorage' }),
  // products,
  // placements,
  campaign: campaign,

  fbAudience: persist(fbAudience),
  campaignDetails,
  customerJourney,
  audienceAnalytics,
  customerAnalytics,
  CJAnalytics,
  campaignAnalytics,
  actionTrigger,
  customerSegments,
  campaignModule,
  breadcrumb,
  harasocial,
  dimensions,
  popup,
  randomvoucher,
  codedefault,
}
