import { action, thunk } from 'easy-peasy'
import axios from 'Utils/axios'
const mainPopup = {
  imgGift: {
    url: 'https://storage.googleapis.com/tch-mcm-public-storage/product-images/b08b366f-238c-473d-abcf-211510b91c8e',
  },
  imgArrow: {
    url: 'https://storage.googleapis.com/tch-mcm-public-storage/product-images/a9a71b30-abfb-4cf0-a0ff-10786507743b',
  },
  background: {
    url: 'https://storage.googleapis.com/dreamf-mcm-public-storage/product-images/b4302511-2b0e-4ed3-90f2-fb4e786e247d',
    backgroundSize: 'cover',
    opacity: 1,
    color: '#FFFFFF',
    timeOnSite: 15,
    products: 3,
    time: 0,
  },
  text: {
    content: 'SIÊU SALE NGÀY 11 - 11',
    style: {
      fontFamily: 'Inter',
      color: '#FA541C',
      fontSize: 36,
      fontStyle: 'bold',
      textAlign: 'left',
      opacity: 1,
    },
  },
  text_1: {
    content: 'XOAY LÀ TRÚNG NGAY',
    style: {
      fontFamily: 'Inter',
      color: '#fa541c',
      fontSize: 20,
      fontStyle: 'bold',
      textAlign: 'left',
      opacity: 1,
    },
  },
  text_2: {
    content:
      'Nhiều phần quà may mắn đang chờ đón bạn mùa siêu sale 11.11 năm nay. Bạn có 1 lượt quay, đừng bỏ lỡ nhé!',
    style: {
      fontFamily: 'Inter',
      color: '#000000',
      fontSize: 14,
      fontStyle: 'normal',
      textAlign: 'left',
      opacity: 1,
    },
  },

  input: {
    content: 'Email của bạn',
    background: {
      backgroundColor: 'white',
      opacity: 1,
    },
    style: {
      fontFamily: 'Inter',
      color: '#fffff',
      fontSize: 14,
      fontStyle: 'normal',
      textAlign: 'left',
      opacity: 1,
    },
    errorTextColor: '#F5222D',
  },
  button: {
    content: 'Bắt đầu xoay',
    buttonStyle: {
      hover: '#D4380D',
      opacity: 1,
    },
    style: {
      fontFamily: 'Inter',
      backgroundColor: '#FA541C',
      color: 'white',
      fontSize: 14,
      fontStyle: 'normal',
      textAlign: 'center',
      opacity: 1,
    },
  },

  wheel: {
    numberOptions: 6,
    textColor: '#FFFFFF',
    wheels: [
      { title: '15% OFF', color: '#FA541C', code: '', rate: '0.00' },
      { title: '15% OFF', color: '#FAAD14', code: '', rate: '0.00' },
      { title: '15% OFF', color: '#FA541C', code: '', rate: '0.00' },
      { title: '15% OFF', color: '#FAAD14', code: '', rate: '0.00' },
      { title: '15% OFF', color: '#FA541C', code: '', rate: '0.00' },
      { title: '15% OFF', color: '#FAAD14', code: '', rate: '0.00' },
    ],
    effect: {
      spinTime: 4,
      movement: 4,
      acceleration: 4,
    },
  },
}

const minimize = {
  background: {
    url: 'https://storage.googleapis.com/dreamf-mcm-public-storage/product-images/b4302511-2b0e-4ed3-90f2-fb4e786e247d',
    backgroundSize: 'cover',
    opacity: 1,
  },
  text: {
    content: 'SALE NGÀY 11 - 11',
    style: {
      fontFamily: 'Inter',
      color: '#FA541C',
      fontSize: 14,
      fontStyle: 'bold',
      textAlign: 'center',
      opacity: 1,
    },
  },
}

const result = {
  imgGift: {
    url: 'https://storage.googleapis.com/tch-mcm-public-storage/product-images/b08b366f-238c-473d-abcf-211510b91c8e',
  },
  background: {
    url: 'https://storage.googleapis.com/dreamf-mcm-public-storage/product-images/b4302511-2b0e-4ed3-90f2-fb4e786e247d',
    backgroundSize: 'cover',
    opacity: 1,
    effects: 'Confetti',
  },
  text: {
    content: 'Chúc mừng bạn đã nhận',
    style: {
      fontFamily: 'Inter',
      color: '#FA541C',
      fontSize: 28,
      fontStyle: 'bold',
      textAlign: 'center',
    },
  },
  code: {
    content: '{{coupon_title}}',
    style: {
      fontFamily: 'Inter',
      color: '#FA541C',
      fontSize: 28,
      fontStyle: 'bold',
      textAlign: 'center',
    },
  },
  text_2: {
    content: 'Code đã được gửi đến Email của bạn rồi đấy',
    style: {
      fontFamily: 'Inter',
      color: '#000000',
      fontSize: 16,
      fontStyle: 'bold',
      textAlign: 'center',
      opacity: 1,
    },
  },
  button: {
    content: 'Tiếp tục mua sắm',
    buttonStyle: {
      hover: '#D4380D',
      backgroundColor: '#FA541C',
    },
    style: {
      fontFamily: 'Inter',
      backgroundColor: '#FA541C',
      color: '#FFFFFF',
      fontSize: 14,
      fontStyle: 'bold',
      textAlign: 'center',
    },
  },
}
const email = {
  emailData: {
    subject: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    image_item: '',
    image_item_url: '',
    image_item_1: '',
    image_item_1_url: '',
    image_item_2: '',
    image_item_2_url: '',
  },
}
const initState = {
  active: false,
  type: '',
  element: '',
  step: 'mainPopup',
  device: 'desktop',
  current_step: 0,
  stepMax: 0,
  stepValue: 0,
  values: [{ mainPopup, minimize, result, email }],
  valueState: { mainPopup, minimize, result, email },
}

export default {
  ...initState,
  resetState: action((state, values) => {
    state.valueState = values
    state.values = []
    state.values[0] = values
    state.stepValue = 0
    state.stepMax = 4
  }),
  showEditChangeStep: action((state, value) => {
    switch (value) {
      case 0:
        state.step = 'mainPopup'
        break
      case 1:
        state.step = 'minimize'
        break
      case 2:
        state.step = 'result'
        break
      case 3:
        state.step = 'email'
        break
      default:
    }
    state.current_step = value
    state.active = state.step != 'email' && true
    state.step = state.step
    state.element = 'Background'
    state.type = 'background'
  }),
  resetUndoRedo: action((state, value) => {
    switch (value) {
      case 0:
        state.step = 'mainPopup'
        break
      case 1:
        state.step = 'minimize'
        break
      case 2:
        state.step = 'result'
        break
      case 3:
        state.step = 'email'
        break
      default:
    }

    state.current_step = value
    state.values = state.values.slice(state.stepValue, state.stepValue + 1)
    state.stepValue = 0
    state.active = state.step != 'email' && true
    state.step = state.step
    state.element = 'Background'
    state.type = 'background'
    if (value > state.stepMax) state.stepMax = value
  }),
  setUndo: action((state) => {
    state.stepValue = state.stepValue == 0 ? 0 : state.stepValue - 1
  }),
  setRedo: action((state) => {
    state.stepValue =
      state.stepValue == state.values.length - 1 ? state.values.length - 1 : state.stepValue + 1
  }),
  setState: action((state, { key, value }) => {
    state[key] = value
    state.active = false
    if (value > state.stepMax) state.stepMax = value
  }),
  toggle: action((state) => {
    state.active = !state.active
  }),
  chooseElement: action((state, { step, element, type }) => {
    state.active = true
    state.step = step
    state.element = element
    state.type = type
  }),
  editElementComp: action((state, value) => {
    if (state.current_step == 3) {
      state.step = 'email'
      state.type = 'emailData'
    }
    state.valueState[state.step][state.type] = { ...value }

    if (state.stepValue == state.values.length - 1) {
      state.values.push(state.valueState)
      state.stepValue = state.stepValue + 1
    } else {
      if (state.stepValue == 0) {
        state.stepValue = state.stepValue + 1
        state.values.splice(state.stepValue, 0, state.valueState)
      } else {
        state.values.splice(state.stepValue, 0, state.valueState)
        state.stepValue = state.stepValue
      }
    }
  }),
  setDevice: action((state, value) => {
    state.device = value
  }),
  setColorText: action((state, value) => {
    state.valueState['mainPopup']['wheel']['textColor'] = value
    if (state.stepValue == state.values.length - 1) {
      state.values.push(state.valueState)
      state.stepValue = state.stepValue + 1
    } else {
      if (state.stepValue == 0) {
        state.stepValue = state.stepValue + 1
        state.values.splice(state.stepValue, 0, state.valueState)
      } else {
        state.values.splice(state.stepValue, 0, state.valueState)
        state.stepValue = state.stepValue
      }
    }
  }),
  setEffectSpinGame: action((state, { key, value }) => {
    state.valueState[state.step][state.type]['effect'][key] = value
    if (state.stepValue == state.values.length - 1) {
      state.values.push(state.valueState)
      state.stepValue = state.stepValue + 1
    } else {
      if (state.stepValue == 0) {
        state.stepValue = state.stepValue + 1
        state.values.splice(state.stepValue, 0, state.valueState)
      } else {
        state.values.splice(state.stepValue, 0, state.valueState)
        state.stepValue = state.stepValue
      }
    }
  }),
  setSingleElement: action((state, { step, element, key, value }) => {
    if (state.current_step == 3) {
      state.step = 'email'
      state.type = 'emailData'
    }
    state.valueState[state.step][state.type][key] = value

    if (state.stepValue == state.values.length - 1) {
      state.values.push(state.valueState)
      state.stepValue = state.stepValue + 1
    } else {
      if (state.stepValue == 0) {
        state.stepValue = state.stepValue + 1
        state.values.splice(state.stepValue, 0, state.valueState)
      } else {
        state.values.splice(state.stepValue, 0, state.valueState)
        state.stepValue = state.stepValue
      }
    }
  }),
  setElement: action((state, { step, element, key, value, item, index }) => {
    state.valueState[step][element][key][index][item] = value
    if (state.stepValue == state.values.length - 1) {
      state.values.push(state.valueState)
      state.stepValue = state.stepValue + 1
    } else {
      if (state.stepValue == 0) {
        state.stepValue = state.stepValue + 1
        state.values.splice(state.stepValue, 0, state.valueState)
      } else {
        state.values.splice(state.stepValue, 0, state.valueState)
        state.stepValue = state.stepValue
      }
    }
  }),

  setNumberOptionWheel: action((state, value) => {
    state.valueState.mainPopup.wheel.numberOptions = parseInt(value)
    if (parseInt(value) <= state.valueState.mainPopup.wheel.wheels.length) {
      state.valueState.mainPopup.wheel.wheels = state.valueState.mainPopup.wheel.wheels.slice(
        0,
        value
      )
    } else {
      for (var i = state.valueState.mainPopup.wheel.wheels.length; i < value; i++) {
        state.valueState.mainPopup.wheel.wheels.push({ title: `Title ${i}`, color: 'red' })
      }
    }
    if (state.stepValue == state.values.length - 1) {
      state.values.push(state.valueState)
      state.stepValue = state.stepValue + 1
    } else {
      if (state.stepValue == 0) {
        state.stepValue = state.stepValue + 1
        state.values.splice(state.stepValue, 0, state.valueState)
      } else {
        state.values.splice(state.stepValue, 0, state.valueState)
        state.stepValue = state.stepValue
      }
    }
  }),
  handleChangeState: action((state) => {
    if (state.stepValue == state.values.length - 1) {
      state.values.push(state.valueState)
      state.stepValue = state.stepValue + 1
    } else {
      if (state.stepValue == 0) {
        state.stepValue = state.stepValue + 1
        state.values.splice(state.stepValue, 0, state.valueState)
      } else {
        state.values.splice(state.stepValue, 0, state.valueState)
        state.stepValue = state.stepValue
      }
    }
  }),
}
