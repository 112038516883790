import React, { useState, useEffect } from 'react'
import { LeftOutlined } from '@ant-design/icons'
import { Select, InputNumber, Button, Input, message } from 'antd'
import { CustomerItem, Segment } from 'DimensionsFiltersTypes'

import CommonInputs from '../../Inputs/CommonInputs'
import TimeInput from './Customer.SegmentScreen.TimeInput'

export type props = {
  segment: CustomerItem
  isEditing?: boolean
  initialValues?: Segment
  onOk: (value: Segment) => void
  onCancel: () => void
}

const SegmentScreen: React.FC<props> = ({ segment, onCancel, onOk, isEditing, initialValues }) => {
  const [value, setValue] = useState<string>('')
  const [operator, setOperator] = useState<string>('')
  const [timeOperator, setTimeOperator] = useState<any>()
  const [timeValue, setTimeValue] = useState<string>('')

  const getValues = (values, v) => {
    const item = values?.find((e) => e.code === v)
    return item?.values
  }

  const checkNumRegex =
    /[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ_<>=^\s+?|$!@#$%\\^&*)(_'"`~/:;{}\[\]-][a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ_<>=^\s+?|$!@#$%\\^&*)(_'"`~/\[\]-]*/

  useEffect(() => {
    setValue(initialValues?.value || '')
    initialValues?.operator && setOperator(initialValues?.operator)
    initialValues?.time_operator && setTimeOperator(initialValues?.time_operator)
    initialValues?.time_value && setTimeValue(initialValues?.time_value?.toString())
  }, [initialValues])

  const isNumber = value
    .toString()
    .split(',')
    .filter((e) => e != '')
    .every((e) => e.match(checkNumRegex))

  const testIsDisable = () => {
    if (value) {
      if (segment.field == 'number') {
        if (operator == 'IS_BETWEEN' || operator == 'IS_NOT_BETWEEN') {
          if (
            value.split(',').length == 2 &&
            typeof parseFloat(value.split(',')[0]) == 'number' &&
            typeof parseFloat(value.split(',')[0]) == 'number' &&
            value?.split(',')[0].normalize('NFC').match(checkNumRegex) == null &&
            value?.split(',')[1].normalize('NFC').match(checkNumRegex) == null
          ) {
            return false
          } else {
            return true
          }
        } else {
          if (isNumber) {
            return true
          } else {
            return false
          }
        }
      } else if (segment.field == 'string') {
        if (operator == 'IS_BETWEEN' || operator == 'IS_NOT_BETWEEN') {
          if (
            value.split(',').length == 2 &&
            typeof parseFloat(value.split(',')[0]) == 'string' &&
            typeof parseFloat(value.split(',')[0]) == 'string'
          ) {
            return false
          } else {
            return true
          }
        } else {
          if (!isNumber) {
            return true
          } else {
            return false
          }
        }
      } else if (segment.field == 'option') {
        return false
      }
    } else {
      return true
    }
  }

  const renderInput = () => {
    if (segment.field === 'number') {
      return (
        <Input
          style={{ height: 40, borderRadius: 4, width: 150 }}
          placeholder="Value"
          value={value}
          onChange={(e) => {
            e.target.value
              .toString()
              .split(',')
              .filter((e) => e != '')
              .some((e) => e.normalize('NFC').match(checkNumRegex)) &&
              message.warning("Invalid input: input couldn't be text", 0.75)
            setValue(e.target.value.toString())
          }}
        />
      )
    } else {
      return (
        <Input
          style={{ height: 40, borderRadius: 4, width: 150 }}
          placeholder="Value"
          value={value}
          onChange={(e) => {
            !e.target.value
              .toString()
              .split(',')
              .filter((e) => e != '')
              .every((e) => e.normalize('NFC').match(checkNumRegex)) &&
              message.warning("Invalid input: input couldn't be number", 0.75)
            setValue(e.target.value.toString())
          }}
        />
      )
    }
  }

  const renderOptionsInput = () => {
    if (segment.values.length) {
      if (operator === 'IN_THE_FOLLOWING' || operator === 'NOT_IN_THE_FOLLOWING') {
        return (
          <CommonInputs
            placeholder="Value"
            value={value ? value?.split(',') : []}
            options={segment?.values.map((op) => ({ label: op?.name, value: op?.code }))}
            type="MULTI_SELECT"
            onChange={(value) => {
              setValue(value.toString())
            }}
          />
        )
      } else if (operator === 'IS_BETWEEN' || operator === 'IS_NOT_BETWEEN') {
        return (
          <CommonInputs
            placeholder="Value"
            value={value ? value?.split(',').slice(-2) : []}
            options={segment?.values.map((op) => ({ label: op?.name, value: op?.code }))}
            type="MULTI_SELECT"
            onChange={(value) => {
              setValue(value.toString().split(',').slice(-2).join(',').toString())
            }}
          />
        )
      } else {
        return (
          <CommonInputs
            placeholder="Value"
            value={value}
            options={segment?.values.map((op) => ({ label: op.name, value: op.code }))}
            type="SINGLE_SELECT"
            onChange={(value) => setValue(value.toString())}
          />
        )
      }
    } else if (operator === 'IS_BETWEEN' || operator === 'IS_NOT_BETWEEN') {
      return renderInput()
    } else {
      return renderInput()
    }
  }

  return (
    <div className="w-full h-full ml-4 p-4">
      <div
        className="CustomerSegmentScreenComponent flex items-center text-sm cursor-pointer"
        onClick={onCancel}
        style={{ width: 'fit-content' }}
      >
        <LeftOutlined className="cursor-pointer" />
        <span className="ml-2">{segment?.name}</span>
      </div>

      <div className="flex mt-6 items-center">
        <span className="mr-4">{segment?.name}</span>
        <div className="mr-4">
          <CommonInputs
            placeholder="Select operator"
            value={operator?.toString() || undefined}
            options={segment?.operator.map((op) => ({ label: op.name, value: op.code }))}
            type="SINGLE_SELECT"
            onChange={(v) => {
              if (timeOperator?.name === 'IN_THE_LAST') {
                setTimeValue('')
              }
              setOperator(v?.toString())
              setValue('')
            }}
          />
        </div>
        <div className="mr-4">{renderOptionsInput()}</div>

        {segment?.time_operator?.length != 0 && (
          <div className="flex">
            <div className="mr-4">
              <CommonInputs
                placeholder="When"
                value={
                  isEditing
                    ? typeof timeOperator == 'object'
                      ? timeOperator?.name
                      : timeOperator
                    : timeOperator?.name ||
                      initialValues?.time_operator?.name?.toString() ||
                      undefined
                }
                options={segment?.time_operator?.map((op) => ({ label: op.name, value: op.code }))}
                type="SINGLE_SELECT"
                onChange={(v) =>
                  setTimeOperator({
                    name: v?.toString(),
                    values: getValues(segment?.time_operator, v?.toString()),
                  })
                }
              />
            </div>
            {timeOperator ? (
              <div className="mr-4" style={{ width: 200 }}>
                <TimeInput
                  timeOperator={timeOperator}
                  value={timeValue}
                  onChange={setTimeValue}
                  isEditing={isEditing}
                />
              </div>
            ) : null}
          </div>
        )}

        <Button
          disabled={testIsDisable()}
          onClick={() => {
            onOk({
              type: segment.code,
              operator,
              value,
              time_operator: timeOperator,
              time_value: timeValue,
            })
          }}
          type="text"
          className="font-semibold text-brand_primary cursor-pointer p-0"
        >
          {isEditing ? 'Update' : 'Add'}
        </Button>
      </div>
    </div>
  )
}

export default SegmentScreen
