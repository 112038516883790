import React, { useState, useEffect, useRef } from 'react'
import { Upload, message } from 'antd'
import { FileImageOutlined } from '@ant-design/icons'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface.d'
import { PlusOutlined } from '@ant-design/icons'
import { compact } from 'lodash'

import { formatErrors } from 'Utils'
import { uploadProductImage } from 'Services/products'

export type ImageProps = {
  onChange: (images: string[] | string | undefined) => void
  value: string[] | undefined
  imageCount?: number
  defaultFileList?: string[] | any
}

const ImageField: React.FC<ImageProps> = ({
  onChange: setImages,
  value,
  imageCount = 1,
  defaultFileList,
}) => {
  const images = Array.isArray(value) ? value : value ? [value] : undefined
  const firstRun = useRef(true)
  const [fileList, setFileList] = useState<UploadFile[]>(
    images?.length
      ? images?.map((image, index) => ({
          uid: index.toString(),
          name: 'image.png',
          status: 'done',
          url: image,
        }))
      : []
  )
  useEffect(() => {}, [value])
  const handleUploadImage = async (info: UploadRequestOption) => {
    try {
      const file: any = info.file
      if (!file?.type.includes('image/')) {
        throw new Error('Only image files are allowed')
      }

      const resp = await uploadProductImage({
        image: info.file,
        config: {
          onUploadProgress: (event) => {
            info.onProgress &&
              info.onProgress({ ...event, percent: (event.loaded / event.total) * 100 })
          },
        },
      })
      const xhr = new XMLHttpRequest()
      info.onSuccess && info.onSuccess(resp.data[0].url, xhr)
    } catch (err: any) {
      info.onError &&
        info.onError({
          name: 'image',
          message: err.message || 'Cannot upload image, try another one',
        })
    }
  }

  const onChange = (info: UploadChangeParam) => {
    info.fileList.forEach((file: UploadFile) => {
      if (file.response) file.url = file.response
      if (file.status === 'error') file.thumbUrl = ''
    })
    setFileList(info.fileList)
  }

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false
      return
    }

    if (imageCount === 1) {
      if (fileList.length && !fileList[0]?.url) return
      setImages(fileList[0]?.url)
      return
    }

    setImages(compact(fileList.map((file) => file.url)))
  }, [fileList, imageCount])

  const renderMeat = () => {
    if (fileList && fileList[0] && fileList[0].status === 'done')
      return (
        <Upload
          listType="picture-card"
          fileList={fileList}
          defaultFileList={fileList}
          customRequest={handleUploadImage}
          onChange={onChange}
        >
          {fileList.length < imageCount && (
            <div>
              <PlusOutlined />
              <div className="mt-2">Upload</div>
            </div>
          )}
        </Upload>
      )
    else
      return (
        <Upload.Dragger fileList={fileList} customRequest={handleUploadImage} onChange={onChange}>
          <p className="ant-upload-drag-icon">
            <FileImageOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
        </Upload.Dragger>
      )
  }

  return <div>{renderMeat()}</div>
}

export default ImageField
