import React, { useState } from 'react'
import { ReactComponent as Close } from '../img/close-line.svg'
import { Upload, Card, Button, Tabs, message, List, Typography, Layout, Menu, Steps } from 'antd'
import { EditOutlined, CloseOutlined } from '@ant-design/icons'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import { useStoreActions, useStoreState } from 'easy-peasy'
// import gift from '../img/giftOrange.png'
import gift from '../img/gift.png'
import arrow from '../img/arrowcurve.png'
import { uploadProductImage } from 'Services/products'
import { formatErrors } from 'Utils'

import ImageField from '../Image'
const { Title } = Typography

const EditElement = ({ toggle, values }) => {
  console.log('image ', values)
  const { type, step } = useStoreState((state) => state.popup)
  const { setSingleElement } = useStoreActions((action: any) => action.popup)

  const [valueImage, setValueImage] = useState([])

  const handleImage = (name, value) => {
    value?.[value.length - 1] &&
      setSingleElement({
        step: step,
        element: type,
        key: 'url',
        value: value?.[value.length - 1],
      })
  }

  const renderImg = (content) => {
    return !content ? (type == 'imgArrow' ? arrow : gift) : content
  }
  return (
    <Card
      size="small"
      title={
        <Title style={{ padding: '8px 4px' }} level={5}>
          Element
        </Title>
      }
      extra={
        <Close
          onClick={() => toggle()}
          className="cursor-pointer"
          style={{ fontSize: '20px', color: '#00000' }}
        />
      }
      bodyStyle={{ padding: '16px' }}
    >
      <div className="EditPopupElement">
        <span>Image</span>
      </div>
      <div className="EditPopupItems mt-8">
        <div>
          <img
            style={{ width: 111, height: 112, objectFit: 'cover' }}
            src={renderImg(values?.url)}
            alt=""
          />
        </div>
        <div className=" mt-4 w-full">
          <ImageField
            imageCount={2}
            onChange={(images) => {
              handleImage('image', images)
            }}
            value={[]}
          />
        </div>
      </div>
    </Card>
  )
}

export default EditElement
