import React, { useEffect, useState } from 'react'
import { Tree, Checkbox } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { flattenChannelChildren } from '../../helpers'

const DimensionsMktChannel = () => {
  const { dimensionsDraft, channelGroup } = useStoreState((state: any) => state.dimensions)
  const { onAddDimension, onChangeDimension, onRemoveDimension } = useStoreActions(
    (action: any) => action.dimensions
  )

  const dimensionType = 'CHANNEL'

  const channelDimensionIndex = dimensionsDraft.findIndex(
    (item) => item?.dimension?.segments[0]?.type === dimensionType
  )
  const checkedKeys =
    dimensionsDraft[channelDimensionIndex]?.dimension?.segments[0].value?.split(',') || []

  const channels = flattenChannelChildren(channelGroup)

  const [indeterminate, setIndeterminate] = useState(false)
  const [isCheckedAll, setIsCheckedAll] = useState(checkedKeys.length === channels.length)

  const onCheckKeys = (nodes: any) => {
    const selectedNodes = nodes.checkedNodes.filter((node) => !node.children)
    const selectedKeys = selectedNodes.map((node) => node.key)

    const dimensionValue = {
      segments: [{ type: dimensionType, operator: 'EQUAL', value: selectedKeys.toString() || '' }],
      condition: 'AND',
    }

    if (channelDimensionIndex >= 0) {
      if (!selectedKeys.length) {
        onRemoveDimension(channelDimensionIndex)
        return
      }

      onChangeDimension({
        index: channelDimensionIndex,
        newDimension: dimensionValue,
      })
      return
    }

    onAddDimension(dimensionValue)
  }

  const onCheckAllChange = (e) => {
    const dimensionValue = {
      segments: [
        {
          type: dimensionType,
          operator: 'EQUAL',
          value: channels.map((channel) => channel.key).toString(),
        },
      ],
      condition: 'AND',
    }

    if (!e.target.checked) {
      onRemoveDimension(channelDimensionIndex)
      return
    }

    if (channelDimensionIndex >= 0) {
      onChangeDimension({
        index: channelDimensionIndex,
        newDimension: dimensionValue,
      })
      return
    }

    onAddDimension(dimensionValue)
  }

  useEffect(() => {
    setIndeterminate(checkedKeys.length < channels.length && checkedKeys.length > 0)
    setIsCheckedAll(checkedKeys.length === channels.length)
  }, [checkedKeys, channels])

  return (
    <div className="ml-4 p-4">
      <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={isCheckedAll}>
        All
      </Checkbox>
      <Tree
        checkable
        checkedKeys={checkedKeys}
        treeData={channelGroup}
        onCheck={(keys, nodes: any) => {
          onCheckKeys(nodes)
        }}
        selectable={false}
      />
    </div>
  )
}

export default DimensionsMktChannel
