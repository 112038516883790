import React from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

const TitlePopup = ({ changeType, textContent }) => {
  const { type } = useStoreState((state) => state.codedefault)
  const { content, style } = textContent
  const { fontFamily, color, fontSize, fontStyle, textAlign } = style
  const fontStyleClass = (fontStyle) => {
    if (fontStyle == 'bold') {
      return 'font-semibold'
    }
    if (fontStyle == 'underline') {
      return 'underline'
    }
    if (fontStyle == 'italic') {
      return 'italic'
    }
  }
  return (
    <>
      <div
        className={`cursor-pointer ${fontStyleClass(fontStyle)} ${
          type == 'text' && `border-active-element`
        }`}
        onClick={(e) => {
          e.stopPropagation()
          changeType({ step: 'mainPopup', element: 'Text', type: 'text' })
        }}
        style={{
          ...style,
          width: 262,
          fontStyle: fontStyle == 'italic' ? 'italic' : '',
          lineHeight: fontSize + 'px',
        }}
      >
        {content == '' ? 'Title' : content}
      </div>
    </>
  )
}

export default TitlePopup
