import React from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import backgroundImg from '../img/Background.png'
const Minimize = ({ chooseElement, device, initialValues }) => {
  const { type } = useStoreState((state) => state.randomvoucher)
  const fontStyleClass = (fontStyle) => {
    if (fontStyle == 'bold') {
      return 'font-semibold'
    }
    if (fontStyle == 'underline') {
      return 'underline'
    }
    if (fontStyle == 'italic') {
      return 'italic'
    }
  }
  const { minimize } = initialValues
  const { text, background } = minimize
  const { content, style } = text

  const renderWidthDevice = () => {
    if (device == 'desktop') {
      return '191px'
    }
    if (device == 'mobile') {
      return '100%'
    }
  }
  return (
    <div style={{ height: 538 }}>
      <div className="h-full flex">
        <div style={{ width: renderWidthDevice() }} className="MinimizePopup">
          <div
            className={`MinimizeItem truncate px-2 cursor-pointer ${fontStyleClass(
              style?.fontStyle
            )} `}
            style={{
              height: 48,
              backgroundImage: `url(${background?.url && background?.url})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: background?.backgroundSize,
              backgroundColor: `${background?.color && background?.color}`,
              ...style,
              fontStyle: style?.fontStyle == 'italic' ? 'italic' : '',
            }}
            onClick={() => {
              chooseElement({ step: 'minimize', element: 'Background', type: 'background' })
            }}
          >
            <span
              onClick={(e) => {
                e.stopPropagation()
                // chooseElement({ step: 'minimize', type: 'Text' })
                chooseElement({ step: 'minimize', element: 'Text', type: 'text' })
              }}
              className={`${type == 'text' && `border-active-element`}`}
            >
              {content == '' ? 'Title' : content}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Minimize
