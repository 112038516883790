import React from 'react'
import { Modal, Card, Select, Tabs, Input, List, Typography, Layout, Menu, Steps } from 'antd'
import { EditOutlined, CloseOutlined } from '@ant-design/icons'
import { ReactComponent as Close } from '../img/close-line.svg'
import ColorPickerFull from '../../SpinGame/ColorPickerPopup/Modal/ColorPickerFull'
const { Title } = Typography
const { Option } = Select
const EditPopup = ({ toggle, values, editElement, title }) => {
  console.log('value ', values)
  const handleOnClickChangeStyle = (props) => {
    editElement({ ...values, color: props?.value, opacity: props?.opacity, url: null })
    console.log(props)
  }

  const handleChangeEffects = (value) => {
    editElement({ ...values, effects: value })
  }

  const renderEffect = () => {
    return (
      <>
        <div className="EditPopupElement">
          <Title style={{ fontSize: 14 }} level={5}>
            Effects
          </Title>
        </div>
        <div className="EditPopupItems mt-4 mb-6">
          <div className="flex justify-between">
            <div className="flex-1">
              <span>Congratulation Effect</span>
            </div>
            <div className="flex-1">
              <Input.Group compact>
                <Select value={values?.effects} className="w-full" onChange={handleChangeEffects}>
                  <Option value={'None'}>None</Option>
                  <Option value={'Confetti'}>Confetti</Option>
                  <Option value={'Fireworks'}>Fireworks</Option>
                </Select>
              </Input.Group>
            </div>
          </div>
        </div>
      </>
    )
  }
  return (
    <>
      <Card
        size="small"
        title={
          <Title style={{ padding: '8px 4px', fontSize: 16 }} level={5}>{`Edit ${title}`}</Title>
        }
        extra={
          <Close
            className="cursor-pointer"
            onClick={() => toggle()}
            style={{ fontSize: '20px', color: '#00000' }}
          />
        }
        style={{ width: 300, borderBottom: 0 }}
        bodyStyle={{ padding: '16px' }}
      >
        {title == 'Result' && renderEffect()}
        <div className="EditPopupElement ">
          <Title style={{ fontSize: 14 }} level={5}>
            Background
          </Title>
        </div>
        <div className="EditPopupItems mt-4">
          <div className="flex justify-between items-center">
            <div>
              <span>Fill</span>
            </div>
            <div>
              <ColorPickerFull
                color={values?.color}
                choiceColor={handleOnClickChangeStyle}
                editElementComp={editElement}
                values={values}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  )
}

export default EditPopup
