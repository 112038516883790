import React from 'react'
import { CUSTOMER_DIMENSION, CUSTOMER_METRICS } from 'Pages/Analytics/constants'
import PivotTableTemplate from '../../../Components/PivotTable.Template'
import { CustomerPivotTypes } from 'Services/Analytics/Pivot.service'

const PivotRow1 = () => {
  const currentPage = window?.location?.href?.split('/')[4]?.split('?')[0]
  return (
    <>
      <div className="mb-4 font-semibold">Product Insight</div>
      <div className="py-4 col-span-1">
        <div className="mb-4 font-semibold">Popular Purchased Category and Product by LTV</div>
        <PivotTableTemplate
          hideColumnSelects
          currentPage={currentPage}
          type={CustomerPivotTypes.ProductInsight}
          isCustomer
          defaultDimensions={[{ code: 'ltv' }, ...CUSTOMER_DIMENSION]}
          defaultMetrics={CUSTOMER_METRICS}
        />
      </div>
      <div className="py-4 col-span-1">
        <div className="mb-4 font-semibold">Popular Purchased Category and Product by CLC</div>
        <PivotTableTemplate
          hideColumnSelects
          currentPage={currentPage}
          isCustomer
          type={CustomerPivotTypes.ProductInsight}
          defaultDimensions={[{ code: 'life_cycle' }, ...CUSTOMER_DIMENSION]}
          defaultMetrics={CUSTOMER_METRICS}
        />
      </div>
    </>
  )
}

export default PivotRow1
