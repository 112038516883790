import React from 'react'
import { Modal, Card, Button, Tabs, Input, List, Typography, Layout, Menu, Steps } from 'antd'
import { EditOutlined, CloseOutlined } from '@ant-design/icons'
import StylePopup from './SubComponentPopup/StylePopup'
import { ReactComponent as Close } from '../img/close-line.svg'
import { data } from 'jquery'

import { useStoreState, useStoreActions } from 'easy-peasy'
import ColorPickerComponent from './SubComponentPopup/ColorPickerComponent'
import ColorPickerFull from 'Components/CJChannels/Popup/SpinGame/ColorPickerPopup/Modal/ColorPickerFull'

const { Title } = Typography

const EditInput = ({ toggle, values, editElement }) => {
  const style = values?.style

  const handleOnChange = (e) => {
    console.log(e.target.value)
    const upDateValue = {
      ...values,
      content: e.target.value,
    }
    editElement(upDateValue)
  }

  const handleOnClickChangeStyle = (props) => {
    const upDateValue = {
      ...values,
      style: {
        ...style,
        backgroundColor: props.value,
        opacity: props.opacity,
      },
    }

    editElement(upDateValue)
  }
  return (
    <>
      <Card
        size="small"
        title={
          <Title style={{ padding: '8px 4px' }} level={5}>
            Input
          </Title>
        }
        extra={
          <Close
            onClick={() => toggle()}
            className="cursor-pointer"
            style={{ fontSize: '20px', color: '#00000' }}
          />
        }
        bodyStyle={{ padding: '16px' }}
      >
        <div className="EditPopupElement">
          <Title style={{ fontSize: 14 }} level={5}>
            Placeholder
          </Title>
          <div className="w-full mt-2">
            <Input
              className="py-2 pl-4"
              onChange={handleOnChange}
              style={{ color: '#000000' }}
              defaultValue={'Email của bạn'}
            />
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: '16px' }}>
        <div className="">
          <Title style={{ fontSize: 14 }} level={5}>
            Background
          </Title>
          <div className="flex justify-between items-center mt-6">
            <div className="ColorTitle ">
              <span>Color</span>
            </div>
            <div>
              <ColorPickerFull
                color={style?.backgroundColor}
                choiceColor={handleOnClickChangeStyle}
              />
            </div>
          </div>
        </div>
      </Card>
      <Card style={{ borderBottom: 0 }} bodyStyle={{ padding: '16px' }}>
        <Title style={{ fontSize: 14 }} level={5}>
          Text
        </Title>
        <div className="EditPopupItems mt-8">
          <StylePopup getElement={values} editElement={editElement} />
        </div>
      </Card>
    </>
  )
}

export default EditInput
