import React from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'

function ResultPopupMobile({ children }) {
  const changeType = useStoreActions((actions: any) => actions.randomvoucher.changeElement)
  return (
    <div>
      <div style={{ background: '#E5E5E5' }} className="MainPopupStepsContent h-full">
        <div className="MainPopupStep ">
          <div className="MainPopupStepContent flex ">
            <div
              style={{ width: 332, height: 568, backgroundColor: 'white' }}
              className="MainPopupEdit m-auto p-2 "
            >
              <div className="h-full">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResultPopupMobile
