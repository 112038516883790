import React, { useMemo, useState, useContext, useEffect } from 'react'

import { DIMENSION_GROUPS } from './constants'
import CustomerGroup from './Customer'
import ChannelGroup from './Channel'
import CampaignGroup from './Campaign'
import Segment from './Segment'
import DimensionGroupCounter from './DimensionGroup.Counter'

import { dimensionPopupContext } from '../Dimensions.Popup'
import { getDimensions } from 'Services/dimensions'

const DimensionsGroups = ({ activeDimension, segmentOptions }) => {
  const { dimensionsDraft: dimensions, setDimensionsDraft } = useContext(dimensionPopupContext)
  const [activeGroup, setActiveGroup] = useState(
    activeDimension == '' || activeDimension == undefined ? 'customer' : activeDimension
  )
  const DIMENSION_GROUPS_FILTER = [
    { value: 'customer', label: 'Audience' },
    // Chưa có data, uncomment sau khi có data
    // { value: 'segment', label: 'Segment' },
    // { value: 'campaign', label: 'Marketing Campaign' },
    // { value: 'channel', label: 'Marketing Channel' },
  ]
  const isSegment = window.location.href.split('/')[4] === 'segments'

  const renderGroup = useMemo(() => {
    switch (activeGroup) {
      case 'customer':
        return <CustomerGroup />
      case 'segment':
        return isSegment && <Segment options={segmentOptions} />
      case 'channel':
        return <ChannelGroup />
      case 'campaign':
        return <CampaignGroup />
    }
  }, [activeGroup])

  return (
    <div className="DimensionGroups flex w-full h-full overflow-scroll">
      <div className="w-1/4 border-r p-4">
        {(isSegment ? DIMENSION_GROUPS : DIMENSION_GROUPS_FILTER).map((group) => (
          <div
            className={`${
              activeGroup === group?.value && 'bg-brand_light'
            } font-semibold px-4 py-2 cursor-pointer rounded flex justify-between items-center`}
            key={group.value}
            onClick={() => {
              setActiveGroup(group.value)
              activeGroup != group?.value && setDimensionsDraft({ type: 'clear' })
            }}
          >
            <span>{group.label}</span>
            <DimensionGroupCounter group={group.value} dimensions={dimensions} />
          </div>
        ))}
      </div>
      <div className="w-3/4 relative overflow-y-scroll overflow-x-hidden">{renderGroup}</div>
    </div>
  )
}

export default DimensionsGroups
