import React, { useState } from 'react'
import { Form, Button, Popover, message, Input, Select } from 'antd'

import { useStoreActions, useStoreState } from 'easy-peasy'

import FormItem from 'Components/FormItem'
import './index.scss'
import ValidateLinkForm from './VaidateLinkForm'
import axios from 'Utils/axios'
import { formatErrors } from 'Utils'

const CATEGORY_OPTIONS = [
  { value: 'Sale', label: 'Sale' },
  { value: 'FullPrice', label: 'Full Price' },
  { value: 'NewCollection', label: 'New Collection' },
]

let timeoutId

const NewLinkPage = (props) => {
  const [link, setLink] = useState({})
  const [validateFormVisible, setValidateFormVisible] = useState(false)
  const [validateFormState, setValidateFormState] = useState(false)
  const [creating, setCreating] = useState(false)
  const me = useStoreState((state) => state.auth.userInfo)
  const handleCreate = async () => {
    setCreating(true)
    try {
      const resp = await axios.post('/social-selling/', link)
      props.onCreateSuccess(resp.data)
    } catch (error: any) {
      message.error(formatErrors(error?.response?.data?.detail))
    }
    setCreating(false)
  }

  return (
    <div className="NewLinkPaneComponent h-full flex flex-col">
      <div className="title-wrapper p-4 shadow-div_b flex items-center">
        <h2 className="__title text-xl font-semibold">New Link</h2>
      </div>
      <Form
        className="flex flex-col justify-between h-full"
        layout="vertical"
        name="ContentForm"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 24 }}
        onFinish={handleCreate}
      >
        <div className="p-4 mb-auto">
          <Form.Item label="Link gốc" name="origin_link" rules={[{ required: true }]}>
            <Input
              placeholder="kshort.ai/Bily"
              onChange={(event) => setLink({ ...link, origin_link: event.target.value })}
            ></Input>
          </Form.Item>
          <Form.Item label="Phân loại nội dung" name="category" rules={[{ required: true }]}>
            <Select
              placeholder="Select a Category"
              onChange={(val) => setLink({ ...link, category: val })}
              options={CATEGORY_OPTIONS.map((option) => ({
                value: option.value,
                label: option.label,
              }))}
            />
          </Form.Item>
          <Form.Item label="Nội dung post" name="content">
            <Popover
              className="validate-form-popover"
              placement="bottom"
              visible={validateFormVisible}
              content={
                <ValidateLinkForm
                  text={link['content']}
                  invalidCallBack={() => {
                    setValidateFormState(false)
                  }}
                ></ValidateLinkForm>
              }
            >
              <Input
                placeholder="content..."
                onChange={(event) => {
                  setValidateFormState(true)
                  setLink({ ...link, content: event.target.value })
                  setValidateFormVisible(true)
                  clearTimeout(timeoutId)
                  timeoutId = setTimeout(() => {
                    setValidateFormVisible(false)
                  }, 1500)
                }}
              ></Input>
            </Popover>
          </Form.Item>
          <Form.Item label="Mã nhân viên">
            <Input readOnly value={me.hara_employee_code}></Input>
          </Form.Item>
        </div>
        <div className="pane-footer flex mt-auto shadow-div_t p-4 justify-end">
          <Button className="mr-2" onClick={props.onClosePanel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={creating} disabled={!validateFormState}>
            Create
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default NewLinkPage
