import React, { useState, useEffect } from 'react'

import { Empty, Skeleton, Table } from 'antd'
import TasksReport from 'Components/StoreFarming/TasksReport'
import MetricCard from 'Components/MetricCard'
import { BarLineChart } from 'Components/Charts/MixCharts'
import { columns, SAMPLE_DATA, CHART_OPTIONS } from '../constants'
import axios from 'Utils/axios'
import '../index.scss'

type ManagerReportRespons = { card: any; chart: any; table: any; progress_bar: any }

const Overview = ({ fromDate, toDate }) => {
  const [data, setData] = useState<null | ManagerReportRespons>(null)
  useEffect(() => {
    setData(null)
    ;(async () => {
      try {
        let fDate = fromDate.format('YYYY-MM-DD')
        let tDate = toDate.format('YYYY-MM-DD')
        const resp = await axios.get(
          `/store-farming/stats/overview?from_date=${fDate}&to_date=${tDate}`
        )
        setData(resp.data)
      } catch (error: any) {}
    })()
  }, [fromDate, toDate])

  const renderCards = (card, position) => {
    if (!data) return <Skeleton></Skeleton>
    else if (!data.card) return <Empty></Empty>
    return (
      <MetricCard
        className={`grid-box-${position} ${position === 1 ? 'shadow-div_r' : 'shadow-div_rb'}`}
        value={card.value}
        unit={card.unit}
        title={card.title}
      ></MetricCard>
    )
  }

  const renderProgressMetrics = () => {
    if (!data)
      return (
        <div className="p-4">
          <Skeleton></Skeleton>
        </div>
      )
    else if (!data.card) return <Empty></Empty>
    return (
      <>
        {renderCards(data.card[0], 1)}
        {renderCards(data.card[1], 2)}
        {renderCards(data.card[2], 3)}
        <TasksReport data={data.progress_bar} className="shadow-div_r"></TasksReport>
        {renderCards(data.card[3], 4)}
        {renderCards(data.card[4], 5)}
      </>
    )
  }

  const renderLineBarChart = () => {
    if (!data)
      return (
        <div className="p-4">
          <Skeleton></Skeleton>
        </div>
      )
    else if (!data.chart) return <Empty></Empty>
    return <BarLineChart data={SAMPLE_DATA} opts={CHART_OPTIONS}></BarLineChart>
  }

  const renderTable = () => {
    if (!data)
      return (
        <div className="p-4">
          <Skeleton></Skeleton>
        </div>
      )
    else if (!data.chart) return <Empty></Empty>
    return <Table dataSource={data.table} columns={columns} />
  }

  return (
    <div className="SFOverviewComponent mt-4 ">
      <div className="tasks-progress-container bg-gray_1 jobs-progress-container">
        {renderProgressMetrics()}
      </div>
      <div className="chart-container bg-white mt-4">{renderLineBarChart()}</div>
      <div className="table-container mt-4">{renderTable()}</div>
    </div>
  )
}

export default Overview
