import React, { useState } from 'react'

import {
  Modal,
  Card,
  Button,
  Tabs,
  Input,
  List,
  Typography,
  Layout,
  Menu,
  Steps,
  Select,
} from 'antd'

import {
  UnderlineOutlined,
  CloseOutlined,
  BoldOutlined,
  ItalicOutlined,
  AlignLeftOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
} from '@ant-design/icons'
import StylePopup from './SubComponentPopup/StylePopup'
import { ReactComponent as Close } from '../img/close-line.svg'
import { useStoreActions, useStoreState } from 'easy-peasy'

const { Option } = Select
const { Title } = Typography
const EditText = ({ toggle, values, editElement }) => {
  const { content, style } = values
  console.log(values)

  const handleOnChange = (e) => {
    console.log(e.target.value)
    const upDateValue = {
      ...values,
      content: e.target.value,
    }
    console.log(upDateValue)
    editElement(upDateValue)
  }
  return (
    <>
      <Card
        size="small"
        title={
          <Title style={{ padding: '8px 4px' }} level={5}>
            Text
          </Title>
        }
        extra={
          <Close
            onClick={() => toggle()}
            className="cursor-pointer"
            style={{ fontSize: '20px', color: '#00000' }}
          />
        }
        bodyStyle={{ padding: '16px' }}
      >
        <div className="EditPopupElement">
          <span>Content</span>
          <div className="w-full mt-2">
            <Input
              className="py-2 pl-4"
              onChange={(e) => handleOnChange(e)}
              style={{ color: '#000000' }}
              value={content}
            />
          </div>
        </div>
      </Card>
      <Card style={{ borderBottom: 0 }} bodyStyle={{ padding: '16px' }}>
        <div className="EditPopupItems">
          <StylePopup getElement={values} editElement={editElement} />
        </div>
      </Card>
    </>
  )
}

export default EditText
