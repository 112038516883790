import React from 'react'
import { Divider, List, Typography } from 'antd'
const handleStatistics = async (record) => {
  window.open(`/analytics/campaign360/${record.id}`)
}
const ItemCampaigns = ({ title, data }) => {
  return (
    <>
      <Divider orientation="left">{title}</Divider>
      {data ? (
        <List
          style={{ border: 0 }}
          size="small"
          bordered
          dataSource={data}
          renderItem={(item: any) => (
            <List.Item style={{ borderBottomWidth: 0 }}>
              <a onClick={() => handleStatistics(item)} href="" style={{ color: 'black' }}>
                {item.name}
              </a>
            </List.Item>
          )}
        />
      ) : (
        <List
          style={{ border: 0 }}
          size="small"
          bordered
          dataSource={[]}
          renderItem={(item: any) => <List.Item>{item.name}</List.Item>}
        />
      )}
    </>
  )
}

export default ItemCampaigns
