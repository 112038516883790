import React from 'react'
import { Modal, Card, Button, Tabs, Input, List, Typography, Layout, Menu, Select } from 'antd'
import { EditOutlined, CloseOutlined } from '@ant-design/icons'
import { useState } from 'react'
import EditWheelModal from './SubComponentPopup/EditWheelModal'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { ReactComponent as Close } from '../img/close-line.svg'
import ColorPickerFull from 'Components/CJChannels/Popup/SpinGame/ColorPickerPopup/Modal/ColorPickerFull'

const { Title } = Typography
const EditWheel = ({ toggle, values }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { setElement } = useStoreActions((actions: any) => actions.randomvoucher)

  const { numberOptions, gifts } = values
  const showModal = () => {
    setIsModalVisible(true)
  }
  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  function handleChange(value) {
    console.log(`selected ${value}`)
  }

  const handleOnClickChangeStyle = (props, index) => {
    console.log(props, index)
    setElement({
      step: 'mainPopup',
      element: 'Wheels',
      key: 'wheels',
      item: 'color',
      value: props.value,
      index: index,
    })

    // if (!props?.opacity) {
    //   const upDateValue = {
    //     ...getElement,
    //     style: {
    //       ...style,
    //       [props.key]: props.value,
    //     },
    //   }
    //   editElement(upDateValue)
    // } else {
    //   const upDateValue = {
    //     ...getElement,
    //     style: {
    //       ...style,
    //       [props.key]: props.value,
    //       opacity: props.opacity,
    //     },
    //   }
    //   editElement(upDateValue)
    // }
  }
  return (
    <>
      <Card
        size="small"
        title={
          <Title style={{ padding: '8px 4px' }} level={5}>
            Gifts
          </Title>
        }
        extra={
          <Close
            onClick={() => toggle()}
            className="cursor-pointer"
            style={{ fontSize: '20px', color: '#00000' }}
          />
        }
        bodyStyle={{ padding: '16px' }}
      >
        <div className="EditPopupItems">
          <div className="w-full ">
            <Button
              style={{ color: '#000000', fontWeight: 600 }}
              onClick={() => showModal()}
              className="w-full"
            >
              Edit Content
            </Button>
          </div>
          <div className="WheelModal">
            <EditWheelModal
              numberOptions={numberOptions}
              wheels={gifts}
              // data={Wheel}
              isModalVisible={isModalVisible}
              handleCancel={handleCancel}
              handleOk={handleOk}
            />
          </div>
        </div>
      </Card>
    </>
  )
}

export default EditWheel
