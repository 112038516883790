export const COLOR_TAG = [
  {
    value: 'magenta',
    label: 'Magenta',
  },
  {
    value: 'red',
    label: 'Red',
  },
  {
    value: 'volcano',
    label: 'Volcano',
  },
  {
    value: 'orange',
    label: 'Orange',
  },
  {
    value: 'gold',
    label: 'Gold',
  },
  {
    value: 'lime',
    label: 'Lime',
  },
  {
    value: 'green',
    label: 'Green',
  },
  {
    value: 'cyan',
    label: 'Cyan',
  },
  {
    value: 'blue',
    label: 'Blue',
  },
  {
    value: 'geekblue',
    label: 'Geekblue',
  },
  {
    value: 'purple',
    label: 'Purple',
  },
  {
    value: 'gray',
    label: 'Gray',
  },
]

export const randomColor = [
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'green',
  'cyan',
  'blue',
  'geekblue',
  'purple',
  'gray',
]

export const transformNameToId = (nameArr, fullArr) => {
  const temp = []
  fullArr.map((item) => {
    if (nameArr.includes(item.value)) {
      temp.push(item.id)
    }
  })
  return temp
}

export const findIdFromValue = (value, fullArr) => {
  let id = ''
  fullArr.map((item) => {
    if (item.value === value) {
      id = item.id
    }
  })
  return id
}

export const findValueFromId = (id, fullArr) => {
  let value = ''
  fullArr.map((item) => {
    if (item.id === id) {
      id = item.value
    }
  })
  return value
}
