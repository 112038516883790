import { action, thunk } from 'easy-peasy'

const mainPopup = {
  imgGift: {
    url: 'https://storage.googleapis.com/tch-mcm-public-storage/product-images/2e2d2df1-19e1-431a-b29d-78fda4426c29',
  },
  background: {
    url: '',
    backgroundSize: 'cover',
    opacity: 1,
    color: '#F0F5FF',
  },
  text: {
    content: 'Bạn có muốn nhận quà?',
    style: {
      fontFamily: 'Inter',
      color: '#000',
      weight: '600',
      fontSize: 20,
      fontStyle: 'bold',
      textAlign: 'center',
      opacity: 1,
    },
  },
  text_1: {
    content: 'Nhập email và chọn ngẫu nhiên một món quà dành riêng cho bạn',
    style: {
      fontFamily: 'Inter',
      color: '#000',
      fontSize: 16,
      fontStyle: 'normal',
      textAlign: 'center',
      opacity: 1,
    },
  },
  text_2: {
    content: 'Email không hợp lệ!!',
    style: {
      fontFamily: 'Inter',
      color: '#F5222D',
      fontSize: 14,
      fontStyle: 'normal',
      textAlign: 'center',
      opacity: 1,
    },
  },
  input: {
    content: 'Email của bạn',
    background: {
      backgroundColor: 'white',
      opacity: 1,
    },
    style: {
      fontFamily: 'Inter',
      color: '#fffff',
      fontSize: 14,
      fontStyle: 'normal',
      textAlign: 'left',
      opacity: 1,
    },
  },
  gift: {
    numberOptions: 1,
    gifts: [
      { title: 'Title', code: '' },
      { title: 'Title', code: '' },
      { title: 'Title', code: '' },
      { title: 'Title', code: '' },
      { title: 'Title', code: '' },
    ],
    effect: {},
  },
}

const minimize = {
  background: {
    url: 'https://storage.googleapis.com/tch-mcm-public-storage/product-images/d5e1afdf-db0b-43fa-a6d5-98f6a134a188',
    backgroundSize: 'cover',
    opacity: 1,
  },
  text: {
    content: 'Bạn có muốn nhận quà?',
    style: {
      fontFamily: 'Inter',
      color: '#000',
      fontSize: 14,
      fontStyle: 'bold',
      textAlign: 'center',
      opacity: 1,
    },
  },
}

const result = {
  imgGift: {
    url: 'https://storage.googleapis.com/tch-mcm-public-storage/product-images/2e2d2df1-19e1-431a-b29d-78fda4426c29',
  },
  background: {
    url: '',
    backgroundSize: 'cover',
    opacity: 1,
    color: '#F0F5FF',
    effects: 'Confetti',
  },
  text: {
    content: 'Chúc mừng bạn đã nhận',
    style: {
      fontFamily: 'Inter',
      color: '#000',
      fontSize: 20,
      fontStyle: 'bold',
      textAlign: 'center',
    },
  },
  code: {
    content: '{{coupon_title}}',
    style: {
      fontFamily: 'Inter',
      color: '#000',
      fontSize: 20,
      fontStyle: 'bold',
      textAlign: 'center',
    },
  },
  text_1: {
    content: 'Mã khuyến mãi đã được gửi vào email của bạn rồi đấy, bạn kiểm tra nhé!',
    style: {
      fontFamily: 'Inter',
      color: '#000000',
      fontSize: 16,
      fontStyle: 'normal',
      textAlign: 'center',
      opacity: 1,
    },
  },
  button: {
    content: 'Tiếp tục mua sắm',
    buttonStyle: {
      hover: '#D4380D',
      // backgroundColor: '#FA541C',
    },
    style: {
      fontFamily: 'Inter',
      backgroundColor: '#FA541C',
      color: '#FFFFFF',
      fontSize: 14,
      fontStyle: 'bold',
      textAlign: 'center',
      width: 232,
      height: 40,
    },
  },
}
const email = {
  emailData: {
    subject: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    image_item: '',
    image_item_url: '',
    image_item_1: '',
    image_item_1_url: '',
    image_item_2: '',
    image_item_2_url: '',
  },
}

const initState = {
  active: false,
  emailValid: '',
  type: '',
  element: '',
  step: 'mainPopup',
  device: 'desktop',
  current_step: 0,
  stepMax: 0,
  stepValue: 0,
  values: [{ mainPopup, minimize, result, email }],
  valueState: { mainPopup, minimize, result, email },
}

export default {
  ...initState,
  resetState: action((state, values) => {
    state.valueState = values
    state.values = []
    state.values[0] = values
    state.stepValue = 0
    state.stepMax = 4
  }),
  showEditChangeStep: action((state, value) => {
    switch (value) {
      case 0:
        state.step = 'mainPopup'
        break
      case 1:
        state.step = 'minimize'
        break
      case 2:
        state.step = 'result'
        break
      case 3:
        state.step = 'email'
        break
      default:
    }
    state.current_step = value
    state.active = true
    state.step = state.step
    state.element = 'Background'
    state.type = 'background'
  }),
  resetUndoRedo: action((state, value) => {
    switch (value) {
      case 0:
        state.step = 'mainPopup'
        break
      case 1:
        state.step = 'minimize'
        break
      case 2:
        state.step = 'result'
        break
      case 3:
        state.step = 'email'
        break
      default:
    }

    state.current_step = value
    state.values = state.values.slice(state.stepValue, state.stepValue + 1)
    state.stepValue = 0
    state.active = state.step != 'email' && true
    state.step = state.step
    state.element = 'Background'
    state.type = 'background'
    if (value > state.stepMax) state.stepMax = value
  }),
  setUndo: action((state) => {
    state.stepValue = state.stepValue == 0 ? 0 : state.stepValue - 1
  }),
  setRedo: action((state) => {
    state.stepValue =
      state.stepValue == state.values.length - 1 ? state.values.length - 1 : state.stepValue + 1
  }),
  setState: action((state, { key, value }) => {
    state[key] = value
    state.active = false
    if (value > state.stepMax) state.stepMax = value
  }),
  toggle: action((state) => {
    state.active = !state.active
  }),
  chooseElement: action((state, { step, element, type }) => {
    state.active = true
    state.step = step
    state.element = element
    state.type = type
  }),
  editElementComp: action((state, value) => {
    if (state.current_step == 3) {
      state.step = 'email'
      state.type = 'emailData'
    }
    state.valueState[state.step][state.type] = { ...value }

    if (state.stepValue == state.values.length - 1) {
      state.values.push(state.valueState)
      state.stepValue = state.stepValue + 1
    } else {
      if (state.stepValue == 0) {
        state.stepValue = state.stepValue + 1
        state.values.splice(state.stepValue, 0, state.valueState)
      } else {
        state.values.splice(state.stepValue, 0, state.valueState)
        state.stepValue = state.stepValue
      }
    }
  }),
  setDevice: action((state, value) => {
    state.device = value
  }),
  setSingleElement: action((state, { step, element, key, value }) => {
    if (state.current_step == 3) {
      state.step = 'email'
      state.type = 'emailData'
    }
    state.valueState[state.step][state.type][key] = value

    if (state.stepValue == state.values.length - 1) {
      state.values.push(state.valueState)
      state.stepValue = state.stepValue + 1
    } else {
      if (state.stepValue == 0) {
        state.stepValue = state.stepValue + 1
        state.values.splice(state.stepValue, 0, state.valueState)
      } else {
        state.values.splice(state.stepValue, 0, state.valueState)
        state.stepValue = state.stepValue
      }
    }
  }),
  setElement: action((state, { step, element, key, value, item, index }) => {
    state.valueState[step][element][key][index][item] = value

    if (state.stepValue == state.values.length - 1) {
      state.values.push(state.valueState)
      state.stepValue = state.stepValue + 1
    } else {
      if (state.stepValue == 0) {
        state.stepValue = state.stepValue + 1
        state.values.splice(state.stepValue, 0, state.valueState)
      } else {
        state.values.splice(state.stepValue, 0, state.valueState)
        state.stepValue = state.stepValue
      }
    }
  }),
  setNumberOptionWheel: action((state, value) => {
    state.valueState.mainPopup.gift.numberOptions = parseInt(value)
    if (parseInt(value) <= state.valueState.mainPopup.gift.gifts.length) {
      state.valueState.mainPopup.gift.gifts = state.valueState.mainPopup.gift.gifts.slice(0, value)
    } else {
      for (var i = state.valueState.mainPopup.gift.gifts.length; i < value; i++) {
        state.valueState.mainPopup.gift.gifts.push({ title: `Title ${i}` })
      }
    }
    if (state.stepValue == state.values.length - 1) {
      state.values.push(state.valueState)
      state.stepValue = state.stepValue + 1
    } else {
      if (state.stepValue == 0) {
        state.stepValue = state.stepValue + 1
        state.values.splice(state.stepValue, 0, state.valueState)
      } else {
        state.values.splice(state.stepValue, 0, state.valueState)
        state.stepValue = state.stepValue
      }
    }
  }),
}
