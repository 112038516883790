import React, { useEffect, useState } from 'react'
import { Breadcrumb, Button, Form, message, Skeleton } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import JunoTemplate from '../JunoTemplate'

import CreativeContent from 'Containers/CreativeContent'
import { karibaEmailValidation, sendgridEmailValidation } from './index.validation'
import {
  karibaEmailSchemas,
  karibaInitialValues,
  sendGridInitialValues,
  sendgridEmailSchemas,
  cjSendgridEmailSchemas,
} from './index.constants'
import { getEmailTemplate, getEmailTemplate as getEmailTemplateApi } from '../index.services'

import './index.scss'
import FormItem from 'Containers/Campaign/MainFlow/Components/Body/Content/Form/FormItem'
import cloneDeep from 'lodash/cloneDeep'
import { useStoreActions } from 'easy-peasy'

const EmailEditContentForm = ({
  onBack,
  onSubmit, // for CJ
  values,
  seletedEmail,
  isCampaign = false,
  emailType,
  setValue, // for campaign // Todo: fix later
  setCreativeContentsState,
  breadcrumbVisble,
  isRunning,
}: any) => {
  const validation = emailType === 'kariba' ? karibaEmailValidation : sendgridEmailValidation
  const [loading, setLoading] = useState(false)
  const [emailContent, setEmailContent] = useState(() => {
    return { ...values, template_id: seletedEmail?.id || values.template_id }
  })
  // console.log('emailContent ', emailContent)
  const { setState } = useStoreActions((action: any) => action.campaignModule)
  const [emailSchemas, setEmailSchemas] = useState<any>(sendgridEmailSchemas)
  const [orgEmailTemplate, setOrgEmailTemplate] = useState<any>('')
  const isManualCampaign = window.location.pathname.split('/')[1] === 'campaigns'
  const [schema, setSchema] = useState(
    emailType === 'kariba'
      ? karibaEmailSchemas
      : isManualCampaign
      ? emailSchemas
      : cjSendgridEmailSchemas
  )
  const [emailPreview, setEmailPreview] = useState<any>(null)
  const [valueReview, setValueReview] = useState<any>([]) //[{ {{code}}: value },]
  const re = /(?:\{+)(.+?)(?:\}+)/
  useEffect(() => {
    var html_string = orgEmailTemplate
    /* eslint-disable */
    valueReview?.map((item) => {
      const key = Object.keys(item)[0]
      const value = Object.values(item)[0]
      html_string = value ? html_string.replace(key, value) : html_string
    })
    isManualCampaign && setEmailPreview(html_string)
  }, [valueReview, emailPreview])

  useEffect(() => {
    if (!isEmpty(values)) {
      setEmailContent({ ...values, template_id: seletedEmail?.id || values.template_id })
      setCreativeContentsState({ key: 'previewData', value: values })
    }
  }, [setCreativeContentsState, values, seletedEmail])

  useEffect(() => {
    setState({ key: 'isContentValidated', value: false })
  }, [])
  useEffect(() => {
    const getEmailSchemas = async () => {
      // if (id === 'string') return
      // message.loading({ content: 'Loading email template...', key: 'loading-template' })
      try {
        const html = await getEmailTemplate(seletedEmail?.id || values.template_id)
        // let wnd = window.open('about:blank', '', '_blank')
        isManualCampaign && setEmailSchemas(emailSchemas?.concat(html?.email_schema))
        // console.log('email_schema', emailSchemas)
        setEmailPreview(html?.content)
        setOrgEmailTemplate(html?.content)
        const keys = Object.keys(values)
        isManualCampaign &&
          setValueReview((prev) => {
            return html?.email_schema?.map((item) => {
              const regexKey = item.code.match(re)[1]
              return { [item.code]: values[regexKey] || '' }
            })
          })
        // return wnd?.document.write(html)
        /* eslint-enable */
      } catch (error: any) {
        message.error({ content: 'Load email template failed', key: 'loading-template' })
      }
    }
    emailType != 'kariba' && getEmailSchemas()
  }, [seletedEmail?.id])

  useEffect(() => {
    if (emailType === 'kariba') return
    const getEmailTemplate = async () => {
      // Load sendgrid email template
      setLoading(true)
      try {
        const res = await getEmailTemplateApi(emailContent.template_id)
        let count = 1
        const newSchema: any = cloneDeep(isManualCampaign ? emailSchemas : cjSendgridEmailSchemas)
        while (true) {
          if (String(res).includes(`image_item_${count}`)) {
            newSchema.splice(6 + count * 2, 0, {
              name: `image_item_${count}`,
              type: 'image',
              label: `Image (image_item_${count})`,
            })
            newSchema.splice(7 + count * 2, 0, {
              name: `image_item_${count}_url`,
              type: 'string',
              label: `Image (image_item_${count}_url)`,
            })
            count++
            continue
          }
          break
        }
        setSchema(newSchema)
      } catch (err: any) {
        message.error('Failed to load preview')
      }
      setLoading(false)
    }
    getEmailTemplate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.template_id, seletedEmail, emailSchemas])

  const handleCJSubmit = (values: any) => {
    if (isCampaign) {
      message.success('Content is validated')
      setState({ key: 'isContentValidated', value: true })
    } else {
      onSubmit({ ...values, template_id: seletedEmail?.id || values.template_id })
    }
  }

  const handleEmailCampaingChange = (name, value) => {
    //Set values for content - campaign models
    if (setValue && typeof setValue === 'function') {
      setValue(name, value)
    }
  }

  const handleFieldChange = (schema, value) => {
    if (isCampaign) {
      setValueReview((prev) => {
        let flag = false
        const arrayValue = prev.map((item) => {
          const key = Object.keys(item)[0]
          const valueOfKey = Object.values(item)[0]
          if (key == schema.code) {
            flag = true
            return { [key]: value || '' }
          } else return item
        })
        if (!flag) {
          return [...arrayValue, { [schema?.code]: value }]
        } else {
          return [...arrayValue]
        }
      })
      handleEmailCampaingChange(schema?.name, value)
      setEmailContent({ ...emailContent, [schema?.name]: value, html_string: emailPreview })
    } else {
    }
  }

  const renderCampaignForm = React.useCallback(() => {
    return (
      <Form
        initialValues={emailContent}
        name="ContentForm"
        onFinish={handleCJSubmit}
        className="w-1/2 p-4 bg-white"
        layout="vertical"
      >
        {schema &&
          schema?.map((schema, index) => (
            <Form.Item
              className={schema?.type === 'hidden' ? 'hidden' : ''}
              name={schema?.name}
              key={index}
              label={schema?.label}
              hasFeedback
              rules={[{ required: schema?.isRequired, message: `${schema?.label} is required` }]}
            >
              <FormItem
                defaultValue={emailContent && emailContent[schema?.name]}
                type={schema?.type}
                value={emailContent && emailContent[schema?.name]}
                options={schema?.options}
                onChange={(value) => {
                  handleFieldChange(schema, value)
                }}
              />
            </Form.Item>
          ))}
        {!isCampaign && (
          <div className="flex justify-end">
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        )}
      </Form>
    )
  }, [schema])

  const renderForm = () => {
    if (loading) return <Skeleton></Skeleton>
    return renderCampaignForm()
  }
  return (
    <div className="EmailEditContentFormComponent">
      {breadcrumbVisble && (
        <Breadcrumb>
          <Breadcrumb.Item className="template-breadcrumb" onClick={onBack}>
            Templates
          </Breadcrumb.Item>
          <Breadcrumb.Item>Edit {emailType} email content</Breadcrumb.Item>
        </Breadcrumb>
      )}

      <div className="input-fields" style={{ pointerEvents: isRunning ? 'none' : 'auto' }}>
        {/* Form */}
        {renderForm()}
        {/* Preview  */}
        {/* {emailType === 'kariba' && <JunoTemplate />} */}
        {isManualCampaign && <JunoTemplate content={emailPreview}></JunoTemplate>}
      </div>
    </div>
  )
}
export default connect(
  (state: any) => ({
    initData: state.campaign.creativeContents.previewData,
  }),
  (dispatch: any) => ({
    setCreativeContentsState: dispatch.campaign.creativeContents.setState,
    clearPreviewData: dispatch.campaign.creativeContents.clearPreviewData,
  })
)(EmailEditContentForm)
