import { Input } from 'antd'
import React, { useEffect, useState } from 'react'
import ImageField from './Image'

const { TextArea } = Input
const CustomHtml = ({ value, onChange, setBlockedLinks, blockedLinks }) => {
  const [linkImage, setLinkImage] = useState<string | null>(null)
  const [itmSource, setItmSource] = useState<string>('')
  const [itmMedium, setItmMedium] = useState<string>('')
  const [itmCampaign, setItmCampaign] = useState<string>('')
  const [valueOrigin, setValueOrigin] = useState<string>(value || '')
  useEffect(() => {
    value && setValueOrigin(value)
  }, [value])

  useEffect(() => {
    onChange(valueOrigin)
  }, [valueOrigin])

  useEffect(() => {
    const ConvertStringToHTML = function (str) {
      let parser = new DOMParser()
      let doc = parser.parseFromString(str, 'text/html')
      return doc.body
    }

    const covertHtml = ConvertStringToHTML(value)
    const imgSrc = covertHtml?.getElementsByTagName('img')[0]?.getAttribute('src')
    const getATag = covertHtml
      ?.getElementsByTagName('a')[0]
      ?.getAttribute('href')
      ?.split('?')[1]
      .split('&')

    if (imgSrc) {
      setLinkImage(imgSrc)
    }
    if (getATag) {
      getATag.map((item) => {
        const getKey = item.split('=')
        switch (getKey[0]) {
          case 'itm_source':
            setItmSource(getKey[1])
            break
          case 'itm_medium':
            setItmMedium(getKey[1])
            break
          case 'itm_campaign':
            setItmCampaign(getKey[1])
            break
          default:
            break
        }
      })
    }
  }, [value])

  const replaceText = (e, key, value) => {
    setValueOrigin(valueOrigin.replace(key + value, key + e.target.value))
  }
  const onChangeBlockedLinks = (e) => {
    const value = e.target.value
    console.log('value ', value)
    console.log('value ', value?.split(','))
    setBlockedLinks(value)
  }
  const onChangeInput = (e, code) => {
    switch (code) {
      case 'itmSource':
        setItmSource(e.target.value)
        replaceText(e, 'itm_source=', itmSource)
        break
      case 'itmMedium':
        setItmMedium(e.target.value)
        replaceText(e, 'itm_medium=', itmMedium)
        break
      case 'itmCampaign':
        setItmCampaign(e.target.value)
        replaceText(e, 'itm_campaign=', itmCampaign)
        break
      default:
        break
    }
  }

  const onChangeImage = (e) => {
    setLinkImage(e)
  }

  return (
    <>
      <div className="grid grid-cols-3 pb-4 border-b border-divider">
        <div className="col-span-1 mr-4">
          <div>
            <div className="mb-2 font-semibold text-secondary_text">Image</div>
            <ImageField onChange={onChangeImage} value={linkImage ? [linkImage] : []} />
            <div>{linkImage}</div>
          </div>
          <div className="mt-4">
            <div className="mb-2 font-semibold text-secondary_text">ITM Source</div>
            <div>
              <Input
                maxLength={200}
                value={itmSource}
                onChange={(e) => onChangeInput(e, 'itmSource')}
                size="large"
                disabled={!itmSource && !value.includes('itm_source=') && true}
              ></Input>
            </div>
            <div className="flex items-center justify-between mt-2 text-secondary_text">
              <div>Hint</div>
              <div>{itmSource.length} / 200</div>
            </div>
          </div>
          <div className="mt-4">
            <div className="mb-2 font-semibold text-secondary_text">ITM Medium</div>
            <div>
              <Input
                maxLength={200}
                value={itmMedium}
                onChange={(e) => onChangeInput(e, 'itmMedium')}
                size="large"
                disabled={!itmMedium && !value.includes('itm_medium=') && true}
              ></Input>
            </div>
            <div className="flex items-center justify-between mt-2 text-secondary_text">
              <div>Hint</div>
              <div>{itmMedium.length} / 200</div>
            </div>
          </div>
          <div className="mt-4">
            <div className="mb-2 font-semibold text-secondary_text">ITM Campaign</div>
            <div>
              <Input
                maxLength={200}
                value={itmCampaign}
                onChange={(e) => onChangeInput(e, 'itmCampaign')}
                size="large"
                disabled={!itmCampaign && !value.includes('itm_campaign') && true}
              ></Input>
            </div>
            <div className="flex items-center justify-between mt-2 text-secondary_text">
              <div>Hint</div>
              <div>{itmCampaign.length} / 200</div>
            </div>
          </div>
          <div className="mt-4">
            <div className="mb-2 font-semibold text-secondary_text">Blocked Links</div>
            <div>
              <Input
                maxLength={200}
                value={blockedLinks}
                onChange={(e) => {
                  onChangeBlockedLinks(e)
                }}
                size="large"
              ></Input>
            </div>
            <div className="flex items-center justify-between mt-2 text-secondary_text">
              <div>Example: link1,link2,link3</div>
            </div>
          </div>
        </div>
        <div className="col-span-2">
          <TextArea
            className="h-full"
            value={valueOrigin || ''}
            onChange={(e) => onChange(e.target.value)}
          ></TextArea>
        </div>
      </div>
    </>
  )
}

export default CustomHtml
