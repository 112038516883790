import React from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

const ElementPopup = ({ changeType, img }) => {
  const { type } = useStoreState((state) => state.randomvoucher)
  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        changeType({ step: 'mainPopup', element: 'Image', type: 'imgGift' })
      }}
      style={{ width: 298, height: 95 }}
      className={`WheelContent__right-gift flex justify-center cursor-pointer ${
        type == 'imgGift' && `border-active-element`
      }`}
    >
      <img style={{ objectFit: 'cover', width: 116, height: 80 }} src={img} alt="" />
    </div>
  )
}

export default ElementPopup
