import React, { useState, useEffect } from 'react'

import RankingCard from 'Components/RankingCard/index'
import axios from 'Utils/axios'
import { Skeleton } from 'antd'

export type LeaderBoardType = {
  fromDate: any
  toDate: any
}

const LeaderBoards: React.FC<LeaderBoardType> = ({ fromDate, toDate }) => {
  const [leaderBoard, setLeaderBoard] = useState(null)
  useEffect(() => {
    setLeaderBoard(null)
    let fDate = fromDate.format('YYYY-MM-DD')
    let tDate = toDate.format('YYYY-MM-DD')
    ;(async () => {
      try {
        const resp = await axios.get(
          `/store-farming/stats/leaderboard?from_date=${fDate}&to_date=${tDate}&company=${process.env.REACT_APP_COMPANY_NAME}&force=true&limit=12`
        )
        setLeaderBoard(resp.data)
      } catch (error: any) {}
    })()
  }, [fromDate, toDate])
  if (!leaderBoard)
    return (
      <div className="w-full bg-white p-3 mt-4">
        <Skeleton></Skeleton>
      </div>
    )
  return (
    <div className="LeaderBoardsComponent mt-4">
      <RankingCard
        list={(leaderBoard as any)?.top_rev_staff_by_store?.values}
        title={(leaderBoard as any)?.top_rev_staff_by_store?.title}
        subtitle={(leaderBoard as any)?.top_rev_staff_by_store?.subtitle}
        type={(leaderBoard as any)?.top_rev_staff_by_store?.type}
        currentRank={(leaderBoard as any)?.top_rev_staff_by_store?.current_rank}
      />
      <RankingCard
        list={(leaderBoard as any)?.top_rev_store?.values}
        title={(leaderBoard as any)?.top_rev_store?.title}
        subtitle={(leaderBoard as any)?.top_rev_store?.subtitle}
        type={(leaderBoard as any)?.top_rev_store?.type}
        currentRank={(leaderBoard as any)?.top_rev_store?.current_rank}
      />
      <RankingCard
        list={(leaderBoard as any)?.top_rev_staff_by_org?.values}
        title={(leaderBoard as any)?.top_rev_staff_by_org?.title}
        subtitle={(leaderBoard as any)?.top_rev_staff_by_org?.subtitle}
        type={(leaderBoard as any)?.top_rev_staff_by_org?.type}
        currentRank={(leaderBoard as any)?.top_rev_staff_by_org?.current_rank}
      />
    </div>
  )
}

export default LeaderBoards
