import React from 'react'
import backgroundImg from '../img/Background.png'
import CongratulationResult from './SubComponentPopup/CongratulationResult'
import { useStoreActions, useStoreState } from 'easy-peasy'

const Result = ({ initialValues, chooseElement }) => {
  const { type, device } = useStoreState((state) => state.popup)
  const { result } = initialValues
  const { background } = result
  return (
    <div
      style={{
        width: `${device == 'mobile' ? '302px' : '701px'} `,
        height: `${device == 'mobile' ? '508px' : '539px'} `,
      }}
      onClick={() => {
        chooseElement({ step: 'result', element: 'Background', type: 'background' })
      }}
      className={`WheelContent flex bottom-0 overflow-y-scroll cursor-pointer`}
    >
      <div
        style={{
          width: `${device == 'mobile' ? '302px' : '696px'} `,
          height: `${device == 'mobile' ? '508px' : '539px'} `,
          backgroundImage: `url(${background?.url && background?.url})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: background?.backgroundSize,
          backgroundColor: `${background?.color && background?.color}`,
          opacity: background?.opacity,
        }}
        className="absolute"
      ></div>
      <div className="m-auto relative w-full">
        <CongratulationResult initialValues={initialValues} chooseElement={chooseElement} />
      </div>
    </div>
  )
}

export default Result
