import React from "react";
import reactCSS from "reactcss";

export const CustomPointer = () => {
  const styles = reactCSS({
    default: {
      pickerwrapper: {
        width: "64px",
        height: "64px",
        transform: "translate(-26px, -26px)",
      },
      picker: {
        cx: "32",
        cy: "32",
        r: "11",
        stroke: "rgb(231 231 231)",
        strokeWidth: "10",
        fill: "none",
      },
    },
  });

  return (
    <svg style={styles.pickerwrapper}>
      <circle style={styles.picker} />
    </svg>
  );
};

export default CustomPointer;
