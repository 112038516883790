import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { message } from 'antd'

import { formatCampaignErrors } from './helpers'
import {
  updateCampaign,
  updateContent,
  updateCampaignSchedule,
  publishCampaign,
} from 'Services/campaigns'
import LoadingIcon from 'Components/LoadingIcon'
import { getCampaignDetail } from 'Services/campaigns'
import Header from './Components/Header'
import Body from './Components/Body'
import ProgressBar from './Components/ProgressBar'
import { setCampaignDetail } from './helpers'
import { addTagToCampaign } from 'Services/campaign-tags'

export default function EditCampaign() {
  const history = useHistory()
  const { id } = useParams()

  const [errors, setErrors] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [disableEditPublish, setDisableEditPublish] = useState(false)

  const { setBreadCrumb } = useStoreActions((actions: any) => actions.breadcrumb)

  const {
    name,
    description,
    mode,
    start_time,
    start_date,
    end_time,
    end_date,
    days,
    start_at,
    audience_id,
    selected_placement,
    content,
    content_id,
    daily_budget,
    bid_amount,
    kpi,
    id: campaign_id,
    campaign_tag_ids,
  } = useStoreState((state: any) => state.campaignModule)
  const { setState } = useStoreActions((actions: any) => actions.campaignModule)

  const handleUpdateCampaign = async () => {
    setIsLoading(true)
    try {
      await addTagToCampaign({ campaign_id: campaign_id, tag_ids: campaign_tag_ids })
      await updateCampaign({
        campaign_id: id,
        audience_segment_id: audience_id,
        name,
        description,
        daily_budget,
        bid_amount,
        kpi,
      })
      await updateContent({
        content_id: content_id,
        content: content,
        placement_id: selected_placement.id,
      })
      await updateCampaignSchedule({
        campaign_id: id,
        schedule: {
          mode,
          published: false,
          start_time: start_date && `${start_date} ${start_time}:00`,
          end_time: end_time && `${end_date} ${end_time}:00`,
          days,
          start_at: start_at && `${start_at}:00`,
        },
      })
      message.success('Update campaign successfully')
      history.push(`/campaigns`)
    } catch (err: any) {
      setErrors(formatCampaignErrors(err?.response?.data))
    }
    setIsLoading(false)
  }

  const handlePublishCampaign = async () => {
    setDisableEditPublish(true)
    try {
      await handleUpdateCampaign()
      await publishCampaign({ campaign_id, audience_id })
      await addTagToCampaign({ campaign_id: campaign_id, tag_ids: campaign_tag_ids })
      message.success('Published campaign successfully')
      history.push(`/campaigns`)
    } catch (err: any) {
      setErrors(formatCampaignErrors(err?.response?.data))
      setDisableEditPublish(false)
    }
  }

  useEffect(() => {
    const handleGetCampaignDetail = async () => {
      setIsLoading(true)
      try {
        const resp = await getCampaignDetail(id)
        await setCampaignDetail({
          data: resp.data,
          handler: setState,
        })
      } catch (err: any) {
        setErrors('Something went wrong')
      }
      setIsLoading(false)
    }
    handleGetCampaignDetail()
    setBreadCrumb([
      { title: 'Campaigns', path: '/campaigns' },
      { title: 'Detail', path: `/campaigns/${id}` },
    ])
  }, [setBreadCrumb, id, setState, disableEditPublish])

  if (isLoading)
    return (
      <div className="w-full mt-10 flex justify-center">
        <LoadingIcon />
      </div>
    )

  return (
    <div>
      <Header
        disablePublish={disableEditPublish}
        handlePublishCampaign={handlePublishCampaign}
        handleUpdateCampaign={handleUpdateCampaign}
        errors={errors}
        isSubmitting={isLoading}
      />
      <div className="py-4 px-7 bg-white">
        <ProgressBar />
      </div>

      <div>
        <Body />
      </div>
    </div>
  )
}
