import React from 'react'
import { Modal, Card, Button, Tabs, Input, List, Typography, Layout, Menu, Select } from 'antd'
import { EditOutlined, CloseOutlined } from '@ant-design/icons'
import { ReactComponent as Close } from '../img/close-line.svg'
import { useState } from 'react'
import EditWheelModal from './SubComponentPopup/EditWheelModal'
import { useStoreActions, useStoreState } from 'easy-peasy'
import ColorPickerFull from '../ColorPickerPopup/Modal/ColorPickerFull'

const { Title } = Typography
const EditWheel = ({ toggle, values }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { setElement, setColorText, setEffectSpinGame } = useStoreActions(
    (actions: any) => actions.popup
  )

  const { numberOptions, effect, wheels, textColor } = values
  const showModal = () => {
    setIsModalVisible(true)
  }
  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleChangeTextColor = (props) => {
    console.log(`selected ${props.value}`)
    var value = props.value
    setColorText(value)
  }

  const handleChangeSpinTime = (e) => {
    console.log('console ----> ', e.target.value)
    setEffectSpinGame({ key: 'spinTime', value: e.target.value })
  }

  const handleOnClickChangeStyle = (props, index) => {
    console.log(props, index)
    setElement({
      step: 'mainPopup',
      element: 'wheel',
      key: 'wheels',
      item: 'color',
      value: props.value,
      index: index,
    })

    // if (!props?.opacity) {
    //   const upDateValue = {
    //     ...getElement,
    //     style: {
    //       ...style,
    //       [props.key]: props.value,
    //     },
    //   }
    //   editElement(upDateValue)
    // } else {
    //   const upDateValue = {
    //     ...getElement,
    //     style: {
    //       ...style,
    //       [props.key]: props.value,
    //       opacity: props.opacity,
    //     },
    //   }
    //   editElement(upDateValue)
    // }
  }
  return (
    <>
      <Card
        size="small"
        title={
          <Title style={{ padding: '8px 4px' }} level={5}>
            Wheel
          </Title>
        }
        extra={<Close onClick={() => toggle()} className="cursor-pointer" />}
        bodyStyle={{ padding: '16px' }}
      >
        <div className="EditPopupItems">
          <div className="w-full ">
            <Button onClick={() => showModal()} className="w-full">
              Edit Content
            </Button>
          </div>
          <div className="WheelModal">
            <EditWheelModal
              numberOptions={numberOptions}
              wheels={wheels}
              // data={Wheel}
              isModalVisible={isModalVisible}
              handleCancel={handleCancel}
              handleOk={handleOk}
            />
          </div>
        </div>
        <div className="WheelColor mt-8">
          <span className="">Color</span>
          {wheels?.map((item, index) => (
            <div key={index} className="flex justify-between items-center align- mt-2">
              <div className="ColorTitle">Color {index + 1}</div>
              <div>
                <ColorPickerFull
                  color={item.color}
                  choiceColor={(props) => handleOnClickChangeStyle(props, index)}
                />
              </div>
            </div>
          ))}
          <div className="flex justify-between items-center align- mt-2">
            <div className="ColorTitle">Text Color</div>
            <div>
              <ColorPickerFull
                color={textColor}
                choiceColor={(props) => handleChangeTextColor(props)}
              />
            </div>
          </div>
        </div>
      </Card>
      <Card style={{ borderBottom: 0 }} bodyStyle={{ padding: '16px' }}>
        <div className="WheelEffect WheelColor">
          <span className="">Effects</span>
          <div className="flex justify-between mt-4 items-center">
            <div className="ColorTitle flex-1">Spin time</div>
            <div className="flex-1 text-right">
              <Input
                onChange={(e) => handleChangeSpinTime(e)}
                value={effect?.spinTime}
                style={{ width: 80 }}
                suffix={'s'}
              />
            </div>
          </div>
          <div className="flex justify-between mt-4 items-center">
            <div className="ColorTitle flex-1">Movement</div>
            <div className="flex-1 text-right">
              <Input disabled={true} value={4} style={{ width: 80 }} />
            </div>
          </div>
          <div className="flex justify-between mt-4 items-center">
            <div className="ColorTitle flex-1">Acceleration</div>
            <div className="flex-1 text-right">
              <Input disabled={true} value={4} style={{ width: 80 }} />
            </div>
          </div>
        </div>
      </Card>
    </>
  )
}

export default EditWheel
