import React from 'react'
import { ReactComponent as Close } from '../img/close-line.svg'
import {
  Modal,
  Card,
  Button,
  Tabs,
  Input,
  List,
  Typography,
  Layout,
  Menu,
  Steps,
  Select,
} from 'antd'
import { EditOutlined, CloseOutlined } from '@ant-design/icons'
import ColorPickerFull from '../ColorPickerPopup/Modal/ColorPickerFull'
import { FONTSIZE } from '../constants'
const { Title } = Typography
const { Option } = Select
const EditPopup = ({ toggle, values, editElementComp, title }) => {
  const handleOnClickChangeStyle = (props) => {
    editElementComp({ ...values, color: props?.value, opacity: props?.opacity, url: null })
  }

  const handleChangeEffects = (value) => {
    editElementComp({ ...values, effects: value })
  }

  const handleTimeOnSiteChange = (value) => {
    editElementComp({ ...values, timeOnSite: value })
  }
  const handleChangeProducts = (value) => {
    editElementComp({ ...values, products: value })
  }
  const handleChangeTime = (value) => {
    editElementComp({ ...values, time: value })
  }

  const renderEffect = () => {
    return (
      <>
        <div className="EditPopupElement">
          <Title style={{ fontSize: 14 }} level={5}>
            Effects
          </Title>
        </div>
        <div className="EditPopupItems mt-2 mb-4">
          <div className="flex justify-between">
            <div className="flex-1">
              <span>Congratulation Effect</span>
            </div>
            <div className="flex-1">
              <Input.Group compact>
                <Select value={values?.effects} className="w-full" onChange={handleChangeEffects}>
                  <Option value={'None'}>None</Option>
                  <Option value={'Confetti'}>Confetti</Option>
                  <Option value={'Fireworks'}>Fireworks</Option>
                </Select>
              </Input.Group>
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderTimeOnSite = () => {
    return (
      <>
        <div className="EditPopupItems mt-2 mb-4">
          <div className="flex justify-between items-center">
            <div className="flex-1">
              <span>Products</span>
            </div>
            <div className="flex-1 ">
              <Input.Group compact>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  onChange={handleChangeProducts}
                  value={values?.products}
                  notFoundContent=""
                >
                  {FONTSIZE.map((item, index) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Input.Group>
            </div>
          </div>
          <div className="flex justify-between items-center pt-2">
            <div>
              <div className="font-semibold ">OR</div>
            </div>
          </div>
          <div className="flex justify-between items-center pt-2">
            <div className="flex-1">
              <span>Time on site</span>
            </div>
            <div className="flex-1">
              <Input.Group compact>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  onChange={handleTimeOnSiteChange}
                  value={values?.timeOnSite}
                  notFoundContent=""
                >
                  {FONTSIZE.map((item, index) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Input.Group>
            </div>
          </div>
        </div>
      </>
    )
  }
  return (
    <>
      <Card
        size="small"
        title={<Title style={{ padding: '8px 4px' }} level={5}>{`Edit ${title}`}</Title>}
        extra={
          <Close
            className="cursor-pointer"
            onClick={() => toggle()}
            style={{ fontSize: '20px', color: '#00000' }}
          />
        }
        style={{ width: 300, borderBottom: 0 }}
        bodyStyle={{ padding: '16px' }}
      >
        {title == 'Result' && renderEffect()}
        {title == 'Popup' && renderTimeOnSite()}

        <div className="EditPopupElement">
          <Title style={{ fontSize: 14 }} level={5}>
            Background
          </Title>
        </div>
        <div className="EditPopupItems mt-2">
          <div className="flex justify-between items-center">
            <div>
              <span>Fill</span>
            </div>
            <div>
              <ColorPickerFull
                color={values?.color}
                choiceColor={handleOnClickChangeStyle}
                editElementComp={editElementComp}
                values={values}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  )
}

export default EditPopup
