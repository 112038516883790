import React from 'react'

// channels
import WebPush from 'Components/CJChannels/Webpush'
import SMS from 'Components/CJChannels/SMS'
import EmailTabs from 'Components/CJChannels/Email'
import Popup from 'Components/CJChannels/Popup/SpinGame'
import RandomVoucher from 'Components/CJChannels/Popup/RandomVoucher'
import CodeDefault from 'Components/CJChannels/Popup/CodeDefault'
import Kaipass from 'Components/CJChannels/Kaipass'
import { HaraSocialModalSF, HaraSocialModal } from 'Components/CJChannels/HaraSocial'

// others
import ABTesting from './ABTesting'
import Wait from './Wait'

// conditions
import UserAttribute from './UserAttribute'
import Reachability from './Reachability'
import SelectAudience from './SelectAudience'
import { CurrentType } from 'CustomerJourneyModule'

import './index.scss'
import AppPush from 'Components/CJChannels/AppPush'
import Customize from 'Components/CJChannels/Popup/Customize'

interface Props {
  current: CurrentType
  data: any
  isRunning: boolean
  value: any
  isPopoverVisible: boolean
  onAddNewNode: (type: string, value: any) => void
  onUpdateNode: (value: any) => void
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
  setCurrent: React.Dispatch<React.SetStateAction<CurrentType>>
}

const Config: React.FC<Props> = (props) => {
  const { data, onUpdateNode, onAddNewNode, setIsPopoverVisible } = props

  const handleEmailSubmit = async (values: any) => {
    onAddNewNode && (await onAddNewNode('email', values))
    onUpdateNode && (await onUpdateNode(values))
  }

  const renderConfig = (type: string) => {
    const values = props?.current.value
    //console.log('data ', data, 'values', values)
    switch (type) {
      // channels
      case 'web_push':
        return <WebPush {...props} />
      case 'sms':
        return <SMS {...props} />
      case 'email':
        return (
          <EmailTabs
            {...props}
            withModal
            values={values}
            onSubmit={handleEmailSubmit}
            showPreview
          />
        )
      case 'spin_game':
        return <Popup {...props} valuesEmail={values} />
      case 'random_gift':
        return <RandomVoucher {...props} valuesEmail={values} />
      case 'default_code':
        return <CodeDefault {...props} valuesEmail={values} />
      case 'customize':
        return <Customize {...props} setIsPopoverVisible={setIsPopoverVisible} />
      case 'popup':
        return <Customize {...props} setIsPopoverVisible={setIsPopoverVisible} />
      case 'minigame': {
        console.log('---> HERE')
        if (values.popupType == 'spin_game') return <Popup {...props} valuesEmail={values} />
        if (values.popupType == 'default_code')
          return <CodeDefault {...props} valuesEmail={values} />
        if (values.popupType == 'random_gift')
          return <RandomVoucher {...props} valuesEmail={values} />
        if (values.popupType == 'customize')
          return <Customize {...props} setIsPopoverVisible={setIsPopoverVisible} />
      }
      case 'kaipass':
        return <Kaipass {...props} />

      case 'harasocial':
        return <HaraSocialModal {...props} setIsPopoverVisible={setIsPopoverVisible} />

      case 'hrs_store_farming':
        return <HaraSocialModalSF {...props} setIsPopoverVisible={setIsPopoverVisible} />
      case 'tch_app':
        return <AppPush {...props} setIsPopoverVisible={setIsPopoverVisible}></AppPush>

      // others
      case 'wait':
        return <Wait {...props} />
      case 'split':
        return <ABTesting {...props} />

      // conditions
      case 'check_user_attr':
        return <UserAttribute {...props} />
      case 'check_reachability':
        return <Reachability {...props} />
      // case 'entrance':
      //   return <Entrance {...props} />
      case 'check_audience_segment':
        return <SelectAudience {...props} />
      default:
        return 'Not available at the moment'
    }
  }

  return <div className="NodeConfigComponent">{renderConfig(data)}</div>
}

export default Config
