import React, { useState } from 'react'
import { Steps } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { isNextStepDisabled } from '../../helpers'
import { STEPS, STEPS_WITH_ID } from '../../constants'
import { States } from 'Models/campaign'
import { useLocation } from 'react-router-dom'
const { Step } = Steps

export default function ProgressBar() {
  const search = useLocation().search
  const id = new URLSearchParams(search).get('id')

  const { current_step } = useStoreState(
    (state: { campaignModule: States }) => state.campaignModule
  )
  const campaignStates = useStoreState((state: { campaignModule: States }) => state.campaignModule)
  const { setState } = useStoreActions((actions: any) => actions.campaignModule)

  return (
    <div className="ProgressBarComponent">
      <Steps
        size="small"
        className="mb-4"
        current={current_step}
        onChange={(step: number) => setState({ key: 'current_step', value: step })}
      >
        {id
          ? STEPS_WITH_ID.map((step, index) => (
              <Step
                disabled={index > 0 ? isNextStepDisabled(index - 1, campaignStates, id) : false}
                key={index}
                title={step.title}
              />
            ))
          : STEPS.map((step, index) => (
              <Step
                disabled={index > 0 ? isNextStepDisabled(index - 1, campaignStates, id) : false}
                key={index}
                title={step.title}
              />
            ))}
      </Steps>
      <div className="mt-1 text-gray_7">
        {id ? STEPS_WITH_ID[current_step]?.description : STEPS[current_step]?.description}
      </div>
    </div>
  )
}
