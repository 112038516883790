import React from 'react'
import backgroundImg from '../img/Background.png'
import CongratulationResult from './SubComponentPopup/CongratulationResult'
import { useStoreState, useStoreActions } from 'easy-peasy'
import { CloseOutlined } from '@ant-design/icons'

const Result = ({ device, initialValues, chooseElement }) => {
  const { type } = useStoreState((state) => state.codedefault)
  const { result } = initialValues
  const { background } = result
  return (
    <div
      style={{
        width: `${device == 'desktop' ? '1012px' : '320px'}`,
        height: `${device == 'mobile' ? '552px' : '568px'}`,
      }}
      onClick={() => {
        chooseElement({ step: 'result', element: 'Background', type: 'background' })
      }}
      className={`WheelContent flex bottom-0 overflow-y-scroll cursor-pointer`}
    >
      <div
        style={{
          width: `${device == 'desktop' ? '1012px' : '320px'}`,
          height: `${device == 'mobile' ? '552px' : '568px'}`,
          backgroundImage: `url(${background?.url && background?.url})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: background?.backgroundSize,
          backgroundColor: `${background?.color && background?.color}`,
          opacity: background?.opacity,
        }}
        className={`absolute `}
      ></div>
      <div className="m-auto relative">
        <CloseOutlined
          className="flex justify-end px-4 pt-4"
          style={{
            position: 'relative',
            right: `${device == 'mobile' ? '-28px' : ''}`,
            paddingBottom: `${device == 'mobile' ? '160px' : ''}`,
          }}
        />
        <CongratulationResult
          device={device}
          initialValues={initialValues}
          chooseElement={chooseElement}
        />
      </div>
    </div>
  )
}

export default Result
