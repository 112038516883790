import React from 'react'
import { FONTSIZE, FONT_FAMILY } from '../../constants'
import {
  Modal,
  Card,
  Button,
  Tabs,
  Input,
  List,
  Typography,
  Layout,
  Menu,
  Steps,
  Select,
} from 'antd'

import {
  UnderlineOutlined,
  CloseOutlined,
  BoldOutlined,
  ItalicOutlined,
  AlignLeftOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
} from '@ant-design/icons'
import ColorPickerComponent from './ColorPickerComponent'
import ColorPickerFull from 'Components/CJChannels/Popup/SpinGame/ColorPickerPopup/Modal/ColorPickerFull'

const { Option } = Select
const StylePopup = ({ getElement, editElement }) => {
  const style = getElement?.style

  const handleOnClickChangeStyle = (props) => {
    if (!props?.opacity) {
      const upDateValue = {
        ...getElement,
        style: {
          ...style,
          [props.key]: props.value,
        },
      }

      editElement(upDateValue)
    } else {
      const upDateValue = {
        ...getElement,
        style: {
          ...style,
          [props.key]: props.value,
          opacity: props.opacity,
        },
      }
      editElement(upDateValue)
    }
  }

  function handleChangeFontSize(value) {
    const upDateValue = {
      ...getElement,
      style: {
        ...style,
        fontSize: parseInt(value),
      },
    }

    editElement(upDateValue)
  }

  const handleChangeFontFamily = (value) => {
    const upDateValue = {
      ...getElement,
      style: {
        ...style,
        fontFamily: value,
      },
    }

    editElement(upDateValue)
  }

  const renderFontStyle = (fontStyle) => {
    return (
      <>
        <div className="mr-2">
          <button
            style={{ height: 32, width: 32, background: `${fontStyle === 'bold' ? '#ccc' : ''}` }}
            className="btn-Bold border rounded"
            onClick={() => handleOnClickChangeStyle({ key: 'fontStyle', value: 'bold' })}
          >
            <BoldOutlined />
          </button>
        </div>
        <div className="mr-2">
          <button
            style={{ height: 32, width: 32, background: `${fontStyle === 'italic' ? '#ccc' : ''}` }}
            className="btn-Bold border rounded"
            onClick={() => handleOnClickChangeStyle({ key: 'fontStyle', value: 'italic' })}
          >
            <ItalicOutlined />
          </button>
        </div>
        <div>
          <button
            style={{
              height: 32,
              width: 32,
              background: `${fontStyle === 'underline' ? '#ccc' : ''}`,
            }}
            className="btn-Bold border rounded"
            onClick={() => handleOnClickChangeStyle({ key: 'fontStyle', value: 'underline' })}
          >
            <UnderlineOutlined />
          </button>
        </div>
      </>
    )
  }

  const renderTextAlign = (textAlign) => {
    return (
      <>
        <div className="mr-2">
          <button
            style={{ height: 32, width: 32, background: `${textAlign === 'left' ? '#ccc' : ''}` }}
            className="btn-Bold border rounded"
            onClick={() => handleOnClickChangeStyle({ key: 'textAlign', value: 'left' })}
          >
            <AlignLeftOutlined />
          </button>
        </div>
        <div className="mr-2">
          <button
            style={{ height: 32, width: 32, background: `${textAlign === 'center' ? '#ccc' : ''}` }}
            className="btn-Bold border rounded"
            onClick={() => handleOnClickChangeStyle({ key: 'textAlign', value: 'center' })}
          >
            <AlignCenterOutlined />
          </button>
        </div>
        <div>
          <button
            style={{ height: 32, width: 32, background: `${textAlign === 'right' ? '#ccc' : ''}` }}
            className="btn-Bold border rounded"
            onClick={() => handleOnClickChangeStyle({ key: 'textAlign', value: 'right' })}
          >
            <AlignRightOutlined />
          </button>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex-1">
          <span>Font-family</span>
        </div>
        <div className="flex-1">
          <Input.Group compact>
            <Select value={style?.fontFamily} className="w-full" onChange={handleChangeFontFamily}>
              {FONT_FAMILY.map((fontFamily) => (
                <Option value={fontFamily}>{fontFamily}</Option>
              ))}
            </Select>
          </Input.Group>
        </div>
      </div>
      <div className="flex justify-between items-center mt-6">
        <div>
          <span>Color</span>
        </div>
        <div>
          <ColorPickerFull color={style?.color} choiceColor={handleOnClickChangeStyle} />
        </div>
      </div>
      <div className="flex justify-between items-center mt-6">
        <div>
          <span>Font-Size</span>
        </div>
        <div>
          <Input.Group compact>
            <Select
              showSearch
              style={{ width: 64 }}
              onChange={handleChangeFontSize}
              value={style?.fontSize}
              notFoundContent=""
            >
              {FONTSIZE.map((item, index) => (
                <Option value={item}>{item}</Option>
              ))}
            </Select>
          </Input.Group>
        </div>
      </div>
      <div className="fontStyleText flex justify-between items-center mt-6">
        <div>
          <span>Font-style</span>
        </div>
        <div className="flex">{renderFontStyle(style?.fontStyle)}</div>
      </div>
      <div className="textAlignText flex justify-between items-center mt-6">
        <div>
          <span>Text align</span>
        </div>
        <div className="flex">{renderTextAlign(style?.textAlign)}</div>
      </div>
    </>
  )
}

export default StylePopup
