import React from 'react'
import reactCSS from 'reactcss'
import * as color from './color'
import '../../index.scss'

import { EditableInput } from 'react-color/lib/components/common'
import { CustomPicker } from './CustomPicker'

export class CustomFields extends React.Component {
  handleChange = (data, e) => {
    console.log(data.Opacity / 100)
    console.log('event: ', e)
    console.log('data: ', data)
    if (data.hex) {
      color.isValidHex(data.hex) &&
        this.props.onChange(
          {
            hex: data.hex,
            source: 'hex',
          },
          e
        )
    } else if (data.Opacity) {
      if (data.Opacity < 0) {
        data.Opacity = 0
      } else if (data.Opacity > 100) {
        data.Opacity = 1
      }
      this.props.onChange(
        {
          h: this.props.hsl.h,
          s: this.props.hsl.s,
          l: this.props.hsl.l,
          a: data.Opacity / 100,
          source: 'rgb',
        },
        e
      )
    }
  }

  render() {
    const styles = reactCSS(
      {
        default: {
          fields: {
            flex: '1',
            display: 'flex',
            marginLeft: '10px',
          },
          input: {
            fontWeight: 'normal',
            fontSize: '16px',
            color: '#000',
            borderRadius: '2px',
            border: 'none',
            boxShadow: 'inset 0 0 0 1px #dadada',
            height: '40px',
            width: '232px',
            textAlign: 'left',
            fontFamily: 'Inter',
            padding: '8px',
          },
          ainput: {
            fontWeight: 'normal',
            fontSize: '16px',
            color: '#000',
            borderRadius: '2px',
            border: 'none',
            boxShadow: 'inset 0 0 0 1px #dadada',
            height: '40px',
            width: '80px',
            textAlign: 'center',
            fontFamily: 'Inter',
          },
          label: {
            fontSize: '12px',
            lineHeight: '11px',
            textAlign: 'center',
            display: 'block',
            marginTop: '12px',
          },
        },
      },
      this.props,
      this.state
    )

    return (
      <div style={styles.wrap} className="flexbox-fix">
        <div style={styles.fields} className="flexbox-fix">
          <div className="hexinputstyle" style={styles.field}>
            <div
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '20px',
                paddingLeft: '4px',
                paddingBottom: '8px',
                fontSize: '12px',
              }}
            >
              Hex
            </div>
            <EditableInput
              style={{ input: styles.input, label: styles.label }}
              value={this.props.hex}
              onChange={this.props.changeHexColor}
            />
          </div>
          <div class="alphastyle">
            <EditableInput
              class="test"
              style={{ input: styles.ainput, label: styles.label }}
              label="Opacity"
              value={this.props.rgb.a}
              arrowOffset={1}
              onChange={this.handleChange}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default CustomFields
