import React from 'react'
import backgroundImg from '../img/Background.png'
import CongratulationResult from './SubComponentPopup/CongratulationResult'
import { CloseOutlined } from '@ant-design/icons'
import { useStoreActions, useStoreState } from 'easy-peasy'

const Result = ({ device, initialValues, chooseElement }) => {
  const { type } = useStoreState((state) => state.randomvoucher)
  const { result } = initialValues
  const { background } = result
  return (
    <div className="flex h-full">
      <div
        style={{
          width: '300px',
          height: `${device == 'mobile' ? '97%' : '396px'}`,
          backgroundColor: '#F0F5FF',
        }}
        onClick={() => {
          chooseElement({ step: 'result', element: 'Background', type: 'background' })
        }}
        className={`WheelContent m-auto flex overflow-y-scroll cursor-pointer`}
      >
        <div
          style={{
            width: '300px',
            height: `${device == 'mobile' ? 'auto' : '396px'}`,
            backgroundImage: `url(${background?.url && background?.url})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: background?.backgroundSize,
            backgroundColor: `${background?.color && background?.color}`,
            opacity: background?.opacity,
          }}
          className={`absolute`}
        ></div>
        <div
          className="WheelContent m-auto relative  "
          style={{ width: 300, height: `${device == 'mobile' ? '526px' : '396px'}` }}
        >
          <CloseOutlined
            className="flex justify-end px-4 pt-4"
            style={{ position: 'relative', marginBottom: `${device == 'mobile' ? '100px' : ''}` }}
          />
          <CongratulationResult initialValues={initialValues} chooseElement={chooseElement} />
        </div>
      </div>
    </div>
  )
}

export default Result
