import React from 'react'
import gift from '../../img/gift.png'

import { useStoreState, useStoreActions } from 'easy-peasy'

const CongratulationResult = ({ initialValues, chooseElement, device }) => {
  const { type } = useStoreState((state) => state.codedefault)
  const { result } = initialValues
  const { text, text_1, button } = result

  const fontStyleClass = (fontStyle) => {
    if (fontStyle == 'bold') {
      return 'font-semibold'
    }
    if (fontStyle == 'underline') {
      return 'underline'
    }
    if (fontStyle == 'italic') {
      return 'italic'
    }
  }

  const renderTitle = () => {
    const { content, style } = text
    const { fontStyle } = style

    return (
      <div
        className={`cursor-pointer ${fontStyleClass(fontStyle)} ${
          type == 'text' && `border-active-element`
        }`}
        onClick={(e) => {
          e.stopPropagation()
          chooseElement({ step: 'result', element: 'Text', type: 'text' })
        }}
        style={{ ...style, fontStyle: fontStyle == 'italic' ? 'italic' : '', width: 262 }}
      >
        <span> {content == '' ? 'Title' : content}</span>
      </div>
    )
  }

  const renderTitle1 = () => {
    const { content, style } = text_1
    const { fontStyle } = style

    return (
      <div
        className={`cursor-pointer ${fontStyleClass(fontStyle)} ${
          type == 'text_1' && `border-active-element`
        }`}
        onClick={(e) => {
          e.stopPropagation()
          chooseElement({ step: 'result', element: 'Text', type: 'text_1' })
        }}
        style={{ ...style, fontStyle: fontStyle == 'italic' ? 'italic' : '', width: 262 }}
      >
        <span> {content == '' ? 'Text' : content}</span>
      </div>
    )
  }

  const renderButton = () => {
    const { content, style, buttonStyle } = button
    const { hover } = buttonStyle

    const { fontStyle, backgroundColor } = style

    const onHandleOnMouseOver = (e) => {
      e.target.style.backgroundColor = hover
    }
    const onHandleOnMouseOut = (e) => {
      e.target.style.backgroundColor = backgroundColor
    }
    return (
      <div
        className={`cursor-pointer `}
        onClick={(e) => {
          e.stopPropagation()
          chooseElement({ step: 'result', element: 'Button', type: 'button' })
        }}
      >
        <button
          className={`truncate px-2 ${fontStyleClass(fontStyle)} ${
            type == 'button' && `border-active-element`
          }`}
          style={{
            ...style,
            width: 252,
            height: 40,
            fontStyle: fontStyle == 'italic' ? 'italic' : '',
            marginBottom: 48,
          }}
          onMouseOver={onHandleOnMouseOver}
          onMouseOut={onHandleOnMouseOut}
        >
          {content}
        </button>
      </div>
    )
  }

  return (
    <div>
      <div
        className="text-center CongratulationResultPopup"
        style={{ height: `${device == 'mobile' ? '552px' : '300px'}` }}
      >
        <div className="mb-4">{renderTitle()}</div>
        <div className="mb-4">{renderTitle1()}</div>
        <div className="mb-4">{renderButton()}</div>
      </div>
    </div>
  )
}

export default CongratulationResult
