import React from 'react'
import { CloseOutlined } from '@ant-design/icons'
import ElementPopup from './ElementPopup'
import TitlePopup from './TitlePopup'
import InputPopup from './InputPopup'
import ButtonPopup from './ButtonPopup'
import logo from '../../img/logo.png'
import { useStoreActions, useStoreState } from 'easy-peasy'

const RightEdit = ({ changeType }) => {
  const { device, content, text } = useStoreState((state) => state.popup)
  const { button } = useStoreState((state) => state.randomvoucher)
  const { input } = useStoreState((state) => state.randomvoucher)

  const renderImg = (content) => {
    return !content ? logo : content
  }

  return (
    <>
      <CloseOutlined className="flex justify-end px-4 pt-4" />
      <div className="WheelContent__right__content  p-2">
        <div className={`${device == 'mobile' && 'hidden'}`}>
          <ElementPopup img={renderImg(content)} changeType={changeType} />
        </div>
        <div className="WheelContent__title mt-4">
          <TitlePopup textContent={text} changeType={changeType} />
        </div>
        <div className="WheelContent__infor mt-2">
          <p>
            Nhiều phần quà may mắn đang chờ đón bạn mùa siêu sale 11.11 năm nay. Bạn có 1 lượt quay,
            đừng bỏ lỡ nhé!
          </p>
          <div className="WheelContent__Email">
            {/* <InputPopup textContent={input} changeType={changeType} /> */}
            <div className="mt-2">
              <ButtonPopup textContent={button} changeType={changeType} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RightEdit
