import React, { useState, useEffect } from 'react'
import EmailCardList from './EmailCardList'
import { Tabs, Modal, Popconfirm } from 'antd'
import Header from 'Components/CJHeader'
import { LeftOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'

import { EmailCardProps } from './EmailCardList/EmailCard'
import EmailEditContentForm from './EmailEditContentForm'
import isEmpty from 'lodash/isEmpty'

const TabPane = Tabs.TabPane

// Todo: Define type of MailList
const EmailList = (props: any) => {
  const {
    withModal,
    values,
    isCampaign = false,
    setValue,
    clearValue,
    btnSubmit,
    onSubmit: handleEmailSubmit,
    isPopoverVisible,
    setIsPopoverVisible,
    breadcrumbVisble = true,
    emailTypeChange,
    setPreviewData, // For CJ
    setState, // For CJ
    isRunning, // For CJ
  } = props

  const [visible, setVisible] = useState(true)
  const [seletedEmail, setSeletedEmail] = useState<EmailCardProps | null>(null)
  const [emailType, setEmailType] = useState(() => {
    if (values?.template_id && values.template_id !== 'string') return 'sendgrid'
    return 'kariba'
  })

  useEffect(() => {
    if (withModal) {
      if (isPopoverVisible) setVisible(true)
    }
  }, [isPopoverVisible])

  const onSubmit = async (values: any) => {
    handleEmailSubmit(values)
    if (withModal) setVisible(false)
  }

  const onCancel = () => {
    setSeletedEmail(null)
    setVisible(false)
    setIsPopoverVisible(false)
  }

  const renderBackButton = () => {
    if (!isCampaign) return
    return (
      <Popconfirm
        placement="topLeft"
        title={'Back will remove current data!'}
        onConfirm={() => {
          clearValue()
          setSeletedEmail(null)
        }}
        okText="Yes"
        cancelText="No"
      >
        <div className="back-btn mb-4 cursor-pointer" style={{ width: 'fit-content' }}>
          <LeftOutlined className="mr-2" />
          <span>Templates</span>
        </div>
      </Popconfirm>
    )
  }

  const renderContent = () => {
    if (seletedEmail || !isEmpty(values))
      return (
        <div>
          {renderBackButton()}
          <EmailEditContentForm
            isRunning={isRunning}
            isCampaign={isCampaign}
            emailType={emailType}
            onSubmit={onSubmit}
            seletedEmail={seletedEmail}
            btnSubmit={btnSubmit}
            setValue={setValue}
            breadcrumbVisble={breadcrumbVisble}
            values={values}
            onBack={() => {
              setPreviewData({})
              setSeletedEmail(null)
            }}
            showPreview={emailType === 'kariba'}
          />
        </div>
      )
    return (
      <>
        <Tabs
          defaultActiveKey={emailType}
          onChange={(type) => {
            emailTypeChange && emailTypeChange(type)
            setEmailType(type)
          }}
        >
          <TabPane key="kariba" tabKey="kariba" tab="Kariba">
            <EmailCardList
              emailType={'kariba'}
              onEmailSelect={(email) => {
                setSeletedEmail(email)
              }}
            />
          </TabPane>
          <TabPane key="sendgrid" tabKey="sendgrid" tab="SendGrid">
            <EmailCardList
              emailType={'sendgrid'}
              onEmailSelect={(email) => {
                // For Campaign
                if (isCampaign) setValue('template_id', email?.id)
                else setPreviewData({ ...values, template_id: email?.id }) // For CJ
                setSeletedEmail(email)
              }}
            />
          </TabPane>
        </Tabs>
      </>
    )
  }

  const renderWithModal = () => {
    return (
      <Modal
        onOk={() => {}}
        onCancel={onCancel}
        visible={visible}
        maskClosable
        title={<Header {...props} />}
        width={960}
        closable={false}
        footer={true}
        className="ChannelsModal"
      >
        {renderContent()}
      </Modal>
    )
  }

  return (
    <div className="EmailListComponent" style={{ pointerEvents: isRunning ? 'none' : 'auto' }}>
      {withModal ? renderWithModal() : renderContent()}
    </div>
  )
}

EmailList.defaultProps = {
  showPreview: false,
  withModal: true,
  values: {},
}

export default connect(
  (state: any) => ({
    data: state.campaign.creativeContents.previewData,
  }),
  (dispatch: any) => ({
    setState: dispatch.campaign.creativeContents.setState,
    resetState: dispatch.campaign.creativeContents.resetState,
    setPreviewData: dispatch.campaign.creativeContents.setPreviewData,
  })
)(EmailList)
