import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { DashboardRoute } from './Layouts/Dashboard'
import { AuthenticationRoute } from './Layouts/Authentication'
import { HarasocialRoute } from './Layouts/Harasocial'

// Authentication Pages
import Login from 'Pages/Login'
import ForgotPassword from 'Pages/ForgotPassword'
import ResetPassword from 'Pages/ResetPassword'
import Register from 'Pages/Register' // ! Tmp disabled
import ActivateAccount from 'Pages/ActivateAccount'
import Policies from 'Pages/Policies'
import LoginIAM from 'Pages/IAM'

// Main Pages
import { CampaignList, CampaignNew, CampaignDetail } from 'Pages/Campaign'
import { CJList, CJAnalytics, CJRoute, CJNew, CJDetail } from 'Pages/CustomerJourney'
import { CreativeContentForm, CreativeContentList } from 'Pages/CreativeContent'
import Agents from 'Pages/Agents'

// Audience Pages
import { AudienceNew, AudienceList, AudienceDetails, Audience360Details } from 'Pages/Audiences'

// Customer Pages
import { CustomerAnalytics, CustomerList } from 'Pages/Customers'

// Customer Journey
import CustomerJourneyDesign from 'Containers/CustomerJourneyDesign'

// Agent
import SettingAccount from 'Pages/SettingAccount'
import CustomerPortrait from 'Pages/Harasiocial/CustomerPortrait'
import LeaderBoard from 'Pages/Harasiocial/LeaderBoard'

//  Under Construction Page
import UnderConstruction from 'Pages/UnderConstruction'
import NotFound from 'Pages/NotFound'

import SocialSelling from 'Pages/SocialSelling'
// HARAVAN pages
import HaravanLoginSuccess from 'Pages/HaravanAuth/LoginSuccess'
import HaravanLoginFail from 'Pages/HaravanAuth/LoginFail'
import StoreFarming from 'Pages/StoreFarming'
import HarasocialInteraction from 'Pages/Harasiocial/HistoryInteraction'
import ForbidenPage from 'Pages/Forbiden'
import Test from 'Pages/TestPivot'
import TestChart from 'Pages/TestChart'

// Analytics pages
import {
  MarketingCampaignsAnalytics,
  Campaign360,
  CustomersAnalytics,
  Segment360,
  Customer360,
  Product360,
  ProductsAnalytics,
} from 'Pages/Analytics'
import CustomerListing from 'Pages/Analytics/Customers/ViewCustomerListing'
import LoginFail from 'Pages/IAM/LoginFail'

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <DashboardRoute exact path="/TestPivot" component={Test} />
      <DashboardRoute exact path="/TestChart" component={TestChart} />
      {/* Authentication Routes */}
      <AuthenticationRoute exact path="/login" component={Login} />
      <AuthenticationRoute exact path="/forgot-password" component={ForgotPassword} />
      <AuthenticationRoute exact path="/reset-password" component={ResetPassword} />
      {/* <AuthenticationRoute exact path="/register" component={Register} /> */}
      <AuthenticationRoute exact path="/activate-account" component={ActivateAccount} />

      {/* Dashboard Routes */}
      <DashboardRoute exact path="/campaigns" component={CampaignList} />
      <DashboardRoute exact path="/campaigns/new" component={CampaignNew} />
      <DashboardRoute exact path="/campaigns/:id" component={CampaignDetail} />
      <DashboardRoute exact path="/settings-account" component={SettingAccount}></DashboardRoute>

      {/* Social selling */}
      <DashboardRoute exact path="/social-selling" component={SocialSelling} />

      {/* Store Farming  */}
      <DashboardRoute exact path="/store-farming" component={StoreFarming} />

      {/* Creative Content */}
      <DashboardRoute exact path="/creative-contents" component={CreativeContentList} />
      <DashboardRoute exact path="/creative-content/new" component={CreativeContentForm} />

      {/* Audience Routes */}
      <DashboardRoute exact path="/agents" component={Agents} />
      <DashboardRoute exact path="/customers/segments" component={AudienceList} />
      <DashboardRoute exact path="/customers/segments/new" component={AudienceNew} />
      <DashboardRoute exact path="/customers/segments/:id" component={AudienceDetails} />
      {/* <DashboardRoute
        exact
        path="/customers/segments/:id/analytics"
        component={Audience360Details}
      /> */}

      {/* <DashboardRoute exact path="/customers/360" component={CustomerList} /> */}
      {/* <DashboardRoute exact path="/customers/:id/customer360" component={CustomerAnalytics} /> */}

      {/* Haravan Routes */}
      <HarasocialRoute
        exact
        path="/harasocial/leader-board"
        Component={LeaderBoard}
      ></HarasocialRoute>
      <HarasocialRoute
        exact
        path="/harasocial/customer-portrait"
        Component={CustomerPortrait}
      ></HarasocialRoute>
      <HarasocialRoute
        exact
        path="/harasocial/interactions"
        Component={HarasocialInteraction}
      ></HarasocialRoute>
      <Route exact path="/login/haravan/success">
        <HaravanLoginSuccess></HaravanLoginSuccess>
      </Route>
      <Route exact path="/login/haravan/fail">
        <HaravanLoginFail></HaravanLoginFail>
      </Route>
      <Route exact path="/login/sso/success">
        <LoginIAM></LoginIAM>
      </Route>
      <Route exact path="/login/sso/fail">
        <LoginFail></LoginFail>
      </Route>

      {/* CJ Routes */}
      <DashboardRoute exact path="/customer-journeys" component={CJList} />
      <DashboardRoute exact path="/customer-journeys/:id/analytics" component={CJAnalytics} />
      <CJRoute exact path="/customer-journeys/new" component={CJNew} />
      <CJRoute exact path="/customer-journeys/:id" component={CJDetail} />
      <Route exact path="/customer-journey-design" Component={CustomerJourneyDesign}></Route>

      {/* Analytics Routes */}
      <DashboardRoute exact path="/" component={CustomersAnalytics} />
      <DashboardRoute
        exact
        path="/analytics/marketing-campaigns"
        component={MarketingCampaignsAnalytics}
      />
      <DashboardRoute exact path="/analytics/campaign360/:id" component={Campaign360} />
      <DashboardRoute exact path="/analytics/customers" component={CustomersAnalytics} />
      <DashboardRoute exact path="/analytics/customer360/:id" component={Customer360} />
      <DashboardRoute exact path="/analytics/segment360/:id" component={Segment360} />
      <DashboardRoute exact path="/analytics/customer-list/:id" component={CustomerListing} />
      <DashboardRoute exact path="/analytics/products" component={ProductsAnalytics} />
      <DashboardRoute exact path="/analytics/product360/:id" component={Product360} />

      {/* Policies Routes */}
      <Route path="/policies" component={Policies} />

      {/* 404 Routes */}
      <DashboardRoute exact path="/forbidden" component={ForbidenPage}></DashboardRoute>
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
)

export default Routes
