import React from 'react'
import { CloseOutlined } from '@ant-design/icons'
import ElementPopup from './ElementPopup'
import TitlePopup from './TitlePopup'
import InputPopup from './InputPopup'
import ButtonPopup from './ButtonPopup'
import gift from '../../img/gift.png'
import PercentagePopup from './PercentagePopup'
import { useStoreActions, useStoreState } from 'easy-peasy'

function CenterEdit({ changeType, initialValues, device }) {
  const { type } = useStoreState((state) => state.codedefault)
  const { mainPopup } = initialValues
  const { text, default_coupon, text_2, text_3, input, button } = mainPopup
  const fontStyleClass = (fontStyle) => {
    if (fontStyle == 'bold') {
      return 'font-semibold'
    }
    if (fontStyle == 'underline') {
      return 'underline'
    }
    if (fontStyle == 'italic') {
      return 'italic'
    }
  }
  return (
    <>
      <CloseOutlined
        className="flex justify-end px-4 pt-4"
        style={{ marginBottom: `${device == 'mobile' ? '140px' : '10px'}` }}
      />
      <div className="WheelContent__title flex justify-center mt-2">
        <TitlePopup textContent={text} changeType={changeType} />
      </div>
      <div className="WheelContent__title flex justify-center mt-2">
        <PercentagePopup textContent={default_coupon} changeType={changeType} />
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation()
          changeType({ step: 'mainPopup', element: 'Text', type: 'text_2' })
        }}
        className={` cursor-pointer flex justify-center ${fontStyleClass(
          text_2?.style?.fontStyle
        )}`}
      >
        <div
          style={{
            ...text_2.style,
            width: 262,
            fontStyle: text_2?.style?.fontStyle == 'italic' ? 'italic' : '',
          }}
          className={`${type == 'text_2' && 'border-active-element'}`}
        >
          {text_2.content == '' ? 'Text' : text_2.content}
        </div>
      </div>
      <div className="WheelContent__infor flex justify-center mt-2 text-center">
        <div className="WheelContent__Email  ">
          <InputPopup textContent={input} textEmailValidate={text_3} changeType={changeType} />
          <div className="mt-2 mb-4">
            <ButtonPopup textContent={button} changeType={changeType} />
          </div>
        </div>
      </div>
    </>
  )
}

export default CenterEdit
