import { isLength } from 'lodash'
import axios from 'Utils/axios'

// todo: Move to Types/Analytics
export enum CampaignPivotTypes {
  ByTime = 'campaign_performance_by_time',
  Session = 'session',
  Channel = 'channel',
  ProductInsight = 'product_insight',
}

export enum CustomerPivotTypes {
  ProductInsight = 'product_insight',
  ChannelInsight = 'channel_insight',
}

export enum ProductPivotTypes {
  PivotTable = 'pivot_table',
  ProductSales = 'product_sale',
  ProductChannelInsight = 'channel_insight_by_frequency',
  ProductSoldInSaleCampaign = 'product_sold_in_sale_campaign',
  ProductSoldInSaleChannel = 'product_sold_in_sale_channel',
  ProductCustomizeSection = 'customize_section',
  ProductBestSellers = 'best_sellers',
  ProductTopGrossProfit = 'top_gross_profit',
  ProductInventory = 'inventory',
}

export const getBQColumns = async (
  type: string,
  currentPage?: string | undefined,
  isRanking?: boolean,
  barcode?: string
) => {
  try {
    let url
    switch (currentPage) {
      case 'products':
        url = 'products/overview/analytics/metrics'
        break
      case undefined:
      case 'customers':
        url = 'customer-profiles/analytics/overview/pivot-table/metric'
        break
      case 'campaign360':
      case 'marketing-campaigns':
        url = '/campaigns/analytics/metric'
        break
      case 'product360':
        url = 'products/product360/analytics/metrics'
        break
      default:
    }
    const resp = await axios.get(url, {
      params: { analytic_type: type, barcode: barcode },
    })
    return resp.data || []
  } catch (error) {
    return Promise.reject('Something went wrong!')
  }
}

export const getPivotTableContent = async ({
  currentPage,
  type,
  metrics,
  dimensions,
  columns,
  dateRange,
  objectId,
  segmentDimensions,
  isCustomer = false, // Customer || Campaign
  cancelTokenSource,
  isRanking,
  barcode,
}) => {
  let url
  if (!isRanking) {
    switch (currentPage) {
      case 'products':
        url = '/products/overview/analytics/pivot-tables'
        break
      case undefined:
      case 'customers':
        url = '/customer-profiles/analytics/overview/pivot-table'
        break
      case 'product360':
        url = '/products/product360/analytics/pivot-table'
        break
      case 'campaign360':
      case 'marketing-campaigns':
        url = '/campaigns/analytics/overview/pivot-table'
        break
      default:
    }
  } else {
    url = '/products/overview/analytics/ranking-tables'
  }
  if (objectId && !isCustomer) url = `/campaigns/${objectId}/analytics/pivot-table`
  const resp = await axios.post(
    url,
    {
      barcode,
      metrics,
      dimensions,
      columns,
      from_date: dateRange[0],
      to_date: dateRange[1],
      analytic_type: type,
      dimension_segments: segmentDimensions,
      row_limit: 100,
    },
    { cancelToken: cancelTokenSource.token }
  )

  if (!resp.data) {
    throw resp
  }
  return resp.data
}
