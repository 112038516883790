import React, { useMemo } from 'react'
import { LTV, Membership } from 'Customer360Module'
import './index.scss'
type MemberShipBadgeProps = {
  ltv?: LTV | string
  membership: Membership | string
  shape?: 'plate' | 'rounded'
  className?: ''
}
const MemberShipBadge = ({ ltv, shape, membership, className = '' }: MemberShipBadgeProps) => {
  const getMembershipClass = () => {
    switch (membership?.replace('Khách hàng ', '')) {
      case 'kim cương':
        return 'diamond'
      case 'bạch kim':
        return 'platium'
      case 'vàng':
        return 'gold'
      case 'đồng':
        return 'bronze'
      case 'bạc':
        return 'silver'
      case 'thành viên':
        return 'default'
      default:
        return 'default'
    }
  }
  if (membership == null) return <></>
  return (
    <div
      className={`MemberShipBadgeComponent shape-${shape} member-${getMembershipClass()} font-semibold px-2 rounded flex items-center ${className}`}
    >
      <span className="capitalize">{membership.replace('Khách hàng ', '')}</span>
      {ltv && <span>&nbsp;- LTV {ltv}</span>}
    </div>
  )
}

MemberShipBadge.defaultProps = {
  shape: 'plate',
}

export default MemberShipBadge
