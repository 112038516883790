import React from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'
import backgroundImg from '../img/Background.png'
import Wheel from '../img/Frame20.png'
import WheelPopup from './SubComponentPopup/WheelPopup'

import RightEdit from './SubComponentPopup/RightEdit'
const MainPopupMobile = ({ children }) => {
  const changeType = useStoreActions((actions: any) => actions.popup.changeElement)

  return (
    <div
      style={{ background: '#E5E5E5' }}
      className="MainPopupStepsContent h-full overflow-y-scroll"
    >
      <div className="MainPopupStep ">
        <div className="MainPopupStepContent flex ">
          <div
            style={{ width: 320, backgroundColor: 'white' }}
            className="MainPopupEdit m-auto p-2 "
          >
            <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainPopupMobile
