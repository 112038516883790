import React from 'react'
import gift from '../../img/gift.png'
import { useStoreActions, useStoreState } from 'easy-peasy'

const CongratulationResult = ({ initialValues, chooseElement }) => {
  const { type } = useStoreState((state) => state.popup)
  const { result } = initialValues
  const { text, button, imgGift, text_2, code } = result

  const fontStyleClass = (fontStyle) => {
    if (fontStyle == 'bold') {
      return 'font-semibold'
    }
    if (fontStyle == 'underline') {
      return 'underline'
    }
    if (fontStyle == 'italic') {
      return 'italic'
    }
  }

  const renderElement = () => {
    const renderImg = (content) => {
      return !content ? gift : content
    }
    return (
      <div
        className={`flex cursor-pointer justify-center`}
        onClick={(e) => {
          e.stopPropagation()
          chooseElement({ step: 'result', element: 'Image', type: 'imgGift' })
        }}
      >
        <div
          className={`m-auto flex justify-center ${type == 'imgGift' && `border-active-element`}`}
          style={{ width: '100%' }}
        >
          <img
            style={{ width: 92, height: 96, objectFit: 'cover' }}
            src={renderImg(imgGift.url)}
            alt=""
          />
        </div>
      </div>
    )
  }

  const renderCode = () => {
    const { content, style } = code
    const { fontStyle } = style

    return (
      <div
        className={`w-full cursor-pointer ${fontStyleClass(fontStyle)} ${
          type == 'code' && `border-active-element`
        }`}
        onClick={(e) => {
          e.stopPropagation()
          chooseElement({ step: 'result', element: 'Text', type: 'code' })
        }}
        style={{
          ...style,
          fontStyle: fontStyle == 'italic' ? 'italic' : '',
          width: 696,
          lineHeight: style.fontSize + 'px',
        }}
      >
        {content == '' ? 'Title' : content}
      </div>
    )
  }

  const renderTitle = () => {
    const { content, style } = text
    const { fontStyle } = style

    return (
      <div
        className={`w-full cursor-pointer ${fontStyleClass(fontStyle)} mt-4 ${
          type == 'text' && `border-active-element`
        }`}
        onClick={(e) => {
          e.stopPropagation()
          chooseElement({ step: 'result', element: 'Text', type: 'text' })
        }}
        style={{
          ...style,
          fontStyle: fontStyle == 'italic' ? 'italic' : '',
          width: 696,
          lineHeight: style.fontSize + 'px',
        }}
      >
        {content == '' ? 'Title' : content}
      </div>
    )
  }

  const renderButton = () => {
    const { content, style, buttonStyle } = button
    const { hover } = buttonStyle

    const { fontStyle, backgroundColor } = style

    const onHandleOnMouseOver = (e) => {
      e.target.style.backgroundColor = hover
    }
    const onHandleOnMouseOut = (e) => {
      e.target.style.backgroundColor = backgroundColor
    }
    return (
      <div
        onClick={(e) => {
          e.stopPropagation()
          chooseElement({ step: 'result', element: 'Button', type: 'button' })
        }}
      >
        <button
          className={`cursor-pointer truncate px-2 ${fontStyleClass(fontStyle)} ${
            type == 'button' && `border-active-element`
          }`}
          style={{ ...style, fontStyle: fontStyle == 'italic' ? 'italic' : '' }}
          onMouseOver={onHandleOnMouseOver}
          onMouseOut={onHandleOnMouseOut}
        >
          {content}
        </button>
      </div>
    )
  }

  const renderTitle2 = () => {
    const { content, style } = text_2
    const { fontStyle } = style
    return (
      <div
        onClick={(e) => {
          e.stopPropagation()
          chooseElement({ step: 'result', element: 'Text', type: 'text_2' })
        }}
        className={`cursor-pointer ${fontStyleClass(fontStyle)} mb-4 mt-4 ${
          type == 'text_2' && `border-active-element`
        }`}
        style={{ ...style, fontStyle: fontStyle == 'italic' ? 'italic' : '' }}
      >
        {content == '' ? 'Text' : content}
      </div>
    )
  }

  return (
    <div className="text-center CongratulationResultPopup">
      <div>{renderElement()}</div>
      <div>{renderTitle()}</div>
      <div>{renderCode()}</div>
      <div>{renderTitle2()}</div>
      <div>{renderButton()}</div>
    </div>
  )
}

export default CongratulationResult
