import React, { useState, useEffect, useMemo } from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'

import backgroundImg from './img/Background.png'
import backgroundImgMobile from './img/Frame20.png'
import giftimg from './img/giftimg.png'
import logo from './img/logo.png'
import arrow from './img/arrowcurve.png'
import { Modal, Card, Button, Tabs, Input, List, Typography, Layout, Menu, Steps } from 'antd'

import { EditOutlined, CloseOutlined } from '@ant-design/icons'
import * as yup from 'yup'
import { connect } from 'react-redux'

// import Header from 'Components/CJHeader'
import CreativeContent from 'Containers/CreativeContent'
import Preview from 'Components/CreativeContentsPreview'
import { CurrentType } from 'CustomerJourneyModule'
import './index.scss'
import Header from './HeaderSpinGame'

import { STEPS } from './constants'
import EditPopup from './ComponentPopup/EditPopup'
import SpinGameSteps from './ComponentPopup/SpinGameSteps'
import MainPopup from './ComponentPopup/MainPopup'
import CaseElement from './ComponentPopup/CaseElement'
import MainPopupMobile from './ComponentPopup/MainPopupMobile'
import Minimize from './ComponentPopup/Minimize'
import WheelPopup from './ComponentPopup/SubComponentPopup/WheelPopup'
import RightEdit from './ComponentPopup/SubComponentPopup/RightEdit'
import GiftsPopup from './ComponentPopup/SubComponentPopup/GiftsPopup'
import ChooseGift from './ComponentPopup/SubComponentPopup/ChooseGift'
import ResultPopupMobile from './ComponentPopup/ResultPopupMobile'

import { ReactComponent as EditSvg } from './img/IconLeft.svg'
import { ReactComponent as EditAcitve } from './img/EditActive.svg'
import Result from './ComponentPopup/Result'
import MainPopupEmail from './ComponentPopup/MainPopupEmail'
import Email from './ComponentPopup/Email'

const { TextArea } = Input
const { TabPane } = Tabs
const { Title } = Typography

export const webPushSchema = yup.object().shape({
  message_content: yup.string().max(160, 'No more than 160 characters'),
})

interface Props {
  isPopoverVisible: boolean
  value: any
  initData: any
  current: CurrentType
  isRunning: boolean
  setCurrent: React.Dispatch<React.SetStateAction<CurrentType>>
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
  onAddNewNode: (type: string, values: any) => void
  onUpdateNode: (values: any) => void
  setCreativeContentsState: React.Dispatch<React.SetStateAction<any>>
  valuesEmail: any
}

const OnsitePopup: React.FC<Props> = (props) => {
  const {
    isPopoverVisible,
    value,
    initData,
    setIsPopoverVisible,
    onAddNewNode,
    onUpdateNode,
    setCreativeContentsState,
    valuesEmail,
  } = props
  const activePopup = useStoreState((state) => state.randomvoucher.active)
  const { chooseElement, setUndo, setRedo, resetState, setSingleElement, editElementComp } =
    useStoreActions((actions: any) => actions.randomvoucher)
  const { device } = useStoreState((state) => state.randomvoucher)

  const { current_step, type, step, element, values, stepValue } = useStoreState(
    (state) => state.randomvoucher
  )

  const [isModalVisible, setIsModalVisible] = useState(true)

  var initialValues = {
    ...values[stepValue],
  }
  useEffect(() => {
    if (value) {
      console.log(value)
      initialValues = value?.['data']
      resetState(initialValues)
    }
    // value && setValueStatePopup(value)
    setIsPopoverVisible(false)
    setIsModalVisible(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopoverVisible])
  const onCloseModal = () => {
    setIsModalVisible(false)
  }

  const onSubmit = async (values) => {
    onAddNewNode && (await onAddNewNode('minigame', values))
    onUpdateNode && (await onUpdateNode(values))
    setIsModalVisible(false)
  }

  const doc_key = (e) => {
    //undo Win
    if (e.keyCode == 90 && e.ctrlKey) {
      if (stepValue > 0) {
        setUndo()
      }
    }
    //undo Mac
    if (e.keyCode == 90 && e.metaKey) {
      if (stepValue > 0) {
        setUndo()
      }
    }
    //redo Windo
    if (e.keyCode == 89 && e.ctrlKey) {
      if (stepValue < values?.length - 1) {
        setRedo()
      }
    }
    //redo Mac
    if (e.keyCode == 89 && e.keyCode == 16 && e.metaKey) {
      if (stepValue < values?.length - 1) {
        setRedo()
      }
    }
  }

  const renderDevice = (device) => {
    const { mainPopup } = initialValues
    const { background } = mainPopup
    switch (device) {
      case 'desktop':
        return (
          <MainPopup>
            {current_step == 0 ? (
              <div className="flex h-full">
                <div
                  style={{
                    width: '336px',
                    height: '408px',
                  }}
                  onClick={() => {
                    chooseElement({ step: 'mainPopup', element: 'Background', type: 'background' })
                  }}
                  className="WheelContent m-auto overflow-y-scroll cursor-pointer"
                >
                  <div
                    style={{
                      width: '336px',
                      height: '408px',
                      backgroundImage: `url(${background?.url && background?.url})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: background?.backgroundSize,
                      backgroundColor: `${background?.color && background?.color}`,
                      opacity: background?.opacity,
                    }}
                    className="absolute"
                  ></div>
                  <div className="relative">
                    <GiftsPopup
                      initialValues={initialValues}
                      changeType={chooseElement}
                      img={logo}
                    />
                  </div>
                  <div className="relative">
                    <ChooseGift
                      initialValues={initialValues}
                      chooseElement={chooseElement}
                      img={giftimg}
                    ></ChooseGift>
                  </div>
                </div>
              </div>
            ) : (
              current_step == 1 && (
                <Minimize
                  initialValues={initialValues}
                  device={device}
                  chooseElement={chooseElement}
                />
              )
            )}
          </MainPopup>
        )
      case 'mobile':
        return (
          <MainPopupMobile>
            {current_step == 0 ? (
              <div
                style={{
                  backgroundImage: `url(${background.url})`,
                }}
                className="WheelContent flex flex-col"
              >
                <div className="WheelContent__right ">
                  <RightEdit changeType={chooseElement} />
                </div>
                <div className="cursor-pointer">
                  <img
                    onClick={() => {
                      chooseElement('wheel')
                    }}
                    src={backgroundImgMobile}
                    alt=""
                  />
                </div>
              </div>
            ) : (
              current_step == 1 && (
                <Minimize
                  initialValues={initialValues}
                  device={device}
                  chooseElement={chooseElement}
                />
              )
            )}
          </MainPopupMobile>
        )
    }
  }

  const renderResult = (device) => {
    switch (device) {
      case 'desktop':
        return (
          <MainPopup>
            <Result initialValues={initialValues} device={device} chooseElement={chooseElement} />
          </MainPopup>
        )
      case 'mobile':
        return (
          <ResultPopupMobile>
            <Result
              initialValues={initialValues}
              chooseElement={chooseElement}
              device={device}
            ></Result>
          </ResultPopupMobile>
        )
    }
  }

  const renderEmail = () => {
    return (
      <MainPopupEmail>
        <Email
          values={valuesEmail?.data?.email}
          editElementComp={editElementComp}
          setSingleElement={setSingleElement}
          props={props}
        />
      </MainPopupEmail>
    )
  }

  const renderBody = () => {
    switch (current_step) {
      case 0:
        return renderDevice('desktop')
      case 1:
        return renderDevice(device)
      case 2:
        return renderResult(device)
      case 3:
        return renderEmail()
    }
  }

  return (
    <Modal
      {...props}
      onOk={onCloseModal}
      onCancel={() => setIsModalVisible(false)}
      visible={isModalVisible}
      maskClosable
      title={<Header {...props} onSubmit={onSubmit} onCloseModal={onCloseModal} />}
      footer={[]}
      // width={'100vw'}
      closable={false}
      className="ChannelsModal w-screen"
      wrapClassName="modal-overlayclassname"
    >
      <div onKeyDown={(e) => doc_key(e)} className="spingame_layout_contain w-full">
        <div className="spingame_layout flex">
          <div className="left-side-pupup border ">
            <div className="p-2">
              <EditAcitve
                onClick={() => {
                  step != 'email' &&
                    chooseElement({ step: step, element: 'Background', type: 'background' })
                }}
                className="m-auto cursor-pointer"
              />
            </div>
          </div>
          <div className={`SpinGameEdit ${!activePopup && 'hidden'}`}>
            <div style={{ width: 300, height: '90vh' }}>
              <CaseElement element={element} type={type} step={step} />
            </div>
          </div>
          <div className="SpinGameEditMainContent w-full">
            <div className="ProgressPopupComponent ">
              <SpinGameSteps values={value} />
              <div className="MainPopupStepContainer border bg-white w-full">{renderBody()}</div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default connect(
  (state: any) => ({
    initData: state.campaign.creativeContents.previewData,
  }),
  (dispatch: any) => ({
    setCreativeContentsState: dispatch.campaign.creativeContents.setState,
    clearPreviewData: dispatch.campaign.creativeContents.clearPreviewData,
  })
)(OnsitePopup)
