import React from 'react'
import { Modal, Card, Button, Tabs, Input, List, Typography } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import StylePopup from './SubComponentPopup/StylePopup'
import { ReactComponent as Close } from '../img/close-line.svg'

import ColorPickerFull from '../ColorPickerPopup/Modal/ColorPickerFull'

const { Title } = Typography

const EditInput = ({ toggle, editElementComp, values }) => {
  const background = values?.background
  const errorTextColor = values?.errorTextColor
  const handleOnChange = (e) => {
    const upDateValue = {
      ...values,
      content: e.target.value,
    }
    editElementComp(upDateValue)
  }

  const handleOnClickChangeStyle = (props) => {
    const upDateValue = {
      ...values,
      background: {
        backgroundColor: props.value,
        opacity: props.opacity,
      },
    }

    editElementComp(upDateValue)
  }
  const handleOnClickChangeErrorColor = (props) => {
    const upDateValue = {
      ...values,
      errorTextColor: props.value,
    }
    editElementComp(upDateValue)
  }
  return (
    <>
      <Card
        size="small"
        title={
          <Title style={{ padding: '8px 4px' }} level={5}>
            Input
          </Title>
        }
        extra={
          <Close
            onClick={() => toggle()}
            className="cursor-pointer"
            style={{ fontSize: '20px', color: '#00000' }}
          />
        }
        bodyStyle={{ padding: '16px' }}
      >
        <div className="EditPopupElement">
          <Title style={{ fontSize: 14 }} level={5}>
            Placeholder
          </Title>
          <div className="w-full mt-2">
            <Input
              className="py-2 pl-4"
              onChange={handleOnChange}
              style={{ color: '#000000' }}
              defaultValue={'Email của bạn'}
            />
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: '16px' }}>
        <div className="">
          <Title style={{ fontSize: 14 }} level={5}>
            Background
          </Title>
          <div className="flex justify-between items-center mt-4">
            <div className="ColorTitle ">
              <span>Color</span>
            </div>
            <div>
              <div>
                <ColorPickerFull
                  color={background?.backgroundColor}
                  choiceColor={handleOnClickChangeStyle}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: '16px' }}>
        <div className="">
          <Title style={{ fontSize: 14 }} level={5}>
            Error text color
          </Title>
          <div className="flex justify-between items-center mt-4">
            <div className="ColorTitle ">
              <span>Color</span>
            </div>
            <div>
              <div>
                <ColorPickerFull
                  color={errorTextColor}
                  choiceColor={handleOnClickChangeErrorColor}
                />
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Card style={{ borderBottom: 0 }} bodyStyle={{ padding: '16px' }}>
        <Title style={{ fontSize: 14 }} level={5}>
          Text
        </Title>
        <div className="EditPopupItems mt-8">
          <StylePopup getElement={values} editElementComp={editElementComp} />
        </div>
      </Card>
    </>
  )
}

export default EditInput
