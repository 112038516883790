import React from 'react'
import { Modal, Card, Button, Tabs, Input, List, Typography, Layout, Menu, Steps } from 'antd'

const ButtonPopup = ({ changeType, textContent }) => {
  const { initContent, style, buttonStyle } = textContent
  const { hover } = buttonStyle
  const { fontFamily, color, fontSize, fontStyle, textAlign, backgroundColor } = style

  const fontStyleClass = (fontStyle) => {
    if (fontStyle == 'bold') {
      return 'font-semibold'
    }
    if (fontStyle == 'underline') {
      return 'underline'
    }
    if (fontStyle == 'italic') {
      return 'italic'
    }
  }
  const onHandleOnMouseOver = (e) => {
    e.target.style.backgroundColor = hover
  }
  const onHandleOnMouseOut = (e) => {
    e.target.style.backgroundColor = backgroundColor
  }

  return (
    <div
      onClick={() => {
        changeType('button')
      }}
    >
      <button
        style={{
          ...style,
          fontStyle: fontStyle == 'italic' ? 'italic' : '',
        }}
        onMouseOver={onHandleOnMouseOver}
        onMouseOut={onHandleOnMouseOut}
        className={`WheelContent__btn-submit w-full ${fontStyleClass(fontStyle)}`}
      >
        {initContent}
      </button>
    </div>
  )
}

export default ButtonPopup
