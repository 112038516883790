import React from 'react'
import { Input } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

const HeaderTagPopover = (props) => {
  const { item, index, onChangeLabelItem, onDeleteItems } = props
  const onChangeLabel = (e) => {
    onChangeLabelItem(item.value, e.target.value)
  }
  return (
    <div>
      <Input
        onChange={(e) => {
          onChangeLabel(e)
        }}
        allowClear
        style={{ width: '' }}
        defaultValue={item.value}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      />
      <div
        onClick={(e) => {
          e.stopPropagation()
          onDeleteItems(item.value)
        }}
        className="mt-2 flex items-center cursor-pointer"
      >
        <DeleteOutlined />
        <span className="ml-3">Delete</span>
      </div>
    </div>
  )
}

export default HeaderTagPopover
