import React from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

const ButtonPopup = ({ chooseElement, textContent }) => {
  const { type } = useStoreState((state) => state.popup)
  const { content, style, buttonStyle } = textContent
  const { hover } = buttonStyle
  const { fontStyle, backgroundColor } = style

  const fontStyleClass = (fontStyle) => {
    if (fontStyle == 'bold') {
      return 'font-semibold'
    }
    if (fontStyle == 'underline') {
      return 'underline'
    }
    if (fontStyle == 'italic') {
      return 'italic'
    }
  }
  const onHandleOnMouseOver = (e) => {
    e.target.style.backgroundColor = hover
  }
  const onHandleOnMouseOut = (e) => {
    e.target.style.backgroundColor = backgroundColor
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        // chooseElement({ step: 'mainPopup', type: 'Button' })
        chooseElement({ step: 'mainPopup', element: 'Button', type: 'button' })
      }}
    >
      <button
        style={{
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
          ...style,
          fontStyle: fontStyle == 'italic' ? 'italic' : '',
        }}
        onMouseOver={onHandleOnMouseOver}
        onMouseOut={onHandleOnMouseOut}
        className={`WheelContent__btn-submit w-full px-2 truncate ${fontStyleClass(fontStyle)} ${
          type == 'button' && `border-active-element`
        }`}
      >
        {content}
      </button>
    </div>
  )
}

export default ButtonPopup
