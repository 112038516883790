import React, { useMemo, useState } from 'react'
import { useStoreState } from 'easy-peasy'
import { Select } from 'antd'

import { toCamel } from 'Utils'
import WebPushPreview from './WebPush'
import KaiPassPreview from './Kaipass'
import EmailPreview from './Email/JunoTemplate'
import LinkClicksPreview from './Facebook/LinkClicks'
import PostEngagementPreview from './Facebook/PostEngagement'
import HaraSocialPreview from './Harasocial'
import TargetingItem from 'Pages/Harasiocial/CustomerPortrait/Components/Customer/TargetingItem'
import AppPushPreview from '../AppPush'

const platforms = [
  { label: 'Window', value: 'window' },
  { label: 'Mac OS', value: 'mac_os' },
  { label: 'Android', value: 'android' },
]

const ContentPreview = () => {
  const { selected_placement, content } = useStoreState((state) => state.campaignModule)

  const [platform, setPlatform] = useState(platforms[0].value)

  const PreviewScreen = useMemo(() => {
    console.log('selected_placement?.code: ', selected_placement?.code)
    switch (selected_placement?.code) {
      case 'web_push':
        return <WebPushPreview data={content} platform={platform} />
      case 'kaipass':
        return <KaiPassPreview data={content} />
      case 'tch_app':
        return <AppPushPreview data={content} />
      case 'email':
        return <EmailPreview data={content} />
      case 'link_clicks':
        return <LinkClicksPreview data={content} />
      case 'post_engagement':
        return <PostEngagementPreview data={content} />
      case 'hrs_store_farming':
        return (
          <div className="shadow-lg" style={{ width: 380 }}>
            <TargetingItem task={toCamel(content)} expanded></TargetingItem>
          </div>
        )
      case 'harasocial':
        return <HaraSocialPreview data={content} />
      default:
        return <div className="w-full text-center">No preview available</div>
    }
  }, [content, platform, selected_placement])

  return (
    <div className="w-full" id="ContentPreview">
      <div className="flex justify-between items-center">
        <span className="font-semibold text-lg">Preview</span>
        <div className="platform-select text-brand_primary font-semibold">
          <Select options={platforms} onChange={(value) => setPlatform(value)} value={platform} />
        </div>
      </div>
      <div className="preview-screen">
        <div>{PreviewScreen}</div>
      </div>
    </div>
  )
}

export default ContentPreview
