import { Tag } from 'antd'
import React from 'react'
import { COLOR_TAG } from './Contant'

const BodyTagPopover = (props) => {
  const { onChangeColorItem, index, item } = props
  return (
    <div>
      {COLOR_TAG.map((color) => {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation()
              onChangeColorItem(item.value, color.value)
            }}
            key={color.value}
            className="flex items-center mb-3 cursor-pointer hover:bg-hover_overlay py-1 px-2 "
          >
            <Tag style={{ borderRadius: 28 }} className="p-3 mr-3" color={color.value}></Tag>
            <div>{color.label}</div>
          </div>
        )
      })}
    </div>
  )
}

export default BodyTagPopover
