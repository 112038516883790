import React from 'react'

const MainPopupEmail = ({ children }) => {
  return (
    <div
      style={{ background: '#E5E5E5' }}
      className="MainPopupStepsContent h-full overflow-y-scroll"
    >
      <div className="MainPopupStep ">
        <div className="MainPopupStepContent">{children}</div>
      </div>
    </div>
  )
}

export default MainPopupEmail
