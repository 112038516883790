import React from 'react'
import { Modal, Card, Button, Tabs, Input, List, Typography, Layout, Menu, Steps } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'

const InputPopup = ({ changeType, textContent, textEmailValidate }) => {
  const { type } = useStoreState((state) => state.codedefault)
  const { content, style } = textContent
  const { fontFamily, color, fontSize, fontStyle, textAlign, backgroundColor } = style
  const fontStyleClass = (fontStyle) => {
    if (fontStyle == 'bold') {
      return 'font-semibold'
    }
    if (fontStyle == 'underline') {
      return 'underline'
    }
    if (fontStyle == 'italic') {
      return 'italic'
    }
  }

  return (
    <>
      <div
        className={`cursor-pointer ${type == 'input' && `border-active-element`}`}
        onClick={(e) => {
          e.stopPropagation()
          changeType({ step: 'mainPopup', element: 'Input', type: 'input' })
        }}
      >
        <Input
          className={`${fontStyleClass(fontStyle)}`}
          style={{
            ...style,
            fontStyle: fontStyle == 'italic' ? 'italic' : '',
            height: 40,
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)',
            width: 262,
          }}
          placeholder={content}
        />
      </div>
      <div
        className={`cursor-pointer ${fontStyleClass(textEmailValidate?.style.fontStyle)} ${
          type === 'text_3' && `border-active-element`
        }`}
        onClick={(e) => {
          e.stopPropagation()
          changeType({ step: 'mainPopup', element: 'Text', type: 'text_3' })
        }}
        style={{
          ...textEmailValidate?.style,
          fontStyle: textEmailValidate?.style.fontStyle == 'italic' ? 'italic' : '',
        }}
      >
        {textEmailValidate?.content == '' ? 'Email' : textEmailValidate?.content}
      </div>
    </>
  )
}

export default InputPopup
