export const DATE_FORMAT = 'YYYY-MM-DD'

export const PI_METRIC = [
  {
    code: 'page_view',
    aggerate: 'sum',
  },
  {
    code: 'add_to_cart',
    aggerate: 'sum',
  },
  {
    code: 'buy',
    aggerate: 'sum',
  },
  {
    code: 'revenue',
    aggerate: 'sum',
  },
  {
    code: 'gross_profit',
    aggerate: 'sum',
  },

  // {
  //   code: 'ltv',
  //   aggregate: 'avg',
  // },
  // {
  //   code: 'life_cycle',
  //   aggregate: 'avg',
  // },
  // {
  //   code: 'cat',
  //   aggregate: 'sum',
  // },
  // {
  //   code: 'sub_cat',
  //   aggregate: 'sum',
  // },
  // {
  //   code: 'sku',
  //   aggregate: 'sum',
  // },
  // {
  //   code: 'sku_name',
  //   aggregate: 'sum',
  // },
  // {
  //   code: 'sku_image',
  //   aggregate: 'sum',
  // },
  // {
  //   code: 'page_view',
  //   aggregate: 'sum',
  // },
  // {
  //   code: 'add_to_cart',
  //   aggregate: 'sum',
  // },

  // {
  //   code: 'revenue',
  //   aggregate: 'sum',
  // },
  // {
  //   code: 'gross_profit',
  //   aggregate: 'sum',
  // },
]

export const PI_DIMENSION = [
  { code: 'campaign' },
  { code: 'category' },
  { code: 'sub_category' },
  // { code: 'sku' },
  { code: 'product_name' },
  // { code: 'image' },
]

export const BY_TIME_METRICS = [
  {
    code: 'deliver',
  },
  {
    code: 'session',
  },
  {
    code: 'ctr',
  },
  {
    code: 'bills',
    aggregate: 'sum',
  },
  {
    code: 'cr_online',
    aggregate: 'sum',
  },
  {
    code: 'cr_offline',
    aggregate: 'sum',
  },
  {
    code: 'online_revenue',
    aggregate: 'sum',
  },
  {
    code: 'offline_revenue',
    aggregate: 'sum',
  },
  // Missing Return Revenue
  {
    code: 'actual_revenue',
    aggregate: 'sum',
  },
  {
    code: 'cancellation_rate',
    aggregate: 'sum',
  },
  {
    code: 'discount',
    aggregate: 'sum',
  },
  // Missing AOV
  {
    code: 'mkt_cost',
    aggregate: 'sum',
  },
  {
    code: 'cpo',
  },
  {
    code: 'cir',
  },
  {
    code: 'gross_profit',
    aggregate: 'sum',
  },
  {
    code: 'gross_margin',
    aggregate: 'sum',
  },

  // {
  //   code: 'customer',
  //   aggregate: 'sum',
  // },
  // {
  //   code: 'cancel_bills',
  //   aggregate: 'sum',
  // },
  // {
  //   code: 'online_bills',
  //   aggregate: 'sum',
  // },
  // {
  //   code: 'offline_bills',
  //   aggregate: 'sum',
  // },
  // {
  //   code: 'audience',
  //   aggregate: 'sum',
  // },
  // {
  //   code: 'ctr',
  // },
  // {
  //   code: 'clicks',
  // },
]

export const CUSTOMER_METRICS = [
  {
    code: 'quantity',
    aggregate: 'sum',
  },
  {
    code: 'revenue',
    aggregate: 'sum',
  },
]

export const CUSTOMER_DIMENSION = [
  { code: 'category' },
  { code: 'sub_category' },
  { code: 'product_name' },
  { code: 'sku' },
  { code: 'image' },
  // { code: 'image' },
]
export const PRODUCT_PIVOT_METRICS = [
  {
    display_name: 'Product Quantity',
    code: 'sum_quantity',
    data_type: 'string',
  },
  {
    display_name: 'Inventory Quantity',
    code: 'sum_inventory_quantity',
    data_type: 'string',
  },
  {
    display_name: 'Inventory Value',
    code: 'sum_inventory_value',
    data_type: 'string',
  },
  {
    display_name: 'View',
    code: 'cnt_view',
    data_type: 'string',
  },
  {
    display_name: 'Add to cart',
    code: 'cnt_a2c',
    data_type: 'string',
  },
  {
    display_name: 'Order',
    code: 'nunique_order',
    data_type: 'string',
  },
  {
    display_name: 'CR = Order/View',
    code: 'cr',
    data_type: 'string',
  },
  {
    display_name: 'AOV',
    code: 'aov',
    data_type: 'string',
  },
  {
    display_name: 'Revenue',
    code: 'sum_revenue',
    data_type: 'string',
  },
  {
    display_name: 'Promotion',
    code: 'sum_promotion',
    data_type: 'string',
  },
  {
    display_name: 'Gross Profit',
    code: 'sum_gross_profit',
    data_type: 'string',
  },
  {
    display_name: '% Gross Profit',
    code: 'ratio_gross_profit',
    data_type: 'string',
  },
]
export const PRODUCT_PIVOT_DIMENSION = []
