import React, { useState, useEffect } from 'react'
import { Tooltip, Button, Radio } from 'antd'
import {
  LeftOutlined,
  QuestionCircleOutlined,
  RollbackOutlined,
  ArrowLeftOutlined,
  DesktopOutlined,
  MobileOutlined,
} from '@ant-design/icons'

import { CurrentType } from 'CustomerJourneyModule'

import './index.scss'
import { ReactComponent as ButtonUn } from './img/ButtonUn.svg'
import { ReactComponent as ButtonRe } from './img/ButtonRe.svg'

import { ReactComponent as Mobile } from './img/mobile.svg'
import { ReactComponent as MobileActive } from './img/mobileActive.svg'

import { ReactComponent as Desktop } from './img/desktop.svg'
import { ReactComponent as DesktopActive } from './img/destopActive.svg'
import computerIcon from './img/macbook-line.png'

import { useStoreState, useStoreActions } from 'easy-peasy'

interface props {
  current: CurrentType
  setCurrent: React.Dispatch<React.SetStateAction<CurrentType>>
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
  onSubmit: (values: any) => void
  onCloseModal: () => void
}

const Header: React.FC<props> = ({
  current,
  setCurrent,
  setIsPopoverVisible,
  onSubmit,
  onCloseModal,
}) => {
  const { name, previous, description } = current
  const { setDevice, setUndo, setRedo, resetUndoRedo } = useStoreActions(
    (actions: any) => actions.popup
  )
  const { device, current_step, values, stepValue } = useStoreState((state) => state.popup)

  const onGoBack = (prev: React.SetStateAction<CurrentType>) => {
    setIsPopoverVisible(true)
    setCurrent(prev)
  }

  const handleSizeChange = (e) => {
    setDevice(e)
  }

  const JSON_SUBMIT = {
    popupType: 'spin_game',
    data: values[stepValue]?.email?.emailData?.template_id
      ? {
          ...values[stepValue],
        }
      : {
          ...values[stepValue],
          email: {
            emailData: {},
          },
        },
  }

  return (
    <div className="CJPopoverHeader">
      <div className="back-btn mr-2 flex items-center">
        {previous ? (
          <div className="cursor-pointer" onClick={() => onGoBack(previous)}>
            <ArrowLeftOutlined />
            <span className="ml-2">Back</span>
          </div>
        ) : (
          <div className="cursor-pointer" onClick={() => onCloseModal()}>
            <ArrowLeftOutlined />
            <span className="ml-2">Back</span>
          </div>
        )}
        <div className="ml-2 flex">
          <button disabled={stepValue <= 0 && true} className={`p-1`} onClick={() => setUndo()}>
            <ButtonUn style={{ opacity: `${stepValue == 0 ? 0.5 : 1}` }} className={`rounded `} />
          </button>
          <button
            disabled={stepValue == values?.length - 1 && true}
            className={`p-1`}
            onClick={() => setRedo()}
          >
            <ButtonRe
              style={{ opacity: `${stepValue == values?.length - 1 ? 0.5 : 1}` }}
              className={`rounded `}
            />
          </button>
        </div>
      </div>

      {/* <div className="title">{name}</div> */}

      <div className={`icon flex`}>
        <div className={`mr-2 ${current_step == 3 && 'hidden'} flex`}>
          <div>
            {device == 'desktop' ? (
              <DesktopActive />
            ) : (
              <Desktop className="cursor-pointer" onClick={() => handleSizeChange('desktop')} />
            )}
          </div>
          <div>
            {device == 'mobile' ? (
              <MobileActive />
            ) : (
              <Mobile className="cursor-pointer" onClick={() => handleSizeChange('mobile')} />
            )}
          </div>
        </div>
        <div className="SaveNext">
          {current_step < 3 ? (
            <button
              onClick={() => {
                resetUndoRedo(current_step + 1)
              }}
              className="px-2"
            >{`Save & Next`}</button>
          ) : (
            <button
              onClick={() => {
                onSubmit(JSON_SUBMIT)
              }}
              className="px-2"
            >{`Save`}</button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
