import React, { useState, useEffect } from 'react'
import { Modal, Button, Tabs, Input } from 'antd'
import * as yup from 'yup'
import { connect } from 'react-redux'

import Header from 'Components/CJHeader'
import CreativeContent from 'Containers/CreativeContent'
import Preview from 'Components/CreativeContentsPreview'
import { CurrentType } from 'CustomerJourneyModule'
import './index.scss'
import CustomHtml from './index.template'

const { TextArea } = Input
const { TabPane } = Tabs

export const webPushSchema = yup.object().shape({
  message_content: yup.string().max(160, 'No more than 160 characters'),
})

const initialValues = {
  headline: 'Khoan đã, hình như bạn quên sử dụng voucher...',
  promo_title: 'GIẢM ĐẾN 20%!!!',
  promo_content:
    'Tiếp tục mua hàng và sử dụng mã {{MUAHANGLANDAU}} để được giảm 20% cho lần mua hàng đầu tiên bạn nhé',
  go_back_button_label: 'Shopping tiếp thôi',
  go_back_button_url: 'https://www.facebook.com',
  popupType: 'customize',
}

const schemas = [
  { name: 'headline', type: 'string', label: 'Headline' },
  { name: 'popupType', type: 'hidden', label: 'Headline' },
  { name: 'promo_title', type: 'string', label: 'Promo title' },
  {
    name: 'promo_content',
    type: 'text_area',
    label: 'Promo content',
    prefix: '{{code}} to highlight promo code',
  },
  {
    type: [
      { name: 'go_back_button_label', type: 'string' },
      { name: 'go_back_button_url', type: 'string' },
    ],
    label: 'Go back button',
  },
]

// const customHTMLSchema = [
//   {
//     name: 'promo_content',
//     type: 'text_area',
//     label: 'Promo content',
//     prefix: '{{code}} to highlight promo code',
//   },
// ]

interface Props {
  isPopoverVisible: boolean
  value: any
  initData: any
  current: CurrentType
  isRunning: boolean
  setCurrent: React.Dispatch<React.SetStateAction<CurrentType>>
  setIsPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>
  onAddNewNode: (type: string, values: any) => void
  onUpdateNode: (values: any) => void
  setCreativeContentsState: React.Dispatch<React.SetStateAction<any>>
}

const OnsitePopup: React.FC<Props> = (props) => {
  const {
    isPopoverVisible,
    value,
    initData,
    setIsPopoverVisible,
    onAddNewNode,
    onUpdateNode,
    setCreativeContentsState,
  } = props

  const { headline, promo_title, promo_content, go_back_button_label, go_back_button_url } =
    initData
  const [isModalVisible, setIsModalVisible] = useState(true)
  const [blockedLinks, setBlockedLinks] = useState<string>('')
  const [customHTML, setCustomHTML] = useState<string>()
  const onCloseModal = () => {
    setIsModalVisible(false)
  }
  console.log('value ', value)

  const onSubmit = async (values: any) => {
    const popupPreviewComponentElement = document.querySelector('.PopupPreviewComponent')
    // popup template DOM
    if (!popupPreviewComponentElement) return

    const valuesIncludeHTMLTemplate = {
      popupType: 'customize',
      ...values,
      htmlTemplate: popupPreviewComponentElement.outerHTML,
      blockedLinks: blockedLinks || '',
    }

    // post field values and HTML DOM for BE usage
    onAddNewNode && (await onAddNewNode('popup', valuesIncludeHTMLTemplate))
    onUpdateNode && (await onUpdateNode(valuesIncludeHTMLTemplate))
    setIsModalVisible(false)
  }

  const onCustomHTMLSubmit = async () => {
    onAddNewNode &&
      (await onAddNewNode('popup', {
        popupType: 'customize',
        htmlTemplate: customHTML,
        blockedLinks: blockedLinks || '',
      }))
    onUpdateNode &&
      (await onUpdateNode({
        headline: null,
        promo_title: null,
        promo_content: null,
        go_back_button_label: null,
        go_back_button_url: null,
        htmlTemplate: customHTML,
        blockedLinks: blockedLinks || '',
      }))
    setIsModalVisible(false)
  }

  useEffect(() => {
    setCustomHTML(value?.htmlTemplate)
    setBlockedLinks(value?.blockedLinks)
    setCreativeContentsState({ key: 'previewData', value: value || initialValues })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setIsPopoverVisible(false)
    setIsModalVisible(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopoverVisible])

  return (
    <Modal
      {...props}
      onOk={onCloseModal}
      onCancel={() => setIsModalVisible(false)}
      visible={isModalVisible}
      maskClosable
      title={<Header {...props} />}
      footer={[]}
      width={912}
      closable={false}
      className="ChannelsModal"
    >
      <Tabs
        defaultActiveKey={
          !headline &&
          !promo_title &&
          !promo_content &&
          !go_back_button_label &&
          !go_back_button_url
            ? 'custom_html'
            : 'default'
        }
        className={`PopupComponent ${props.isRunning ? 'is-running' : ''}`}
      >
        <TabPane tab="Default" key="default">
          <div className="PopupInputs">
            <CreativeContent
              onSubmit={onSubmit}
              submitText="Save"
              initValues={value || initialValues}
              validationSchema={webPushSchema}
              fields={schemas}
            />
          </div>
          <Preview isPreview={true} type={'popup'}></Preview>
        </TabPane>
        <TabPane tab="Custom HTML" key="custom_html">
          <div className="PopupInputs customHTML">
            <CustomHtml
              value={customHTML || ''}
              onChange={(e) => setCustomHTML(e)}
              setBlockedLinks={setBlockedLinks}
              blockedLinks={blockedLinks}
            />
            <Button
              style={{ float: 'right', marginTop: '20px' }}
              type="primary"
              onClick={onCustomHTMLSubmit}
            >
              Save
            </Button>
          </div>
          <div
            className="PopupCustomHTML-preview"
            dangerouslySetInnerHTML={{ __html: customHTML || '' }}
          ></div>
        </TabPane>
      </Tabs>
    </Modal>
  )
}

export default connect(
  (state: any) => ({
    initData: state.campaign.creativeContents.previewData,
  }),
  (dispatch: any) => ({
    setCreativeContentsState: dispatch.campaign.creativeContents.setState,
    clearPreviewData: dispatch.campaign.creativeContents.clearPreviewData,
  })
)(OnsitePopup)
