import { RankListType } from 'Components/RankingCard'

export const METRICS = [
  { label: 'Sessions', value: 123 },
  { label: 'Số đơn', value: 85 },
  { label: 'Tỉ lệ chuyển đổi', value: 25 },
  { label: 'Lượt truy cập mới', value: 808000 },
  { label: 'Khách hàng mới', value: 80 },
  { label: 'KH tạo doanh thu', value: 80 },
  { label: 'Giá trị đơn hàng trung bình', value: 750000 },
  { label: 'Doanh thu đơn hàng', value: 180000000 },
  { label: 'Doanh thu thành công', value: 112000000 },
]

export const TABS: { label: string; value: string }[] = [
  { label: 'Tổng quan', value: 'general' },
  { label: 'Bảng xếp hạng', value: 'leader-board' },
]

// export const RANK_LIST: RankListType[] = [
//   { name: 'b', id: 1, statistic: { number: 10, unit: 'percentage' } },
//   { name: 'a', id: 2, statistic: { number: 12, unit: 'percentage' } },
//   { name: 'a', id: 3, statistic: { number: 15, unit: 'percentage' } },
//   { name: 'a', id: 4, statistic: { number: 18, unit: 'percentage' } },
//   { name: 'a', id: 5, statistic: { number: 25, unit: 'percentage' } },
//   { name: 'a', id: 6, statistic: { number: 37, unit: 'percentage' } },
// ]
// export const RANK_LIST_2: RankListType[] = [
//   { name: 'b', id: 1, statistic: { number: 10, unit: 'percentage' } },
//   { name: 'b', id: 2, statistic: { number: 12, unit: 'percentage' } },
//   { name: 'b', id: 3, statistic: { number: 15, unit: 'percentage' } },
//   { name: 'b', id: 4, statistic: { number: 18, unit: 'percentage' } },
//   { name: 'b', id: 5, statistic: { number: 25, unit: 'percentage' } },
//   { name: 'b', id: 6, statistic: { number: 37, unit: 'percentage' } },
// ]
