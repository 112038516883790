const AppPushSchema = [
  {
    name: 'title',
    type: 'dynamic_content',
    label: 'Title',
    isRequired: true,
  },
  {
    name: 'content',
    type: 'dynamic_content',
    label: 'Content',
    isRequired: true,
  },
  {
    name: 'image',
    type: 'image',
    label: 'Image',
    isRequired: true,
  },
  {
    name: 'button_name',
    type: 'string',
    label: 'Button Name',
    isRequired: true,
  },
  {
    name: 'button_nav_screen',
    type: 'select',
    label: 'Navigate to',
    placeholder: 'Choose a screen to navigate to',
    options: [
      {
        code: 'coupon',
        name: 'Coupon Screen',
      },
      {
        code: 'order',
        name: 'Order Screen',
      },
      {
        code: 'rewards',
        name: 'Reward Screen',
      },
      {
        code: 'stores',
        name: 'Store Screen',
      },
      {
        code: 'news',
        name: 'News Screen',
      },
    ],
    isRequired: true,
  },

  {
    name: 'coupon_code',
    type: 'string',
    label: 'Coupon Code',
  },

  {
    name: 'utm_source',
    type: 'string',
    label: 'UTM Source',
    isRequired: true,
  },
  {
    name: 'utm_medium',
    type: 'string',
    label: 'UTM Medium',
    isRequired: true,
  },
  {
    name: 'utm_campaign',
    type: 'string',
    label: 'UTM Campaign',
    isRequired: true,
  },
]

export default AppPushSchema

export type AppPushParams = {
  title: string
  content: string
  image: string
  coupon_code: string
  button_name: string
  button_nav_screen: string
  utm_source: string
  utm_medium: string
  utm_campaign: string
}
