import { action, thunk } from 'easy-peasy'

const mainPopup = {
  imgGift: {
    url: '',
  },
  background: {
    url: ' https://storage.googleapis.com/tch-mcm-public-storage/product-images/f5372209-093b-49b8-921f-28b5a3babdc7',
    backgroundSize: 'cover',
    opacity: 1,
    color: '#FFFFFF',
  },
  text: {
    content: 'TẶNG VOUCHER',
    style: {
      fontFamily: 'Inter',
      color: '#000',
      fontSize: 20,
      fontStyle: 'bold',
      textAlign: 'center',
      opacity: 1,
    },
  },
  default_coupon: {
    content: '20%',
    code: '',
    style: {
      fontFamily: 'Inter',
      color: '#FF0000',
      fontSize: 70,
      fontStyle: 'bold',
      textAlign: 'center',
      opacity: 1,
    },
  },
  text_2: {
    content: 'Khi đăng ký email của bạn',
    style: {
      fontFamily: 'Inter',
      color: '#000000',
      fontSize: 14,
      fontStyle: 'normal',
      textAlign: 'center',
      opacity: 1,
    },
  },
  text_3: {
    content: 'Email không hợp lệ!!',
    style: {
      fontFamily: 'Inter',
      color: '#F5222D',
      fontSize: 14,
      fontStyle: 'normal',
      textAlign: 'center',
      opacity: 1,
    },
  },
  input: {
    content: 'Email của bạn',
    background: {
      backgroundColor: 'white',
      opacity: 1,
    },
    style: {
      fontFamily: 'Inter',
      backgroundColor: 'white',
      color: '#fffff',
      fontSize: 14,
      fontStyle: 'normal',
      textAlign: 'left',
      opacity: 1,
    },
  },
  button: {
    content: 'Nhận voucher',
    buttonStyle: {
      hover: '#D4380D',
      opacity: 1,
    },
    style: {
      fontFamily: 'Inter',
      backgroundColor: '#FA541C',
      color: 'white',
      fontSize: 14,
      fontStyle: 'normal',
      textAlign: 'center',
      opacity: 1,
    },
  },
}

const minimize = {
  background: {
    url: ' https://storage.googleapis.com/tch-mcm-public-storage/product-images/f5372209-093b-49b8-921f-28b5a3babdc7',
    backgroundSize: 'cover',
    opacity: 1,
  },
  text: {
    content: 'SALE NGÀY 11 - 11',
    style: {
      fontFamily: 'Inter',
      color: '#FA541C',
      fontSize: 14,
      fontStyle: 'bold',
      textAlign: 'center',
      opacity: 1,
    },
  },
}

const result = {
  text: {
    content: 'Cảm ơn bạn',
    style: {
      fontFamily: 'Inter',
      color: '#000',
      fontSize: 28,
      fontStyle: 'bold',
      textAlign: 'center',
      opacity: 1,
    },
  },
  background: {
    url: ' https://storage.googleapis.com/tch-mcm-public-storage/product-images/f5372209-093b-49b8-921f-28b5a3babdc7',
    backgroundSize: 'cover',
    opacity: 1,
    effects: 'Confetti',
  },
  text_1: {
    content: 'Mã khuyến mãi đã được gửi vào email của bạn rồi đấy, bạn kiểm tra nhé!',
    style: {
      fontFamily: 'Inter',
      color: '#000000',
      fontSize: 16,
      fontStyle: 'normal',
      textAlign: 'center',
      opacity: 1,
    },
  },
  button: {
    content: 'Tiếp tục mua sắm',
    buttonStyle: {
      hover: '#D4380D',
      // backgroundColor: '#FA541C',
    },
    style: {
      fontFamily: 'Inter',
      backgroundColor: '#FA541C',
      color: '#FFFFFF',
      fontSize: 14,
      fontStyle: 'bold',
      textAlign: 'center',
    },
  },
}

const email = {
  emailData: {
    subject: '',
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    image_item: '',
    image_item_url: '',
    image_item_1: '',
    image_item_1_url: '',
    image_item_2: '',
    image_item_2_url: '',
  },
}
const initState = {
  active: false,
  type: '',
  element: '',
  device: 'desktop',
  step: 'mainPopup',
  current_step: 0,
  stepMax: 0,
  stepValue: 0,
  values: [{ mainPopup, minimize, result, email }],
  valueState: { mainPopup, minimize, result, email },
}

export default {
  ...initState,
  resetState: action((state, values) => {
    state.valueState = values
    state.values = []
    state.values[0] = values
    state.stepValue = 0
    state.stepMax = 4
  }),
  showEditChangeStep: action((state, value) => {
    switch (value) {
      case 0:
        state.step = 'mainPopup'
        break
      case 1:
        state.step = 'minimize'
        break
      case 2:
        state.step = 'result'
        break
      case 3:
        state.step = 'email'
        break
      default:
    }
    state.current_step = value
    state.active = state.step != 'email' && true
    state.step = state.step
    state.element = 'Background'
    state.type = 'background'
  }),
  resetUndoRedo: action((state, value) => {
    switch (value) {
      case 0:
        state.step = 'mainPopup'
        break
      case 1:
        state.step = 'minimize'
        break
      case 2:
        state.step = 'result'
        break
      case 3:
        state.step = 'email'
        break
      default:
    }

    state.current_step = value
    state.values = state.values.slice(state.stepValue, state.stepValue + 1)
    state.stepValue = 0
    state.active = state.step != 'email' && true
    state.step = state.step
    state.element = 'Background'
    state.type = 'background'
    if (value > state.stepMax) state.stepMax = value
  }),
  setUndo: action((state) => {
    state.stepValue = state.stepValue == 0 ? 0 : state.stepValue - 1
  }),
  setRedo: action((state) => {
    state.stepValue =
      state.stepValue == state.values.length - 1 ? state.values.length - 1 : state.stepValue + 1
  }),
  setState: action((state, { key, value }) => {
    state[key] = value
    state.active = false
    if (value > state.stepMax) state.stepMax = value
  }),
  toggle: action((state) => {
    state.active = !state.active
  }),
  chooseElement: action((state, { step, element, type }) => {
    state.active = true
    state.step = step
    state.element = element
    state.type = type
  }),
  editElementComp: action((state, value) => {
    if (state.current_step == 3) {
      state.step = 'email'
      state.type = 'emailData'
    }
    state.valueState[state.step][state.type] = { ...value }

    if (state.stepValue == state.values.length - 1) {
      state.values.push(state.valueState)
      state.stepValue = state.stepValue + 1
    } else {
      if (state.stepValue == 0) {
        state.stepValue = state.stepValue + 1
        state.values.splice(state.stepValue, 0, state.valueState)
      } else {
        state.values.splice(state.stepValue, 0, state.valueState)
        state.stepValue = state.stepValue
      }
    }
  }),
  setDevice: action((state, value) => {
    state.device = value
  }),
  setSingleElement: action((state, { step, element, key, value }) => {
    if (state.current_step == 3) {
      state.step = 'email'
      state.type = 'emailData'
    }
    state.valueState[state.step][state.type][key] = value

    if (state.stepValue == state.values.length - 1) {
      state.values.push(state.valueState)
      state.stepValue = state.stepValue + 1
    } else {
      if (state.stepValue == 0) {
        state.stepValue = state.stepValue + 1
        state.values.splice(state.stepValue, 0, state.valueState)
      } else {
        state.values.splice(state.stepValue, 0, state.valueState)
        state.stepValue = state.stepValue
      }
    }
  }),
}
