import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import moment from 'moment'

import useFilters from '../../Components/FiltersHeader/useFilters'
import { getCampaignFilters } from 'Services/campaigns'
import { DATE_FORMAT } from '../../constants'
import CampaignListView from './index.view'

export type Filters = {
  channel: string
  placement: string
  from_date: string
  to_date: string
  query: string
  page: number
  per_page?: number
  order_by?: string
}

const CampaignList = () => {
  const history = useHistory()
  const queryObject = queryString.parse(history.location.search)
  const { filters: defaultFilters } = useFilters()

  const [filters, setFilters] = useState<Filters>({
    ...defaultFilters,
    channel: queryObject?.channel?.toString() || '[]',
    placement: queryObject?.placement?.toString() || '[]',
    query: queryObject?.query?.toString() || '',
    page: parseInt(queryObject?.page?.toString() || '1'),
    per_page: parseInt(queryObject?.per_page?.toString() || '20'),
  })
  const [channelList, setChannelList] = useState<{ value: string; label: string }[]>([])
  const [placementList, setPlacementList] = useState<{ value: string; label: string }[]>([])

  const handleEdit = async (record) => {
    history.push(`/campaigns/${record.id}`)
  }

  const handleStatistics = async (record) => {
    if (record.placement === 'Hrs Store Farming') {
      window.open(`/store-farming?inner_tab=overview&outer_tab=analytic`, '_self')
      return
    }
    window.open(`/analytics/campaign360/${record.id}?${history.location.search}`, '_self')
  }

  const handleTableChange = (tablePagination, _, sorter) => {
    setFilters({
      ...filters,
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.order
        ? sorter.order === 'ascend'
          ? sorter.field
          : `-${sorter.field}`
        : null,
    })
  }

  useEffect(() => {
    const handleFilterList = async () => {
      try {
        const resp = await getCampaignFilters()
        setChannelList(
          resp.data.channels.map((channel) => {
            delete Object.assign(channel, { value: channel.code })['code']
            delete Object.assign(channel, { label: channel.name })['name']
            return channel
          })
        )
        setPlacementList(
          resp.data.placements.map((plm) => {
            delete Object.assign(plm, { value: plm.code })['code']
            delete Object.assign(plm, { label: plm.name })['name']
            return plm
          })
        )
      } catch (err) {}
    }
    handleFilterList()
  }, [])

  return (
    <div className="campaignList" style={{ minHeight: 500 }}>
      <CampaignListView
        channelList={channelList}
        placementList={placementList}
        setFilters={setFilters}
        filters={filters}
        handleTableChange={handleTableChange}
        handleEdit={handleEdit}
        handleStatistics={handleStatistics}
      />
    </div>
  )
}

export default CampaignList
