import React, { useContext } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { Dimension } from 'DimensionsFiltersTypes'
import { message } from 'antd'

import Label from './DimensionInterface.Label'
import Condition from './DimensionInterface.Condition'

import { dimensionPopupContext } from '../Dimensions.Popup'
import { criteriaDraftToDimensions } from '../helpers'

export type props = {
  isEditable?: boolean
  value?: Dimension[]
  emptyText?: React.ReactNode | string
  syncedConditions?: boolean
}

const DimensionInterface: React.FC<props> = ({
  isEditable = true,
  value = [],
  emptyText,
  syncedConditions,
}) => {
  const { dimensionsDraft, setDimensionsDraft, editingDimension, setEditingDimension } =
    useContext(dimensionPopupContext)

  // const dimensions = isEditable ? criteriaDraftToDimensions(criteriaDraft) : value
  const dimensions = isEditable ? dimensionsDraft : value
  //const dimensions = dimensionsDraft
  // console.log('dimension draft:', dimensionsDraft)

  //console.log('testing: ', criteriaDraftToDimensions(criteriaDraft))

  const shouldDisableCondition = (index) => {
    return syncedConditions && index > 1
  }

  const onItemClick = (index) => {
    setEditingDimension({ ...dimensions[index], index })
  }

  const onRemoveDimension = (index) => {
    if (editingDimension?.index === index) message.warning('Can not remove editing Criteria!')
    else setDimensionsDraft({ type: 'remove', payload: { index } })
  }

  if (!dimensions.length) return <span className="py-1">{emptyText || 'No dimensions added'}</span>

  return (
    <>
      {dimensions.map((dim, index) => {
        return (
          <div className="DimensionItemComponent flex items-center cursor-pointer" key={index}>
            {index ? (
              <Condition
                className="mr-2"
                index={index}
                condition={
                  dim.segments[0]?.type == 'SEGMENT'
                    ? dim?.segments[0]?.condition
                    : shouldDisableCondition(index)
                    ? dimensions[1]?.condition
                    : dim?.condition
                }
                isEditable={isEditable}
                isDisabled={shouldDisableCondition(index)}
                type={dim.segments[0].type}
              />
            ) : null}

            <div
              onClick={() => onItemClick(index)}
              className="bg-background_opaque py-1 px-2 rounded flex justify-center items-center"
            >
              <Label segment={dim.segments[0]} />

              {isEditable && (
                <CloseOutlined
                  onClick={(e) => {
                    e.stopPropagation()
                    onRemoveDimension(index)
                  }}
                  className="ml-2 cursor-pointer text-xs text-disabled_text"
                />
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}

export default DimensionInterface
