import React from 'react'
import { Typography, Tooltip } from 'antd'
import moment from 'moment'
import { numberWithCommas } from 'Utils'

export const COLUMNS = [
  {
    title: 'Tiêu đề',
    dataIndex: 'title',
    align: 'left',
  },
  {
    title: 'Link',
    dataIndex: 'shorten_link',
    align: 'left',
    render: (text) => (
      <a href={text} target="_self">
        {text}
      </a>
    ),
  },
  {
    title: 'Phân loại nội dung',
    dataIndex: 'category',
    align: 'left',
  },
  {
    title: 'Nội dung post',
    dataIndex: 'content',
    align: 'left',
  },
  {
    title: 'Clicks',
    dataIndex: 'clicks',
    sorter: true,
    align: 'left',
  },
  {
    title: 'Doanh thu',
    dataIndex: 'revenue',
    sorter: true,
    align: 'left',
    render: (text) => numberWithCommas(text),
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'created_at',
    sorter: true,
    render: (text) => moment(text).format('DD-MM-YYYY'),
    width: 150,
  },
]

export const TABS: { label: string; value: string }[] = [
  { label: 'Links', value: 'links' },
  { label: 'Personal Performance', value: 'performance' },
  { label: 'Dashboard', value: 'dashboard' },
]

export const IS_ACTIVE = [
  { label: 'New Collection', value: 'NewCollection' },
  { label: 'Sale', value: 'Sale' },
  { label: 'Full Price', value: 'FullPrice' },
]
