import React from 'react'
import { Tabs, Card } from 'antd'
import EmailTabs from 'Components/CJChannels/Email'
const { TabPane } = Tabs

const Email = ({ props, setSingleElement, editElementComp, values }) => {
  function callback(key) {
    console.log(key)
  }
  return (
    <div>
      <Card style={{ background: '#E5E5E5' }}>
        <EmailTabs
          {...props}
          withModal={false}
          values={values?.emailData}
          // onSubmit={handleEmailSubmit}
          setSingleElement={setSingleElement}
          editElementComp={editElementComp}
          saveVisible={true}
          showPreview
        />
      </Card>
    </div>
  )
}

export default Email
