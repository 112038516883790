import React, { useEffect, useState } from 'react'
import './index.scss'
import {
  Modal,
  Card,
  Button,
  Tabs,
  Input,
  List,
  Typography,
  Layout,
  Menu,
  Select,
  Alert,
} from 'antd'
import { useStoreActions } from 'easy-peasy'
import axios from 'Utils/axios'
import { isEmpty } from 'lodash'
import { hexToRgbA } from 'Utils'
import { fontWeight } from 'DesignSystem'
const { Title } = Typography
const { Option } = Select
interface coupon {
  title: string
  code: string
}
const EditWheelModal = ({ isModalVisible, handleCancel, handleOk, wheels, numberOptions }) => {
  const { setNumberOptionWheel, setElement } = useStoreActions((actions: any) => actions.popup)
  const [coupons, setCoupons] = useState([])
  const [errors, setErrors] = useState({})
  useEffect(() => {
    const getCoupons = async () => {
      const resp = await axios.get(`/utils/minigame/coupons`)
      setCoupons(resp.data)
    }
    getCoupons()
  }, [])
  function handleSelectCoupon(value, index) {
    setElement({
      step: 'mainPopup',
      element: 'wheel',
      key: 'wheels',
      item: 'code',
      value: value,
      index: index,
    })
    setErrors({})
  }

  function onSearch(val) {
    setNumberOptionWheel(val)
  }

  const handleOnChange = (e, index) => {
    setElement({
      step: 'mainPopup',
      element: 'wheel',
      key: 'wheels',
      item: 'title',
      value: e.target.value,
      index: index,
    })
  }

  const handleOnChangeRate = (e, index) => {
    setElement({
      step: 'mainPopup',
      element: 'wheel',
      key: 'wheels',
      item: 'rate',
      value: e.target.value,
      index: index,
    })
  }
  const checkRateLimit = (wheels) => {
    const rate = wheels.reduce((accum, item) => {
      return accum + parseFloat(item.rate)
    }, 0)
    if (rate == 100) {
      return true
    }
    return false
  }

  const _handleOk = () => {
    const errs = wheels.reduce((accum, item, index) => {
      const hasTitle = !!item.title
      const hasCoupon = !!item.code
      if (hasTitle && hasCoupon) return accum
      accum[index] = [!!item.title, !!item.code]
      return accum
    }, {})
    setErrors(errs)
    if (!checkRateLimit(wheels)) return

    setTimeout(() => {
      if (isEmpty(errs)) {
        handleOk()
      }
    }, 300)
  }

  const renderErrorMessage = (index, fieldTypeIndex) => {
    const err = errors[index]
    if (!err) return
    const er = err[fieldTypeIndex]
    if (er) return
    return <Alert type="error" message="this field can't be blank"></Alert>
  }

  return (
    <>
      <Modal
        style={{ top: 100 }}
        className="WheelModal"
        width={620}
        visible={isModalVisible}
        closable={false}
        footer={null}
        wrapClassName="modal-Wheel"
        // onCancel={handleCancel}
      >
        <div className="title--wheel">
          <div
            style={{ paddingLeft: '24px', paddingRight: '24px', paddingBottom: '19px' }}
            className="border-b flex justify-between items-center "
          >
            <Title className="pt-2" style={{ fontSize: 18, fontWeight: 600 }}>
              Edit Wheel
            </Title>
            <div className="flex">
              <div className="mr-2">
                <Button
                  style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 600 }}
                  onClick={() => handleCancel()}
                  className="px-5"
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  style={{ color: '#FFFFFF', fontWeight: 600 }}
                  onClick={() => _handleOk()}
                  className="px-1"
                  type="primary"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-6 mt-2">
          <div className="flex items-center">
            <div className="mr-4">
              <Title style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: 14 }} level={5}>
                Number Options
              </Title>
            </div>
            <div>
              <Select value={numberOptions} style={{ width: 120 }} onChange={onSearch}>
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
                <Option value="6">6</Option>
              </Select>
            </div>
          </div>
          <div className="mt-8">
            <div className="flex">
              <div className="flex-1" style={{ color: 'rgba(0,0,0,0.6)', fontWeight: 600 }}>
                Wheel Item Title
              </div>
              <div className="flex-1 ml-4" style={{ color: 'rgba(0,0,0,0.6)', fontWeight: 600 }}>
                Coupon code
              </div>
              <div className="flex-1 ml-4" style={{ color: 'rgba(0,0,0,0.6)', fontWeight: 600 }}>
                Chance to win
              </div>
            </div>
            <div>
              {wheels?.map((item, index) => (
                <div key={index} className="mt-2">
                  <div className="flex">
                    <div className="flex-1 mr-4">
                      <Input
                        style={{ color: 'rgba(0, 0, 0, 0.85)' }}
                        value={item.title}
                        onChange={(e) => handleOnChange(e, index)}
                      />
                      {renderErrorMessage(index, 0)}
                    </div>
                    <div className="flex-1 mr-4" style={{ color: 'rgba(0,0,0,0.45)' }}>
                      <Select
                        value={item.code ? item.code : undefined}
                        className="w-full"
                        showSearch
                        style={{ width: 200, color: 'rgba(0, 0, 0, 0.85)' }}
                        placeholder="Select"
                        onChange={(value) => handleSelectCoupon(value, index)}
                      >
                        {coupons.map((item: coupon, i) => (
                          <>
                            <Option key={i} value={item.code}>
                              {item.title}
                            </Option>
                          </>
                        ))}
                      </Select>
                      {renderErrorMessage(index, 1)}
                    </div>
                    <div className="flex-1">
                      <Input
                        onChange={(e) => handleOnChangeRate(e, index)}
                        value={item.rate}
                        addonAfter={'%'}
                        style={{ borderColor: `red` }}
                        className={`${!checkRateLimit(wheels) && 'border_error'}`}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {!checkRateLimit(wheels) && (
          <div className="mt-4" style={{ color: 'var(--negative)' }}>
            Chance to win must be 100% in total
          </div>
        )}
      </Modal>
    </>
  )
}

export default EditWheelModal
