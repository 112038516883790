import React, { useState, useEffect } from 'react'

import RankingCard from 'Components/RankingCard/index'
import axios from 'Utils/axios'
import { message } from 'antd'
import { formatErrors } from 'Utils'

export type LeaderBoardType = {
  dateFilter: any
}

const LeaderBoards: React.FC<LeaderBoardType> = ({ dateFilter }) => {
  const [leaderBoard, setLeaderBoard] = useState<any>(null)
  useEffect(() => {
    setLeaderBoard(null)
    let fromDate = dateFilter[0].format('YYYY-MM-DD')
    let toDate = dateFilter[1].format('YYYY-MM-DD')
    ;(async () => {
      try {
        const params = `from_date=${fromDate}&to_date=${toDate}&company=${process.env.REACT_APP_COMPANY_NAME}&force=true&limit=12`
        const resp = await axios.get(`/social-selling/personal-performance/leaderboard?${params}`)
        setLeaderBoard(resp.data)
      } catch (error: any) {
        setLeaderBoard({ revenue: { values: [] }, performance: { values: [] } })
        message.error(formatErrors(error?.response?.data))
      }
    })()
  }, [dateFilter])

  return (
    <div className="LeaderBoards mt-4">
      <RankingCard
        list={(leaderBoard as any)?.revenue?.values}
        title={(leaderBoard as any)?.revenue?.title}
        subtitle={(leaderBoard as any)?.revenue?.subtitle}
        type={(leaderBoard as any)?.revenue?.type}
        currentRank={(leaderBoard as any)?.revenue?.current_rank}
      />
      <RankingCard
        list={(leaderBoard as any)?.performance?.values}
        title={(leaderBoard as any)?.performance?.title}
        subtitle={(leaderBoard as any)?.performance?.subtitle}
        type={(leaderBoard as any)?.performance?.type}
        currentRank={(leaderBoard as any)?.performance?.current_rank}
      />
    </div>
  )
}

export default LeaderBoards
