import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { WHEEL } from '../../constants'
import Winwheel from 'winwheel'
const WheelPopup = ({ chooseElement, img, initialValues }) => {
  const { type } = useStoreState((state) => state.popup)
  const { mainPopup } = initialValues
  const { imgArrow, wheel } = mainPopup
  const convertText = (text) => {
    if (text.length > 15) {
      if (text.length > 30) {
        return (
          text.substring(0, 15) +
          '\n' +
          text.substring(15, 30) +
          '\n' +
          text.substring(30, text.length)
        )
      }
      return text.substring(0, 15) + '\n' + text.substring(15, text.length)
    }
    return text
  }

  const DEFAULT_WHEEL_DATA = {
    canvasId: 'kariba_popup_canvas',
    numSegments: wheel?.wheels.length, // Specify number of segments.
    outerRadius: 212, // Set radius to so wheel fits the background.
    innerRadius: 20, // Set inner radius to make wheel hollow.
    textFontSize: 18, // Set font size accordingly.
    textMargin: 0, // Take out default margin.
    lineWidth: 0,
    textFillStyle: wheel.textColor,
    textLineWidth: 600,

    // Define segments including colour and text.
    // segments: [
    //   {
    //     fillStyle: '#eae56f',
    //     text: '1234567891234567890'.length,
    //     code: 'xxx',
    //     strokeStyle: '#ffffff',
    //   },
    //   {
    //     fillStyle: '#89f26e',
    //     text: 'Prize 2',
    //     code: 'xxx',
    //     strokeStyle: '#ffffff',
    //   },
    //   {
    //     fillStyle: '#7de6ef',
    //     text: 'Prize 3',
    //     code: 'xxx',
    //     strokeStyle: '#ffffff',
    //   },
    // ],
    segments: wheel.wheels.map((item, index) => {
      return {
        fillStyle: item.color,
        text: convertText(item.title),
        code: item.code,
        rate: item.rate,
        strokeStyle: '#ffffff',
      }
    }),
    // Define spin to stop animation.
    // animation: {
    //   type: 'spinToStop',
    //   duration: 5,
    //   spins: 8,
    //   callbackFinished: () => {},
    // },
  }
  new Winwheel(DEFAULT_WHEEL_DATA)

  const renderWheel = () => {
    var percentageElement = 360 / wheel.wheels.length
    var startPercentage = -percentageElement
    return (
      <div className="">
        <div className="PizzaContainer">
          <div className="PizzaContainer__triangle"></div>
          <div className="PizzaContainer__Shadow"></div>
          <div className={`PizzaBackground ${type == 'wheel' && `border-active-element`}`}></div>
          {wheel.wheels.map((item, index) => (
            <div
              key={index}
              id={WHEEL[index]}
              className="hold"
              style={{
                transform: `rotate(${(startPercentage += percentageElement)}deg)`,
              }}
            >
              <div
                style={{
                  backgroundColor: `${item.color}`,
                  transform: `rotate(${360 / wheel.wheels.length}deg)`,
                }}
                className="Pizza"
              >
                <div
                  style={{
                    color: `${wheel.textColor}`,
                    fontWeight: 600,
                    transform: `rotate(${360 / wheel.wheels.length}deg)`,
                  }}
                >
                  {item.title}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
  return (
    <>
      <div
        className={`${type == 'wheel' && `border-active-element`}`}
        id="kariba_wheel_container"
        onClick={(e) => {
          e.stopPropagation()
          chooseElement({ step: 'mainPopup', element: 'Wheel', type: 'wheel' })
        }}
      >
        <div className="kariba-background-plate">
          <span className="kariba-arrow-down"></span>
          <canvas
            id="kariba_popup_canvas"
            width="440"
            height="440"
            style={{ width: 300, height: 300 }}
          ></canvas>
        </div>
      </div>

      {/* <div>
        <div
          onClick={(e) => {
            e.stopPropagation()
            chooseElement({ step: 'mainPopup', element: 'Wheel', type: 'wheel' })
          }}
          className="WheelContent__left-img cursor-pointer "
        >
          {renderWheel()}
        </div>
      </div> */}
      <div className={`flex justify-end `}>
        <img
          style={{ height: 120, width: 110 }}
          onClick={(e) => {
            e.stopPropagation()
            chooseElement({ step: 'mainPopup', element: 'Image', type: 'imgArrow' })
          }}
          className={`WheelContent__left-arrow absolute cursor-pointer ${
            type == 'imgArrow' && `border-active-element`
          }`}
          src={imgArrow?.url}
          alt=""
        />
      </div>
    </>
  )
}

export default WheelPopup
