import React from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'

import backgroundImg from '../img/Background.png'
import Wheel from '../img/Wheel.png'
import WheelPopup from './SubComponentPopup/WheelPopup'
import RightEdit from './SubComponentPopup/RightEdit'

const MainPopup = ({ children }) => {
  const { type, device } = useStoreState((state) => state.popup)
  return (
    <div style={{ backgroundColor: 'rgb(245, 245, 245)' }} className="MainPopupStepsContent h-full">
      <div className="MainPopupStep ">
        <div className="MainPopupStepContent flex">
          <div
            style={{
              width: `${device == 'mobile' ? '332px' : '1012px'} `,
              height: '538px',
              backgroundColor: 'white',
              justifyContent: `${device == 'mobile' ? 'center' : ''} `,
              alignItems: `${device == 'mobile' ? 'center' : ''} `,
            }}
            className="MainPopupEdit m-auto flex "
          >
            <div
              style={{
                width: `${device == 'mobile' ? '302px' : '1012px'} `,
                height: '538px',
                display: 'contents',
              }}
              className="container"
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainPopup
