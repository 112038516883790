import React from 'react'
import gift from '../../img/gifts.png'
import { useStoreActions, useStoreState } from 'easy-peasy'

function ChooseGift({ chooseElement, img, initialValues }) {
  const { type } = useStoreState((state) => state.randomvoucher)
  const { mainPopup } = initialValues
  const { gift } = mainPopup
  const { gifts } = gift
  return (
    <>
      {/* <div>
        <img
          onClick={(e) => {
            e.stopPropagation()
            chooseElement({ step: 'mainPopup', element: 'Gifts', type: 'Gifts' })
          }}
          className="WheelContent__left-img cursor-pointer"
          alt=""
        />
      </div> */}
      <div className="text-center">
        <div
          className={`flex ${type == 'gift' && `border-active-element`}`}
          style={{ width: 262, justifyContent: 'space-evenly', marginLeft: 36, marginTop: 24 }}
        >
          {gifts.map((item, index) => (
            <img
              key={index}
              onClick={(e) => {
                e.stopPropagation()
                chooseElement({ step: 'mainPopup', element: 'Gifts', type: 'gift' })
              }}
              className="WheelContent__left-img cursor-pointer"
              src={img}
              alt=""
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default ChooseGift
