import React from 'react'
import gift from '../../img/giftimg.png'
import { CloseOutlined } from '@ant-design/icons'
import ElementPopup from './ElementPopup'
import TitlePopup from './TitlePopup'
import InputPopup from './InputPopup'
import ButtonPopup from './ButtonPopup'
import logo from '../../img/logo.png'
import { useStoreActions, useStoreState } from 'easy-peasy'
import ChooseGift from './ChooseGift'

function GiftsPopup({ changeType, img, initialValues }) {
  const { type } = useStoreState((state) => state.randomvoucher)
  const { mainPopup } = initialValues
  const { text, text_1, text_2, input, imgGift } = mainPopup

  const fontStyleClass = (fontStyle) => {
    if (fontStyle == 'bold') {
      return 'font-semibold'
    }
    if (fontStyle == 'underline') {
      return 'underline'
    }
    if (fontStyle == 'italic') {
      return 'italic'
    }
  }

  const renderImg = (content) => {
    return !content ? img : content
  }
  return (
    <>
      <CloseOutlined className="flex justify-end px-4 pt-4" />
      <div className="flex justify-center mt-4">
        <div>
          <ElementPopup img={imgGift.url} changeType={changeType} />
        </div>
      </div>
      <div className="WheelContent__title flex justify-center mt-2">
        <TitlePopup textContent={text} changeType={changeType} />
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation()
          changeType({ step: 'mainPopup', element: 'Text', type: 'text_1' })
        }}
        className={`flex justify-center cursor-pointer ${fontStyleClass(text_1.style.fontStyle)}
        }`}
      >
        <div
          style={{ width: 268, ...text_1.style }}
          className={`${type == 'text_1' && 'border-active-element'}`}
        >
          {text_1.content == '' ? 'Text' : text_1.content}
        </div>
      </div>
      <div className="WheelContent__infor flex justify-center mt-2 text-center">
        <div className="WheelContent__Email ">
          <InputPopup textContent={input} textEmailValidate={text_2} changeType={changeType} />
        </div>
      </div>
    </>
  )
}

export default GiftsPopup
