import React, { useEffect, useState } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { Select } from 'antd'
import './index.scss'

import { formatErrors } from 'Utils'
import { getPredictiveSegments } from 'Services/dimensions'

export default function PredictiveSegments() {
  const { predictive_segment, predefined_segment } = useStoreState(
    (state) => state.customerSegments
  )
  const { setState } = useStoreActions((action: any) => action.customerSegments)
  const [data, setData] = useState<{ code: string; name: string; type: string }[]>([])
  const [errors, setErrors] = useState('')

  const fetchData = async () => {
    try {
      const resp = await getPredictiveSegments()
      setErrors('')
      setData(resp.data)
    } catch (err: any) {
      setErrors(formatErrors(err.response?.data) || 'Something went wrong')
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="Predictive-segment-wrapper">
      <Select
        allowClear
        value={predictive_segment || undefined}
        placeholder="Select"
        disabled={predefined_segment}
        onChange={(value) => {
          setState({ key: 'predictive_segment', value })
          setState({ key: 'need_insights_refresh', value: true })
        }}
        className={`w-full ${errors && 'errors'}`}
      >
        {data.map((segment) => (
          <Select.Option key={segment.code} value={segment.code}>
            {segment.name}
          </Select.Option>
        ))}
      </Select>
      {errors && <div className="text-negative">{errors}</div>}
    </div>
  )
}
