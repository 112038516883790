import React from 'react'
import { ProductItem as ProductItemType } from 'ProductListingTypes'
import { Empty, Skeleton, Tooltip } from 'antd'
import { formatNum, thousandsSeparator } from 'Utils/kFormatter'
import { ReactComponent as Redirect } from 'Assets/images/redirect.svg'
import './index.scss'

const ProductItem = ({ product }: { product: ProductItemType }) => {
  return (
    <div className="ProductItem-wrapper p-3 flex flex-col">
      {product?.product_name == 'loading' ? (
        <Skeleton active />
      ) : (
        <>
          <div
            className="productItem-product-img-wrapper"
            onMouseEnter={(item) => {
              var test: any = document?.querySelector(
                `.ProductListing-redirectIcon-${product?.barcode?.toString().replace(/\s+/g, '')}`
              )
              test.style.display = 'block'
            }}
            onMouseLeave={(item) => {
              var test: any = document?.querySelector(
                `.ProductListing-redirectIcon-${product?.barcode?.toString().replace(/\s+/g, '')}`
              )
              test.style.display = 'none'
            }}
          >
            <>
              {product.image.split(':')[0] === 'https' && product.image ? (
                <img className="productItem-product-img" src={product.image} alt="product item" />
              ) : (
                <Empty className="productItem-product-img" />
              )}
              <div className="productItem-aboveImage">
                <div className="productItem-ranking">#{product.ranking}</div>

                <div
                  className={`ProductListing-redirectIcon ProductListing-redirectIcon-${product?.barcode
                    ?.toString()
                    .replace(/\s+/g, '')} cursor-pointer`}
                  style={{ display: 'none' }}
                >
                  <Redirect
                    onClick={(e) => {
                      e.stopPropagation()
                      window.open(product.url, '_self')
                    }}
                  ></Redirect>
                </div>
              </div>
            </>
          </div>
          <a
            href={`/analytics/product360/${product.barcode}`}
            target="_self"
            className="font-semibold py-1 text-primary_text hover:underline"
            style={{ minHeight: '47px' }}
          >
            {/* <div className="productItem-product-title">{product.product_name}</div> */}
            <Tooltip title={product.product_name} color="#000" placement="top">
              <div className="line-clamp-2 light-text productItem-product-title">
                {product.product_name}
              </div>
            </Tooltip>
          </a>
          <div className="flex flex-row justify-between hightlight-background ">
            <div className="light-text">Sold item</div>
            <div>{thousandsSeparator(parseInt(product.sold_item || ''))}</div>
          </div>
          <div className="flex flex-row justify-between py-1">
            <div className="light-text">Gross margin</div>
            <div>{formatNum(product.gross_margin)} %</div>
          </div>
          <div className="flex flex-row justify-between hightlight-background">
            <div className="light-text">Customers</div>
            <div>{thousandsSeparator(parseInt(product.customers || ''))}</div>
          </div>
          <div className="flex flex-row justify-between py-1">
            <Tooltip title="Returned Customer" color="#000" placement="top">
              <div className="truncate light-text" style={{ width: 102 }}>
                Returned Customer
              </div>
            </Tooltip>
            <div>{thousandsSeparator(parseInt(product.returned_customers || ''))}</div>
          </div>
          <div className="flex flex-row justify-between hightlight-background">
            <div className="light-text">Revenue</div>
            <div>{thousandsSeparator(parseInt(product.revenue || ''))} đ</div>
          </div>
          <div className="flex flex-row justify-between py-1">
            <div className="light-text">% Total Rev</div>
            <div>{parseFloat(product.ratio_total_revenue).toFixed(6)} %</div>
          </div>
          <div className="flex flex-row justify-between hightlight-background">
            <div className="light-text">DRR</div>
            <div>{formatNum(product.drr)}</div>
          </div>
          <div className="flex flex-row justify-between py-1">
            <div className="light-text">Inventory</div>
            <div>{thousandsSeparator(parseInt(product.inventory || ''))}</div>
          </div>
          <div className="flex flex-row justify-between hightlight-background">
            <div className="light-text">Day to clear</div>
            <div>{product.days_to_clear}</div>
          </div>
          <div className="flex flex-row justify-between pt-1">
            <div className="light-text">Pageview</div>
            <div>{product.pageview}</div>
          </div>
        </>
      )}
    </div>
  )
}

export default ProductItem
