import React, { useState } from 'react'
import { Select } from 'antd'
import { EditableInput } from 'react-color/lib/components/common'
import ImageField from './Image.tsx'
import '../../index.scss'
import { useStoreActions, useStoreState } from 'easy-peasy'

function ImagePicker(props) {
  const { previewBackground, editElementComp, values } = props
  const [opacityValue, setOpacityValue] = useState(100)
  const { Option } = Select

  console.log('preview new ', previewBackground)

  const handleImage = (name, value) => {
    const updateValue = {
      ...values,
      url: value[value?.length - 1],
      color: '',
      opacity: 1,
    }
    value[value?.length - 1] && editElementComp(updateValue)
  }
  const handleChange = (value) => {
    const updateValue = {
      ...values,
      backgroundSize: value,
    }
    editElementComp(updateValue)
  }
  const convertOpacity = (value) => {
    if (value.Opacity < 0) {
      const updateValue = {
        ...values,
        opacity: 0,
      }
      editElementComp(updateValue)
    } else if (value.Opacity > 100) {
      const updateValue = {
        ...values,
        opacity: 100,
      }
      editElementComp(updateValue)
    } else {
      const updateValue = {
        ...values,
        opacity: value.Opacity / 100,
      }
      editElementComp(updateValue)
    }
  }

  return (
    <div>
      <div
        className="image-wrapper"
        style={{
          backgroundImage: `url(${previewBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: `${values?.backgroundSize}`,
          opacity: `${values?.opacity}`,
        }}
      >
        <ImageField
          imageCount={4}
          onChange={(images) => {
            handleImage('image', images)
          }}
          value={[]}
        ></ImageField>
      </div>
      <div className="input-field">
        <div className="input-field-left">
          <label>Position</label>
          <Select
            className="input-field-left-select"
            style={{ width: '232px', height: '40px', marginTop: '8px' }}
            defaultValue="Cover"
            optionFilterProp="children"
            onChange={handleChange}
          >
            <Option value="cover">Cover</Option>
            <Option value="contain">Contain</Option>
          </Select>
        </div>
        <div className="input-field-right mr-2">
          <EditableInput
            label="Opacity"
            value={(values?.opacity || 1) * 100}
            //arrowOffset={1}
            onChange={convertOpacity}
          />
        </div>
      </div>
    </div>
  )
}

export default ImagePicker
