import React, { useState } from 'react'
import { message, Tooltip } from 'antd'
import { useHistory } from 'react-router-dom'
import { useStoreActions } from 'easy-peasy'
import {
  BarChartOutlined,
  EditOutlined,
  DeleteOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
} from '@ant-design/icons'

import { CAN_START_STATUS, CAN_PAUSE_STATUS } from 'Utils/constants'
import { formatErrors } from 'Utils'
import Table from 'Components/Table2.0'
import { RecordType } from 'Components/Table2.0/index.d'
import { COLUMNS } from './columns'

const ENDPOINT_CUSTOMER_JOURNEY = '/customer-journeys'

export default React.memo(() => {
  const history = useHistory()

  const { deleteCJ, resetState, setCJActions, getCJ } = useStoreActions(
    (actions: any) => actions.customerJourney
  )
  const { getCJAnalytics } = useStoreActions((actions: any) => actions.CJAnalytics)

  const { setBreadCrumb } = useStoreActions((actions: any) => actions.breadcrumb)

  const [searchTerm, setSearchTerm] = useState('')
  const [filters, setFilters] = useState<any>({
    status: 'published',
  })

  React.useEffect(() => {
    setBreadCrumb([{ title: 'Customer Journeys', path: '/customer-journeys' }])
  }, [setBreadCrumb])

  const handlePlayPause = async (record) => {
    try {
      const resp = await setCJActions({
        id: record.id,
        action: ['running'].includes(record.status) ? 'pause' : 'start',
      })

      setFilters({ ...filters, isRefreshed: true })
    } catch (err: any) {
      message.error(err.response.data.detail)
    }
  }

  const handleDelete = async (record) => {
    try {
      await deleteCJ(record.id)
      message.success('Deleted customer journey')
    } catch (err: any) {
      message.error(formatErrors(err.errors))
      throw new Error(err)
    }
  }

  const handleCreate = () => {
    resetState()
    window.location.href = `${ENDPOINT_CUSTOMER_JOURNEY}/new`
  }

  const handleEdit = async (record) => {
    resetState()
    history.push(`${ENDPOINT_CUSTOMER_JOURNEY}/${record.id}`)
  }

  const handleStatistics = async (record) => {
    history.push(`${ENDPOINT_CUSTOMER_JOURNEY}/${record.id}/analytics`)
  }

  const handleTableChange = (tablePagination, _, sorter) => {
    setFilters({
      ...filters,
      per_page: tablePagination.pageSize,
      page: tablePagination.current,
      order_by: sorter.order
        ? sorter.order === 'ascend'
          ? sorter.field
          : `-${sorter.field}`
        : null,
    })
  }

  const renderPlayPauseBtn = (record: RecordType) => {
    if (![...CAN_START_STATUS, ...CAN_PAUSE_STATUS].includes(record.status))
      return <div className="mr-2" style={{ width: 32, height: 32 }}></div>

    return (
      <Tooltip title={CAN_START_STATUS.includes(record.status) ? 'Run' : 'Pause'}>
        <div
          style={{ width: 32, height: 32 }}
          className="flex mr-2 items-center justify-center text-gray_9 border border-border rounded cursor-pointer hover:text-brand_primary hover:border-brand_primary"
          onClick={() => handlePlayPause(record)}
        >
          {CAN_START_STATUS.includes(record.status) ? (
            <PlayCircleOutlined />
          ) : (
            <PauseCircleOutlined />
          )}
        </div>
      </Tooltip>
    )
  }

  return (
    <div id="CJListTable" className="w-full">
      <Table
        className="px-8 py-6"
        fetchFrom={{ url: '/customer-journeys/', key: 'results' }}
        search={{ keyword: searchTerm, onSearch: setSearchTerm }}
        title="Customer Journeys"
        columns={COLUMNS}
        dropdown={{
          fields: [],
          value: filters,
        }}
        defaultSort="-updated_at"
        handleTableChange={handleTableChange}
        rowActions={{
          onDoubleClick: handleEdit,
          actions: [
            {
              component: (record: RecordType) => renderPlayPauseBtn(record),
            },
            {
              icon: <BarChartOutlined />,
              attribute: { type: 'highlight', label: 'Performance' },
              onClick: handleStatistics,
            },
            { icon: <EditOutlined />, attribute: { label: 'Edit' }, onClick: handleEdit },
            {
              icon: <DeleteOutlined />,
              attribute: { label: 'Delete' },
              onClick: handleDelete,
            },
          ],
        }}
        primaryButton={{ title: 'New Customer Journey', onClick: handleCreate }}
      />
    </div>
  )
})
