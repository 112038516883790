import React from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'

import backgroundImg from '../img/Background.png'
import Wheel from '../img/Wheel.png'
import WheelPopup from './SubComponentPopup/WheelPopup'
import RightEdit from './SubComponentPopup/RightEdit'

const MainPopup = ({ children }) => {
  return (
    <div style={{ background: 'rgb(245,245,245)' }} className="MainPopupStepsContent h-full">
      <div className="MainPopupStep ">
        <div className="MainPopupStepContent flex">
          <div
            style={{ width: '1012px', height: '570px', backgroundColor: 'white' }}
            className="MainPopupEdit m-auto flex "
          >
            <div
              style={{
                width: '100%',
                height: '538px',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}
              className="container"
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainPopup
