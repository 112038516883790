import React, { useEffect, useState, useMemo } from 'react'
import queryString from 'query-string'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom'
import { Alert, Empty } from 'antd'
import moment from 'moment'

import { BarChartPlaceholder } from 'Components/Placeholders/Charts'
import ReactPlaceholder from 'react-placeholder'
import AreaChart from 'Components/Charts/AreaChart'
import useFilters from './FiltersHeader/useFilters'
import { MultiDoughnutChartType, FiltersType } from 'AnalyticsTypes'

const AreaChartWrapper = ({
  handleDataFunction,
  chartCode,
}: {
  handleDataFunction: (filters: FiltersType) => Promise<any>
  chartCode: string
}) => {
  const history = useHistory()
  const { id } = useParams()
  const { filters: defaultFilters } = useFilters()

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [chart, setChart] = useState<
    | {
        code: string
        labels: string[]
        data: MultiDoughnutChartType[]
      }
    | undefined
  >(undefined)
  const queryObject = queryString.parse(history.location.search)

  useEffect(() => {
    let cancelTokenSource = axios.CancelToken.source()
    const fetchData = async () => {
      setIsLoading(true)
      try {
        setError('')
        // setChart(CHART)
        const resp = await handleDataFunction({
          id,
          from_date: queryObject?.from_date?.toString() || defaultFilters?.from_date,
          to_date: queryObject?.to_date?.toString() || defaultFilters?.to_date,
          frequency: queryObject?.frequency?.toString() || defaultFilters?.frequency,
          type: chartCode,
          dimensions: JSON.parse(queryObject?.dimensions?.toString() || '[]'),
          cancelTokenSource,
        })

        const formattedData = resp?.data?.data?.data
          ? {
              ...resp.data.data,
              data: resp?.data?.data?.data?.map((item) => {
                const isValidDate = moment(item.label).isValid()
                return {
                  ...item,
                  label: isValidDate ? moment(item.label).format('MMM DD') : item.label,
                }
              }),
            }
          : undefined

        setChart(formattedData)
      } catch (err: any) {
        if (err.message === 'cancelled') return

        const errorMessage: string =
          typeof err?.response?.data?.detail === 'string'
            ? err?.response?.data?.detail
            : 'No data available'
        setError(errorMessage)
      }
      setIsLoading(false)
    }

    fetchData()
    return () => {
      cancelTokenSource.cancel('cancelled')
      // avoid race condition
    }
  }, [
    id,
    queryObject.from_date,
    queryObject.to_date,
    queryObject.frequency,
    queryObject.dimensions,
    chartCode,
    handleDataFunction,
  ])

  const renderChart = useMemo(() => {
    console.log(chart)
    if (!chart) return <Empty />
    return <div className="w-full h-full">{chart && <AreaChart fill stack data={chart} />}</div>
  }, [chart])

  if (error) return <Alert message={error} type="error" />
  // console.log(chart)

  return (
    <ReactPlaceholder
      className="w-full"
      showLoadingAnimation
      ready={!isLoading}
      customPlaceholder={<BarChartPlaceholder />}
    >
      {renderChart}
    </ReactPlaceholder>
  )
}

export default AreaChartWrapper
