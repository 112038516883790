import axios from 'Utils/axios'
import { CancelTokenSource } from 'axios'

const DIMENSION_ENDPOINT = '/audience-dimensions'

export async function getDimensions({ group, page }) {
  const resp = await axios.get(`${DIMENSION_ENDPOINT}/`, {
    params: {
      as_type: 'kariba',
      company: process.env.REACT_APP_COMPANY_NAME,
      group,
      page,
    },
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}
export async function getDimensionsSegment({ group, page, order_by }) {
  const resp = await axios.get(`${DIMENSION_ENDPOINT}/`, {
    params: {
      as_type: 'kariba',
      company: process.env.REACT_APP_COMPANY_NAME,
      group,
      page,
      order_by,
    },
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getPredictiveSegments() {
  const resp = await axios.get(`${DIMENSION_ENDPOINT}/predictive_segment`)

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function searchDimensions({ keyword, query, type }) {
  const resp = await axios.get(`${DIMENSION_ENDPOINT}/search`, {
    params: {
      as_type: type,
      query,
      keyword: keyword ? keyword : ' ',
    },
  })
  // error handling
  if (!resp.data) {
    throw resp
  }

  return resp.data
}

export async function getDimensionCounts({
  data,
  cancelTokenSource,
}: {
  data: { dimensions?: string | null; custom_sql?: string }
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.post(`${DIMENSION_ENDPOINT}/counter`, data, {
    cancelToken: cancelTokenSource?.token,
  })

  return resp?.data
}

export async function getAnalyticByDimension({ data, analytic_type, cancelTokenSource }) {
  const resp = await axios.post(
    `${DIMENSION_ENDPOINT}/analytics?analytic_type=${analytic_type}`,
    data,
    {
      cancelToken: cancelTokenSource.token,
    }
  )

  // error handling
  if (!resp.data) {
    throw resp
  }

  return resp.data
}
