import React, { useState, useEffect } from 'react'
import { ReactComponent as Close } from '../img/close-line.svg'
import axios from 'Utils/axios'

import {
  Modal,
  Card,
  Button,
  Tabs,
  Input,
  List,
  Typography,
  Layout,
  Menu,
  Steps,
  Select,
} from 'antd'

import {
  UnderlineOutlined,
  CloseOutlined,
  BoldOutlined,
  ItalicOutlined,
  AlignLeftOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
} from '@ant-design/icons'
import StylePopup from './SubComponentPopup/StylePopup'
import { useStoreActions, useStoreState } from 'easy-peasy'
interface coupon {
  title: string
  code: string
}
const { Option } = Select
const { Title } = Typography
const EditText = ({ toggle, editElement, values }) => {
  const { type } = useStoreState((state) => state.codedefault)

  const [coupons, setCoupons] = useState([])
  useEffect(() => {
    const getCoupons = async () => {
      const resp = await axios.get(`/utils/minigame/coupons`)
      console.log('rep', resp.data)
      setCoupons(resp.data)
    }
    getCoupons()
  }, [])

  const { content, style } = values

  const handleOnChange = (value) => {
    var item = Array<coupon>()
    item = coupons.filter((coupon: coupon) => coupon.code == value)
    const upDateValue = {
      ...values,
      content: item[0].title,
      code: item[0].code,
    }
    console.log(upDateValue)
    editElement(upDateValue)
  }

  const handleOnchangeTitle = (e) => {
    console.log(e.target.value)
    const upDateValue = {
      ...values,
      content: e.target.value,
    }
    console.log(upDateValue)
    editElement(upDateValue)
  }

  const renderCoupon = () => {
    return (
      <Select
        className="w-full"
        showSearch
        style={{ width: 200 }}
        placeholder="Select"
        onChange={(value) => handleOnChange(value)}
      >
        {coupons.map((item: coupon, i) => (
          <>
            <Option key={i} value={item.code}>
              {item.title}
            </Option>
          </>
        ))}
      </Select>
    )
  }

  const renderTitle = () => {
    return (
      <Input
        className="py-2 pl-4"
        onChange={(e) => handleOnchangeTitle(e)}
        style={{ color: '#000000' }}
        value={content}
      />
    )
  }

  return (
    <>
      <Card
        size="small"
        title={
          <Title style={{ padding: '8px 4px' }} level={5}>
            Text
          </Title>
        }
        extra={
          <Close
            onClick={() => toggle()}
            className="cursor-pointer"
            style={{ fontSize: '20px', color: '#00000' }}
          />
        }
        bodyStyle={{ padding: '16px' }}
      >
        <div className="EditPopupElement">
          <span>Content</span>
          <div className="w-full mt-2">
            {type == 'default_coupon' ? renderCoupon() : renderTitle()}
          </div>
        </div>
      </Card>
      <Card style={{ borderBottom: 0 }} bodyStyle={{ padding: '16px' }}>
        <div className="EditPopupItems">
          <StylePopup getElement={values} editElement={editElement} />
        </div>
      </Card>
    </>
  )
}

export default EditText
