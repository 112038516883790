import React, { useState } from 'react'
import { Card, Input, Typography } from 'antd'
import { ReactComponent as Close } from '../img/close-line.svg'

import { CloseOutlined } from '@ant-design/icons'
import StylePopup from './SubComponentPopup/StylePopup'

const { Title } = Typography
const EditText = ({ toggle, editElementComp, values }) => {
  const { content, style } = values

  const handleOnChange = (e) => {
    const upDateValue = {
      ...values,
      content: e.target.value,
    }
    editElementComp(upDateValue)
  }
  return (
    <>
      <Card
        size="small"
        title={
          <Title style={{ padding: '8px 4px' }} level={5}>
            Text
          </Title>
        }
        extra={
          <Close
            onClick={() => toggle()}
            className="cursor-pointer"
            style={{ fontSize: '20px', color: '#00000' }}
          />
        }
        bodyStyle={{ padding: '16px' }}
      >
        <div className="EditPopupElement">
          <span>Content</span>
          <div className="w-full mt-2">
            <Input
              className="py-2 pl-4"
              onChange={(e) => handleOnChange(e)}
              style={{ color: '#000000' }}
              value={content}
            />
          </div>
        </div>
      </Card>
      <Card style={{ borderBottom: 0 }} bodyStyle={{ padding: '16px' }}>
        <div className="EditPopupItems">
          <StylePopup getElement={values} editElementComp={editElementComp} />
        </div>
      </Card>
    </>
  )
}

export default EditText
