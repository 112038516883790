import React, { useState } from 'react'
import { Modal, Button, Tabs, Popover } from 'antd'
import '../index.scss'

import CustomPicker from '../SolidPicker/CustomPicker.js'
import ImagePicker from '../ImagePicker/ImagePicker'
import { colorScheme } from './../../../../../../Utils/constants'
function ColorPickerFull(props) {
  const { color, choiceColor, values } = props
  const url = values?.url
  const opacity = values?.opacity
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }
  const handleClose = () => {
    setIsModalVisible(false)
  }
  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const { TabPane } = Tabs

  function callback(key) {
    console.log(key)
  }

  const handleChangeComplete = (color) => {
    console.log(colorScheme)
    const { rgb } = color
    const { a } = rgb
    // setState({ background: color.hex })
    choiceColor({ key: 'color', value: color?.hex, opacity: a })
  }

  const handleChangeHexColor = (props) => {
    console.log(props, 'hex')

    choiceColor({ key: 'color', value: props, opacity: 1 })
  }
  const [visible, setVisible] = useState(false)

  const hide = () => {
    setVisible(false)
  }

  const handleVisibleChange = (visible) => {
    setVisible(visible)
  }
  return (
    <Popover
      content={
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab={<div className="solid-top-label">Solid</div>} key="1">
            <CustomPicker
              color={color}
              onChange={handleChangeComplete}
              changeHexColor={handleChangeHexColor}
            ></CustomPicker>
          </TabPane>
          <TabPane
            disabled={!props?.editElementComp && true}
            tab={<div className="image-top-label">Image</div>}
            key="3"
          >
            <ImagePicker
              previewBackground={url}
              editElementComp={props.editElementComp}
              values={values}
            ></ImagePicker>
          </TabPane>
        </Tabs>
      }
      // className="color-picker-wrapper"
      placement="left"
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      overlayClassName="color-picker-wrapper"
    >
      <button
        style={{
          backgroundImage: `url(${url && url})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: `${color && color}`,
          opacity: opacity,
        }}
        className="rc-color-picker-trigger rounded-full p-3 m-2 border"
        onClick={showModal}
      ></button>
    </Popover>
  )
}

export default ColorPickerFull
