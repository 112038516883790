import React, { useCallback } from 'react'
import checkIcon from 'Assets/images/NewIcons/check.png'
import unCheckIcon from 'Assets/images/NewIcons/unCheck.png'
import classNames from 'classnames'

const VALIDATE_FIELDS = [
  {
    code: 'non_accented',
    // regex: new RegExp('!/^[\u00C0-\u024F\u1E00-\u1EFF]*$/'),
    regex: /^[a-zA-Z0-9]+$/,
    title: 'Viết không dấu',
  },
  { code: 'non_spacing', regex: /^\S*$/, title: 'Không có khoảng trắng' },
  { code: 'char_and_num', regex: /^[a-zA-Z0-9]+$/, title: 'Chỉ gồm chữ và số' },
  {
    code: 'non_special_char',
    regex: /^[a-zA-Z0-9]+$/,
    title: 'Không bao gồm ký tự đặc biệt',
  },
  { code: 'length_limit', regex: /^[\s\S]{1,30}$/, title: 'Không quá 30 kí tự' },
]

const ValidateItem = ({ field, text, invalidCallBack }) => {
  const isChecked = field.regex.test(text)
  if (!isChecked) invalidCallBack(isChecked)
  return (
    <div className={classNames('validate-item', { checked: isChecked })}>
      {isChecked ? <img src={checkIcon}></img> : <img src={unCheckIcon}></img>}
      <span>{field.title}</span>
    </div>
  )
}

const ValidateLinkForm = ({ text, invalidCallBack }) => {
  const renderValidateItem = useCallback(() => {
    const fields = VALIDATE_FIELDS.map((f, i) => {
      return (
        <ValidateItem
          key={i}
          field={f}
          text={text}
          invalidCallBack={invalidCallBack}
        ></ValidateItem>
      )
    })
    return fields
  }, [text])
  return (
    <div className="ValidateLinkFormComponent">
      <p>Nội dung Post cần thỏa mãn các điều kiện:</p>
      <div>{renderValidateItem()}</div>
    </div>
  )
}

export default ValidateLinkForm
