import React, { useEffect, useState } from 'react'
import { Form, Input } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { isEmpty } from 'lodash'
import { CloseCircleOutlined } from '@ant-design/icons'

import { ProductType } from './ProductItem'
import { getActions } from 'Services/store-farming'
import { getAudienceSegment } from 'Services/customer-segments'
import Message from './Message'
import ImageField from '../Image'
import FormItem from '../FormItem'
import Stores from 'Pages/Audiences/Detail/Components/Stores'
import ProductField from './Product'

import './index.scss'

export type ContentType = {
  store_codes: string[]
  action_id: number | null
  products: ProductType[]
  link: string[]
  message: number | null // message_id
  image: string[]
  utm_source: string
  target: string
  utm_medium: string
  utm_campaign: string
}

const INIT_VALUE = {
  store_codes: [],
  action_id: null,
  products: [],
  link: [''],
  message: null, // message_id
  image: [],
  target: '',
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
}

export default function StoreFarmingForm() {
  const { content, audience_id } = useStoreState((state) => state.campaignModule)
  const { setState } = useStoreActions((action: any) => action.campaignModule)

  const [actions, setActions] = useState([])

  const onFormChange = (name, value) => {
    console.log(name, value)
    setState({ key: 'content', value: { ...content, [name]: value } })
  }

  const handleFetchData = async () => {
    try {
      const actions = await getActions()
      if (audience_id) {
        const audienceResp = await getAudienceSegment(audience_id)
        setState({
          key: 'content',
          value: {
            ...INIT_VALUE,
            ...content,
            store_codes: content?.store_codes?.length
              ? content?.store_codes
              : audienceResp?.data?.store_ids,
          },
        })
      }
      setActions(actions.data.map((action) => ({ code: action.id, name: action.name })))
    } catch (err: any) {}
  }

  // useEffect(() => {
  //   if (isEmpty(content)) setState({ key: 'content', value: INIT_VALUE })
  // }, [setState, content])

  useEffect(() => {
    handleFetchData()
  }, [])

  return (
    <Form
      className="sf-form"
      layout="vertical"
      name="ContentForm"
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 24 }}
    >
      <Form.Item key="store_codes" label="Assignment" hasFeedback>
        <Stores
          value={content.store_codes}
          onChange={(nodes) => {
            onFormChange(
              'store_codes',
              nodes.checkedNodes.filter((id) => id.key).map((childStore) => childStore.key)
            )
          }}
        />
      </Form.Item>

      <Form.Item key="action_id" label="Action" hasFeedback>
        <FormItem
          name="action_id"
          value={content.action_id}
          onChange={onFormChange}
          type="select"
          options={actions}
          placeholder={'Choose an action'}
        />
      </Form.Item>

      <Form.Item key="product" label="Product" hasFeedback>
        <ProductField
          onAddProduct={(product) => {
            onFormChange('products', [...content?.products, product])
          }}
          onRemoveProduct={(product) => {
            onFormChange(
              'products',
              content.products.filter((item) => item.ProductId !== product.ProductId)
            )
          }}
          value={content?.products || []}
        />
      </Form.Item>

      <Form.Item key="link" label="Link" hasFeedback>
        {content?.link?.map((item, index) => (
          <div key={index} className="mb-2 sf-link-field">
            <Input
              placeholder="Paste link"
              value={item}
              allowClear
              onChange={(e) => {
                const temp = content.link
                temp[index] = e.target.value
                onFormChange('link', temp)
              }}
            />
            <span
              className="cursor-pointer"
              onClick={() => {
                const temp = content.link
                temp.splice(index, 1)
                onFormChange('link', temp)
              }}
            >
              <CloseCircleOutlined />
            </span>
          </div>
        ))}
        <div
          onClick={() => {
            onFormChange('link', [...content.link, ''])
          }}
          className="add-more-btn"
        >
          + Add more link
        </div>
      </Form.Item>

      <Form.Item key="message" label="Message" hasFeedback>
        <Message value={content && content.message} onChange={onFormChange} />
      </Form.Item>

      <Form.Item key="image" label="Image">
        <ImageField
          imageCount={4}
          onChange={(images) => {
            onFormChange('image', images)
          }}
          value={content.image || []}
        />
      </Form.Item>

      {[
        { name: 'utm_source', label: 'UTM Source' },
        { name: 'utm_medium', label: 'UTM Medium' },
        { name: 'utm_campaign', label: 'UTM Campaign' },
      ].map((field) => (
        <Form.Item key={field.name} label={field.label}>
          <FormItem
            name={field.name}
            placeholder={`Input ${field.label}`}
            type={'string'}
            value={content && content[field.name]}
            onChange={onFormChange}
          />
        </Form.Item>
      ))}
    </Form>
  )
}
