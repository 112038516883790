import React from 'react'
import { ReactComponent as Close } from '../img/close-line.svg'
import { Modal, Card, Button, Tabs, Input, List, Typography } from 'antd'
import { EditOutlined, CloseOutlined } from '@ant-design/icons'
import StylePopup from './SubComponentPopup/StylePopup'
import ColorPickerFull from '../ColorPickerPopup/Modal/ColorPickerFull'
const { Title } = Typography
const EditButton = ({ toggle, editElementComp, values }) => {
  const buttonStyle = values?.buttonStyle
  const content = values?.content
  const style = values?.style

  const handleOnChange = (e) => {
    console.log(e.target.value)
    const upDateValue = {
      ...values,
      content: e.target.value,
    }
    editElementComp(upDateValue)
  }

  const handleOnClickChangeStyle = (props) => {
    const upDateValue = {
      ...values,
      style: {
        ...style,
        backgroundColor: props.value,
        opacity: props.opacity,
      },
    }

    editElementComp(upDateValue)
  }

  const handleOnChangeHover = (props) => {
    const upDateValue = {
      ...values,

      buttonStyle: {
        ...buttonStyle,
        hover: props.value,
        opacity: props.opacity,
      },
    }

    editElementComp(upDateValue)
  }

  return (
    <>
      <Card
        size="small"
        title={
          <Title style={{ padding: '8px 4px' }} level={5}>
            Button
          </Title>
        }
        extra={
          <Close
            onClick={() => toggle()}
            className="cursor-pointer"
            style={{ fontSize: '20px', color: '#00000' }}
          />
        }
        bodyStyle={{ padding: '16px' }}
      >
        <div className="EditPopupElement">
          <Title style={{ fontSize: 14 }} level={5}>
            Button Name
          </Title>
          <div className="w-full mt-2">
            <Input
              className="py-2 pl-4"
              onChange={handleOnChange}
              style={{ color: '#000000' }}
              defaultValue={content}
            />
          </div>
        </div>
      </Card>
      <Card bodyStyle={{ padding: '16px' }}>
        <div>
          <Title style={{ fontSize: 14 }} level={5}>
            Background
          </Title>
          <div className="flex justify-between items-center mt-2">
            <div>
              <span>Color</span>
            </div>
            <div>
              <div>
                <ColorPickerFull
                  color={style?.backgroundColor}
                  choiceColor={handleOnClickChangeStyle}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center mt-2">
            <div>
              <span>Hover color</span>
            </div>
            <div>
              <ColorPickerFull color={buttonStyle?.hover} choiceColor={handleOnChangeHover} />
            </div>
          </div>
        </div>
      </Card>
      <Card style={{ borderBottom: 0 }} bodyStyle={{ padding: '16px' }}>
        <Title style={{ fontSize: 14 }} level={5}>
          Text
        </Title>
        <div className="EditPopupItems mt-8">
          <StylePopup getElement={values} editElementComp={editElementComp} />
        </div>
      </Card>
    </>
  )
}

export default EditButton
