import React from 'react'
import reactCSS from 'reactcss'
import merge from 'lodash/merge'
import '../../index.scss'

import { ColorWrap, Saturation, Hue } from 'react-color/lib/components/common'
import CustomFields from './CustomFields'
import CustomPointer from './CustomPointer'

export const CustomPicker = ({
  width,
  onChange,
  changeHexColor,
  disableAlpha,
  rgb,
  hsl,
  hsv,
  hex,
  styles: passedStyles = {},
}) => {
  const styles = reactCSS(
    merge(
      {
        default: {
          picker: {
            boxSizing: 'initial',
            fontFamily: 'Menlo',
          },
          saturation: {
            width: '319px',
            height: '238px',
            position: 'relative',
            top: '8px',
            borderRadius: '8px',
            overflow: 'hidden',
            marginBottom: '8px',
          },
          body: {
            paddingTop: '16px',
          },
          hue: {
            height: '12px',
            width: '320px',
            position: 'relative',
            marginBottom: '12px',
          },
        },
      },
      passedStyles
    )
  )

  return (
    <div style={styles.picker} className="chrome-picker">
      <div style={styles.saturation}>
        <Saturation hsl={hsl} hsv={hsv} pointer={CustomPointer} onChange={onChange} />
      </div>
      <div style={styles.body}>
        <div style={styles.hue} className="hue-wrapper">
          <Hue hsl={hsl} pointer={CustomPointer} onChange={onChange} />
        </div>
        <CustomFields
          rgb={rgb}
          hsl={hsl}
          hex={hex}
          onChange={onChange}
          changeHexColor={changeHexColor}
          disableAlpha={disableAlpha}
        />
      </div>
    </div>
  )
}

export default ColorWrap(CustomPicker)
