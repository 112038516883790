import React from 'react'
import { useStoreState, useStoreActions } from 'easy-peasy'

import EditPopup from './EditPopup'
import EditWheel from './EditWheel'
import EditElement from './EditElement'
import EditText from './EditText'
import EditInput from './EditInput'
import EditButton from './EditButton'
import EditResult from './EditResult'
interface Props {
  type: string
}

const CaseElement = ({ type, element, step }) => {
  const toggle = useStoreActions((actions: any) => actions.codedefault.toggle)

  const { stepValue } = useStoreState((state) => state.codedefault)
  const { editElementComp } = useStoreActions((actions: any) => actions.codedefault)

  const getValues = useStoreState((state) => state.codedefault)['values']?.[stepValue]?.[step]?.[
    type
  ]
  const title = (step) => {
    switch (step) {
      case 'mainPopup':
        return 'Popup'
      case 'minimize':
        return 'Minimize'
      case 'result':
        return 'Result'
      case 'email':
        return 'Email'
    }
  }
  //   const { type } = props
  const renderCasePopup = (element: string) => {
    switch (element) {
      case 'Background':
        return (
          <EditPopup
            title={title(step)}
            values={getValues}
            editElement={editElementComp}
            toggle={toggle}
          />
        )
      case 'Text':
        return <EditText values={getValues} editElement={editElementComp} toggle={toggle} />
      case 'Input':
        return <EditInput values={getValues} editElement={editElementComp} toggle={toggle} />
      case 'Button':
        return <EditButton values={getValues} editElement={editElementComp} toggle={toggle} />
      default:
        return 'Not data'
    }
  }
  return <div>{renderCasePopup(element)}</div>
}

export default CaseElement
